import styled from 'styled-components';

export const ActivityTaskStyled = styled.div`
  .avt_container {
    border-radius: 5px;
    box-shadow: 0 0 5px #00000026;
    .avt_top_layout {
      padding: 12px 10px 15px 10px;
      .t_row_coagent {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        coloumn-gap: 30px;
        row-gap: 10px;
        .rc_left_col {
          flex: 1;
          min-width: 290px;
          display: flex;
          align-items: center;
          .owner_name {
            margin-left: 10px;
            margin-right: 20px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
            color: ${({ theme }) => theme.COLORS.BLUE_1};
          }
        }
        .rc_right_col {
          flex: 1;
          min-width: 275px;
          text-align: right;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          color: ${({ theme }) => theme.COLORS.GRAY_2};
        }
      }
      .t_line_between {
        background: ${({ theme }) => theme.COLORS.BLUE_1};
        width: 100%;
        height: 2px;
        margin-top: 16px;
        margin-bottom: 15px;
      }
      .t_row_asset {
        display: flex;
        column-gap: 16px;
        row-gap: 16px;
        .ra_left_col {
          display: flex;
          .asset_img {
            width: 200px;
            height: 120px;
            object-fit: cover;
            border-radius: 5px;
          }
        }
        .ra_right_col {
          flex: 1;
          display: flex;
          column-gap: 50px;
          row-gap: 20px;
          .rc_left_col {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;
            row-gap: 20px;
            .rc_content {
              flex: 1;
              min-width: 234px;
              .rc_title {
                font-size: ${({ theme }) => theme.FONT.SIZE.S17};
                font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
                margin-bottom: 10px;
              }
              .typho_wrap {
                &.mb_10 {
                  margin-bottom: 10px;
                }
              }
            }
          }
          .rc_right_col {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
          }
        }
      }
    }
    .avt_bottom_layout {
      border-radius: 5px;
      background: ${({ theme }) => theme.COLORS.BLUE_6};
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 20px;
      .avt_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
      }
      .line_vertical_between {
        width: 2px;
        height: 40px;
        background: ${({ theme }) => theme.COLORS.BLUE_1};
      }
      .txt_update_dated {
        font-size: ${({ theme }) => theme.FONT.SIZE.S17};
        color: ${({ theme }) => theme.COLORS.GRAY_2};
      }
    }
  }

  @media (max-width: 736px) {
    .avt_container {
      .avt_top_layout {
        .t_row_asset {
          flex-direction: column;
          .ra_left_col {
          }
          .ra_right_col {
            flex-direction: column;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .avt_container {
      .avt_top_layout {
        .t_row_asset {
          flex-direction: column;
          .ra_left_col {
            .asset_img {
              width: 100%;
              height: 120px;
            }
          }
          .ra_right_col {
          }
        }
      }
      .avt_bottom_layout {
        justify-content: space-between;
        column-gap: 5px;
      }
    }
  }

  .theme_standard {
  }
`;
