import React, { useEffect, useState } from 'react';
import { AssetListFilterLayoutStyled } from './styled';
import { Blocks, Forms } from 'components';
import { AssetTableListLayout, AssetItemListLayout } from 'widgets';

const AssetListFilterLayout = () => {
  const [_layoutSelected, _setLayoutSelected] = useState('item');

  useEffect(() => {}, []);

  const handleChangeLayout = (e) => {
    _setLayoutSelected(e);
  };

  const onSubmit = (values) => {};

  return (
    <AssetListFilterLayoutStyled>
      <Blocks.BlockShadow>
        <div className="form_wrap">
          <Forms.FilterAssetForm
            isShowControlLayout
            onChangeLayout={handleChangeLayout}
            onSubmit={onSubmit}
          />
        </div>
        {_layoutSelected === 'item' ? (
          <AssetItemListLayout />
        ) : (
          <div className="atll_wrap">
            <AssetTableListLayout />
          </div>
        )}
      </Blocks.BlockShadow>
    </AssetListFilterLayoutStyled>
  );
};

export default AssetListFilterLayout;
