import styled from 'styled-components';

export const PostMsgActionsStyled = styled.div`
  .pmsd_container {
    border-radius: 5px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .content_block {
      padding: 5px 10px;
      min-height: 97px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      color: ${({ theme }) => theme.COLORS.GRAY_3};
    }
    .action_row_3_col_block {
      display: flex;
      padding: 4px 0 5px 0;
      background: ${({ theme }) => theme.COLORS.GRAY_9};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_8};
      border-radius: 5px;
      .ac_r_col {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 3.6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        color: ${({ theme }) => theme.COLORS.GRAY_7};
        cursor: pointer;
        &.bd_lr {
          border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_8};
          border-right: 1px solid ${({ theme }) => theme.COLORS.GRAY_8};
        }
      }
    }
  }

  .theme_standard {
  }
`;
