import { AssetOverviewWidgetStyled } from './styled';
import { Cards, Icons, Blocks } from 'components';
import moment from 'moment';
import theme from 'styles/theme.json';

const AssetOverviewWidget = () => {
  const handleClickSelected = () => {};

  return (
    <AssetOverviewWidgetStyled>
      <Blocks.BlockShadow>
        <div className="aov_container">
          <div className="aov_left_col">
            <div className="card_asset_wrap">
              <Cards.Asset
                bg={theme.COLORS.BLUE_1}
                icon={<Icons.AssetAll />}
                label="ทรัพย์ทั้งหมด"
                no="33"
                // routeAdd={ROUTE_PATH}
                updatedDate={moment().format('DD-DD-YYYY')}
                handleClickSelected={handleClickSelected}
              />
            </div>
            <div className="card_asset_wrap">
              <Cards.Asset
                bg={theme.COLORS.GREEN_1}
                icon={<Icons.AssetHome />}
                label="บ้าน"
                no="3"
                routeAdd={true}
                updatedDate={moment().format('DD-DD-YYYY')}
                handleClickSelected={handleClickSelected}
              />
            </div>
            <div className="card_asset_wrap">
              <Cards.Asset
                bg={theme.COLORS.BLUE_2}
                icon={<Icons.AssetCondo />}
                label="คอนโด"
                no="3"
                routeAdd={true}
                updatedDate={moment().format('DD-DD-YYYY')}
                handleClickSelected={handleClickSelected}
              />
            </div>
            <div className="card_asset_wrap">
              <Cards.Asset
                bg={theme.COLORS.YELLOW_1}
                icon={<Icons.AssetLand />}
                label="ที่ดิน"
                no="3"
                routeAdd={true}
                updatedDate={moment().format('DD-DD-YYYY')}
                handleClickSelected={handleClickSelected}
              />
            </div>
          </div>
          <div className="aov_right_col">
            <Cards.AssetValueView
              total="38"
              houseTotal="8"
              condoTotal="20"
              landTotal="1"
            />
          </div>
        </div>
      </Blocks.BlockShadow>
    </AssetOverviewWidgetStyled>
  );
};

export default AssetOverviewWidget;
