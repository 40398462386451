import { CoinWidgetStyled } from './styled';
import { Displays, Buttons, Icons } from 'components';

const CoinWidget = () => {
  return (
    <CoinWidgetStyled>
      <div className="row_2_col">
        <div className="left_col">
          <Displays.MergeCoin coin={'2,000'} />
        </div>
        <div className="right_col">
          <Buttons.BtnIconPrefix icon={<Icons.CartCircle />} label="Coin" />
        </div>
      </div>
      <div className="txt_detail_col">500 coin | จะหมดอายุใน 4 ก.ค. 64]</div>
    </CoinWidgetStyled>
  );
};

export default CoinWidget;
