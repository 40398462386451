import styled from 'styled-components';

export const TrackingCountStyled = styled.div`
  .tab_header_layout {
    display: flex;
    border-bottom: 3px solid ${({ theme }) => theme.COLORS.BLUE_1};
    margin-bottom: 25px;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 0px;
    }
    .tab_item {
      min-width: 170px;
      padding: 8px 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.BLUE_1};
      cursor: pointer;
      &.active {
        background: ${({ theme }) => theme.COLORS.BLUE_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
    }
  }
  .tab_body_layout {
  }

  .theme_standard {
  }
`;
