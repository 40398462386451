import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { MergeCoinStyled } from './styled';
import { Icons } from 'components';

const MergeCoin = ({ theme_standard, coin }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <MergeCoinStyled>
      <div className={customClass}>
        <div className="mc_row_2_col">
          <Icons.MergeCoin />
          <div className="mc_label_block">
            <div className="mc_txt_title">{coin}</div>
            <div className="mc_txt_unit">Merge Coin</div>
          </div>
        </div>
      </div>
    </MergeCoinStyled>
  );
};

MergeCoin.propTypes = {};

export default MergeCoin;
