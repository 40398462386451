import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { TitleButtonStyled } from './styled';

const TitleButton = ({ theme_standard, title }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <TitleButtonStyled>
      <div className={customClass}>
        <div className="title_btn">{title}</div>
      </div>
    </TitleButtonStyled>
  );
};

TitleButton.propTypes = {};

export default TitleButton;
