import styled from 'styled-components';

export const BtnIconPrefixStyled = styled.div`
  .btn_c_container {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 2px 14px 2px 2px;
    background: ${({ theme }) => theme.COLORS.BLUE_1};
    border-radius: 14px;
    cursor: pointer;
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }

  .theme_standard {
    .btn_c_container {
      width: fit-content;
      padding: 5px 16px;
      border-radius: 5px;
      column-gap: 4px;
      .label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
    }
  }

  .theme_more {
    .btn_c_container {
      background: transparent;
      column-gap: 0px;
      padding: 0;
      .label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.GRAY_5};
      }
    }
  }

  .theme_verify {
    .btn_c_container {
      background: transparent;
      column-gap: 2px;
      padding: 0;
      .label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S9};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
      }
    }
  }

  .theme_action {
    .btn_c_container {
      justify-content: center;
      padding: 4px;
      background: ${({ theme }) => theme.COLORS.BLUE_6};
      .label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        color: ${({ theme }) => theme.COLORS.GRAY_7};
      }
    }
  }
`;
