import React from 'react';
// import PropTypes from 'prop-types'
import { AvatarCompanyStyled } from './styled';

const AvatarCompany = ({ src }) => {
  return (
    <AvatarCompanyStyled>
      <img src={src} alt="merge avatar" />
    </AvatarCompanyStyled>
  );
};

AvatarCompany.propTypes = {};

export default AvatarCompany;
