import styled from 'styled-components';

export const LandingPageFooterWidgetStyled = styled.div`
  position: relative;
  height: calc(260px - 44px);
  padding: 22px;
  .ft_head {
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLUE_1};
  }
  ul {
    li {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      &::marker {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
      }
    }
  }
  .credit_by {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 16px;
    text-align: center;
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  }
`;
