import React from 'react';
// import PropTypes from 'prop-types'
import { AvatarRankCircleStyled } from './styled';

const AvatarRankCircle = ({ rankColor, src }) => {
  return (
    <AvatarRankCircleStyled rankColor={rankColor}>
      <img src={src} alt="merge rank"/>
    </AvatarRankCircleStyled>
  );
};

AvatarRankCircle.propTypes = {};

export default AvatarRankCircle;
