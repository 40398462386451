export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <g id="Group_482" data-name="Group 482" transform="translate(-167 -595)">
        <circle
          id="Ellipse_118"
          data-name="Ellipse 118"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(167 595)"
          fill="#fff"
        />
        <g id="shopping_cart_black_24dp" transform="translate(170 598)">
          <path
            id="Path_534"
            data-name="Path 534"
            d="M0,0H18V18H0Z"
            fill="none"
          />
          <path
            id="Path_535"
            data-name="Path 535"
            d="M11.187,9.7a1.394,1.394,0,0,0,1.225-.721l2.506-4.543A.7.7,0,0,0,14.309,3.4H3.947L3.289,2H1V3.4H2.4l2.52,5.313-.945,1.708A1.4,1.4,0,0,0,5.2,12.5h8.4V11.1H5.2l.77-1.4ZM4.613,4.8h8.506L11.187,8.3H6.272ZM5.2,13.2a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,5.2,13.2Zm7,0a1.4,1.4,0,1,0,1.4,1.4A1.4,1.4,0,0,0,12.2,13.2Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
