import styled from 'styled-components';

export const AvartarVerticalStyled = styled.div`
  .figture_circle {
    width: 120px;
    height: 120px;
    border: 5px solid ${({ theme }) => theme.COLORS.GOLD_1};
    border-radius: 120px;
    margin: auto;
    margin-bottom: 10px;
    overflow: hidden;
    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
  }
  .a_txt_name {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    margin-bottom: 5px;
  }
  .a_scroe {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S17};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
  }
  .theme_standard {
  }
`;

export const ButtonVerifyStyled = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  column-gap: 6px;
  margin: auto;
  padding: 2px 15px;
  background: ${({ bg }) => bg};
  border-radius: 10px;
  margin-bottom: 10px;
  .txt_status {
    color: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`;
