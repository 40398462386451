export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      id="ant-design_comment-outlined"
      xmlns="http://www.w3.org/2000/svg"
      width="13.071"
      height="12.05"
      viewBox="0 0 13.071 12.05"
    >
      <path
        id="Path_527"
        data-name="Path 527"
        d="M258.774,421a.844.844,0,1,0,0,1.688.844.844,0,0,0,0-1.688Zm-5.909,0a.844.844,0,1,0,0,1.688.844.844,0,0,0,0-1.688Z"
        transform="translate(-250.08 -416.444)"
        fill={color}
      />
      <path
        id="Path_528"
        data-name="Path 528"
        d="M76.121,96.632a5.017,5.017,0,0,0-2.757-1.9h0a5.354,5.354,0,0,0-8.242,6.825l.012,1.934a.435.435,0,0,0,.022.137.466.466,0,0,0,.585.3l1.847-.582a5.352,5.352,0,0,0,1.5.3l-.007.006a5.069,5.069,0,0,0,4.566.715l1.854.6a.483.483,0,0,0,.144.023.466.466,0,0,0,.467-.467v-1.955A5.045,5.045,0,0,0,76.121,96.632Zm-8.329,5.689-.175-.073-1.444.452-.015-1.517-.117-.131a4.318,4.318,0,1,1,1.75,1.269Zm7.367-.248-.117.146.015,1.517-1.43-.481-.175.073a4.029,4.029,0,0,1-2.494.1h0a5.346,5.346,0,0,0,3.408-7.079l.009.006a3.986,3.986,0,0,1,.788,5.718Z"
        transform="translate(-64.013 -92.955)"
        fill={color}
      />
      <path
        id="Path_529"
        data-name="Path 529"
        d="M392.865,421a.844.844,0,1,0,0,1.688.844.844,0,0,0,0-1.688Z"
        transform="translate(-387.425 -416.444)"
        fill={color}
      />
    </svg>
  );
}
