export default function renderIcon({ color = '#d1d1d1' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21.5"
      viewBox="0 0 22 21.5"
    >
      <path
        id="Path_504"
        data-name="Path 504"
        d="M1,23H5.19V11.463H1Zm19.9-5.244H13.571l-2.19-.766L11.728,16l1.844.7h2.954a1.239,1.239,0,0,0,1.236-1.238h0a1.259,1.259,0,0,0-.807-1.164L9.35,11.463H7.286v9.46L14.619,23,23,19.854h0A2.1,2.1,0,0,0,20.9,17.756ZM14.619,1.5,7.286,6.744V9.366h2.1l8.528,3.419a3.1,3.1,0,0,1,1.949,2.874h2.1V6.744ZM14.1,10.415H13.048V9.366H14.1Zm0-2.1H13.048V7.268H14.1Zm2.1,2.1H15.143V9.366H16.19Zm0-2.1H15.143V7.268H16.19Z"
        transform="translate(-1 -1.5)"
        fill={color}
      />
    </svg>
  );
}
