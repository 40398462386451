import React from 'react';
import { useTranslation } from 'react-i18next';
import { Sec1IntroductionStyled } from './styled';

const Sec1Introduction = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec1IntroductionStyled>
      <div className="introduction_container">
        <div className="intro_header_block">
          <h1>{t('The best assistance program that broker choose')}</h1>
          <h2>
            {t(
              'That help you to manage your assets and free set up your own commissions'
            )}
          </h2>
          <p>
            <span>Merge </span>
            {t(
              'understanding to make your real estate business go far and success together'
            )}
          </p>
          <div className="intro_btn_regis">{t('Register For Free')}</div>
        </div>
        <img
          className="intro_icon_top"
          src="/assets/images/icons/business.png"
          alt="merge business"
        />
        <img
          className="intro_icon_bottom"
          src="/assets/images/icons/person-merge.png"
          alt="merge person"
        />
      </div>
      <img
        className="intro_bg_backdrop"
        src="/assets/images/bg/bg-blue-wave.jpg"
        alt="merge background"
      />
    </Sec1IntroductionStyled>
  );
};

Sec1Introduction.propTypes = {};

export default Sec1Introduction;
