import styled from 'styled-components';

export const BtnLanguageWidgetStyled = styled.div`
  position: relative;
  .btn_lang_container {
    display: flex;
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    .bl_lb {
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    }
  }
  .btn_content_list {
    position: absolute;
    width: 100%;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    z-index: 10;
    .content_item {
      padding: 4px 0;
      cursor: pointer;
      text-align: center;
    }
  }
`;
