import styled from 'styled-components';

export const AssetTypeDetailStyled = styled.div`
  width: 100%;
  min-width: 190px;
  .asset_type_detail_container {
    padding: 12px 5px 5px 5px;
    background: ${({ bg }) => bg};
    border-radius: 5px;
    .asset_head_wrap {
      margin-bottom: 10px;
    }
    .asset_list_wrap {
      margin-bottom: 12px;
    }
    .at_update_date {
      text-align: right;
      font-size: ${({ theme }) => theme.FONT.SIZE.S10};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }

  .theme_standard {
  }
`;
