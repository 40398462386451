import { LandingPageFooterWidgetStyled } from './styled';
import { useTranslation } from 'react-i18next';

const LandingPageFooterWidget = () => {
  const { t } = useTranslation('landingPage');
  return (
    <LandingPageFooterWidgetStyled>
  
      <div className="ft_head">{t('Contact for more information')}</div>
      <ul>
        <li>line</li>
        <li>facebook</li>
        <li>tel</li>
      </ul>
      <div className="credit_by">© 2021 Freedom Innovation Co.,Ltd</div>
    </LandingPageFooterWidgetStyled>
  );
};

export default LandingPageFooterWidget;
