import styled from 'styled-components';

export const AssetValueViewStyled = styled.div`
  .asset_value_container {
    .av_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.BLUE_1};
      span {
        font-size: ${({ theme }) => theme.FONT.SIZE.S25};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    }
    .av_row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .av_left_col {
        min-width: 225px;
      }
      .av_right_col {
        flex: 1;
        min-width: 175px;
        .content_block {
          .row {
            display: flex;
            margin: auto;
            margin-bottom: 10px;
            max-width: 300px;
            &:last-child {
              margin-bottom: 0;
            }
            .left_col {
              flex: 1;
              display: flex;
              align-items: center;
              column-gap: 10px;
              .dot {
                width: 15px;
                height: 15px;
                border-radius: 15px;
                &.red {
                  background: ${({ theme }) => theme.COLORS.GREEN_1};
                }
                &.blue {
                  background: ${({ theme }) => theme.COLORS.BLUE_2};
                }
                &.yellow {
                  background: ${({ theme }) => theme.COLORS.YELLOW_1};
                }
              }
              .txt_prefix {
                font-size: ${({ theme }) => theme.FONT.SIZE.S15};
                font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                color: ${({ theme }) => theme.COLORS.BLUE_1};
              }
            }
            .right_col {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              column-gap: 18px;
              .txt_value {
                font-size: ${({ theme }) => theme.FONT.SIZE.S15};
                font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
              }
              .txt_suffix {
                font-size: ${({ theme }) => theme.FONT.SIZE.S15};
                font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
                color: ${({ theme }) => theme.COLORS.BLUE_1};
              }
            }
          }
        }
      }
    }
  }

  .theme_standard {
  }
`;
