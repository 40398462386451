import React from 'react';
import { withTranslation } from 'react-i18next';
import { MyAssetContainerStyled } from './styled';
import {
  AssetTypeOverviewWidget,
  FilterAssetWidget,
  AssetCarouselWidget,
} from 'widgets';

class MyAssetContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    return (
      <MyAssetContainerStyled>
        <div className="section_layout">
          <AssetTypeOverviewWidget />
        </div>
        <div className="section_layout">
          <FilterAssetWidget />
        </div>
        <div className="section_layout">
          <AssetCarouselWidget assetType="house" />
        </div>
        <div className="section_layout">
          <AssetCarouselWidget assetType="condo" />
        </div>
        <div className="section_layout">
          <AssetCarouselWidget assetType="land" />
        </div>
      </MyAssetContainerStyled>
    );
  }
}

export default withTranslation('common')(MyAssetContainer);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(MyAssetContainer);
