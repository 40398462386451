import styled from 'styled-components';

export const AssetType1Styled = styled.div`
  .asset_container {
    border-radius: 5px;
    box-shadow: 0 0 5px #00000026;
    width: 195px;
    cursor: pointer;
    &:hover {
      .asset_img {
        transform: scale(1.4);
      }
    }
    .header_block {
      padding: 6px 5px;
      .a_title {
        text-align: center;
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 5px;
      }
      .a_sub_title {
        text-align: right;
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
    }
    .preview_block {
      position: relative;
      height: 130px;
      overflow: hidden;
      .asset_img {
        width: 100%;
        height: 130px;
        object-fit: cover;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: 0.5s all ease-in-out;
      }
      .favorite_icon {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 2;
      }
      .dicount {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
    .content_block {
      padding: 4px 5px 20px 5px;
      .price {
        padding: 2px 0;
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S17};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
        border-bottom: 1px solid ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 10px;
      }
      .user_more {
        padding: 0 5px;
        .commission_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          .txt_prefix_com {
            font-size: ${({ theme }) => theme.FONT.SIZE.S10};
            color: ${({ theme }) => theme.COLORS.BLUE_1};
          }
          .txt_suffix_com {
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            font-size: ${({ theme }) => theme.FONT.SIZE.S15};
            color: ${({ theme }) => theme.COLORS.GREEN_2};
            span {
              color: ${({ theme }) => theme.COLORS.BLUE_1};
            }
          }
        }
        .um_row_2col {
          display: flex;
          align-items: center;
          column-gap: 6px;
          .um_left_col {
            flex: 1;
          }
          .um_right_col {
            width: 44px;
            flex-shrink: 0;
            .btn_wrap {
              margin-bottom: 4px;
            }
            .time_ago {
              text-align: right;
              font-size: ${({ theme }) => theme.FONT.SIZE.S7};
              color: ${({ theme }) => theme.COLORS.GRAY_6};
            }
          }
        }
      }
    }
  }

  .theme_standard {
  }
`;
