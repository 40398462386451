import { BtnImgLinkStyled } from './styled';

const BtnImgLink = () => {
  return (
    <BtnImgLinkStyled>
      <img
        className="img_broker"
        src="/assets/images/bg/broker-co.jpg"
        alt="broker"
      />
    </BtnImgLinkStyled>
  );
};

export default BtnImgLink;
