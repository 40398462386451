import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputCheckboxStyled } from './styled';

const InputCheckbox = ({
  theme_standard,
  label,
  value,
  errors,
  name,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <InputCheckboxStyled>
      <div className={customClass}>
        <div className="checkbox_container">
          <input id={name} type="checkbox" {...props} checked={value} />
          <label for={name}>{label}</label>
        </div>
      </div>
      {errors && <div className="errors">{`* ${errors}`}</div>}
    </InputCheckboxStyled>
  );
};

InputCheckbox.propTypes = {};

export default InputCheckbox;
