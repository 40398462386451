import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AvatarHorizontalStyled } from './styled';
import { Displays, Buttons, Icons } from 'components';
import theme from 'styles/theme.json';

const AvatarHorizontal = ({
  theme_standard,
  teameLogo,
  userImg,
  userName,
  userRank,
  postDated,
  renderContentType,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderContent = () => {
    switch (renderContentType) {
      case 'userDetail':
        return (
          <div className="ah_row_2_col">
            <Buttons.BtnIconPrefix
              theme_verify
              icon={
                <Icons.Verify
                  color={theme.COLORS.GREEN_1}
                  width="10"
                  height="10"
                />
              }
              label="Verified"
            />
            <Displays.AvatarCompany src={teameLogo} />
          </div>
        );
      case 'postDated':
        return <div className="post_dated">{postDated}</div>;
      default:
        break;
    }
  };

  return (
    <AvatarHorizontalStyled>
      <div className={customClass}>
        <div className="avartar_container">
          <div className="left_col">
            <Displays.AvatarRankCircle
              rankColor={theme.COLORS.GOLD_1}
              src={userImg}
            />
          </div>
          <div className="right_col">
            <div className="ah_name">{userName}</div>
            {renderContent()}
          </div>
        </div>
      </div>
    </AvatarHorizontalStyled>
  );
};

AvatarHorizontal.propTypes = {};

export default AvatarHorizontal;
