import React from 'react';
import { withTranslation } from 'react-i18next';
import { CoAgentDashboardContainerStyled } from './styled';
import { Tabs } from 'components';
import {
  OverviewTab,
  WatchlistTab,
  AssetRequestTab,
  AssetApprovedTab,
  CommissionTab,
  UsageHistoryTab,
} from './Tabs';

class CoAgentDashboardContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    return (
      <CoAgentDashboardContainerStyled>
        <Tabs.TrackingCount tabs={tabs} initialValues={'all'} />
      </CoAgentDashboardContainerStyled>
    );
  }
}

const tabs = {
  all: {
    label: 'ภาพรวม',
    component: <OverviewTab />,
  },
  watchlist: {
    label: 'Watchlist (0)',
    component: <WatchlistTab />,
  },
  asset_request: {
    label: 'ทรัพย์ที่ขอสิทธิ์ (0)',
    component: <AssetRequestTab />,
  },
  asset_receive: {
    label: 'ทรัพย์ที่ได้สิทธิ์ (0)',
    component: <AssetApprovedTab />,
  },
  commission: {
    label: 'ค่าคอมมิชชั่น',
    component: <CommissionTab />,
  },
  history: {
    label: 'ประวัติการใช้งาน',
    component: <UsageHistoryTab />,
  },
};

export default withTranslation('common')(CoAgentDashboardContainer);
