import styled from 'styled-components';

export const CoAgentWidgetStyled = styled.div`
  .title_wrap {
    margin-bottom: 34px;
  }
  .row_action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 15px;
    row-gap: 15px;
    margin-bottom: 20px;
    .ra_left_col {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      column-gap: 15px;
      row-gap: 15px;
      .lrs_wrap {
      }
    }
    .ra_right_col {
    }
  }
  .ca_row_2_col {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 30px;
    overflow: auto;
    .left_col {
      flex: 1;
      min-width: 500px;
    }
    .right_col {
      flex: 1;
      min-width: 500px;
    }
    .commission_wrap {
      margin-top: 25px;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    .row_action {
      flex-direction: column;
      align-items: flex-end;
      .ra_left_col {
        order: 2;
      }
      .ra_right_col {
        order: 1;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .row_action {
      .ra_left_col {
        .lrs_wrap {
          width: calc(50% - 15px);
        }
      }
    }
  }
`;

export const CommissionStyled = styled.div`
  .c_container {
    display: flex;
    align-items: center;
    .c_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
    }
    .c_line_vertical {
      width: 2px;
      height: 55px;
      margin: 0 28px;
      background: ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .c_price {
      padding: 10px 15px;
      border-radius: 5px;
      background: ${({ themeColor }) => themeColor};
      font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }
`;
