export default function Logo({ width = '170', height = '42.089' }) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="196.078" height="24.853" viewBox="0 0 216.078 44.853">
  <g id="Group_349" data-name="Group 349" transform="translate(-67.016 -12.251)">
    <g id="Group_348" data-name="Group 348">
      <g id="Group_347" data-name="Group 347">
        <g id="Group_346" data-name="Group 346">
          <path id="Path_465" data-name="Path 465" d="M116.181,12.254a3.005,3.005,0,0,0-2.195.881l-21.17,21.17a6.446,6.446,0,0,0,0,9.117l5.63,5.63a1.168,1.168,0,0,0,1.65,0L113.22,35.927V15.269A3.016,3.016,0,0,1,116.181,12.254Z" fill="#00326e"/>
          <path id="Path_466" data-name="Path 466" d="M99.253,18.047l-4.912-4.912a3.016,3.016,0,0,0-4.267,0L68.9,34.305a6.446,6.446,0,0,0,0,9.117l5.629,5.63a1.169,1.169,0,0,0,1.651,0l23.069-23.07A5.609,5.609,0,0,0,99.253,18.047Z" fill="#0081c7"/>
        </g>
        <path id="Path_467" data-name="Path 467" d="M123.047,12.251H116.1l-.057,0a3.016,3.016,0,0,0-2.96,3.015V45.207a6.446,6.446,0,0,0,6.446,6.447h7.962a1.167,1.167,0,0,0,1.167-1.167V17.862A5.611,5.611,0,0,0,123.047,12.251Z" fill="#0081c7"/>
      </g>
      <path id="Path_468" data-name="Path 468" d="M185.056,37.416a1.2,1.2,0,0,0-1.076,1.738c1.029,1.887,3.491,2.792,6.212,2.792a20.52,20.52,0,0,0,5.32-.639,1.2,1.2,0,0,1,1.487.916l.483,2.423a1.313,1.313,0,0,1-.751,1.673,24.15,24.15,0,0,1-7.615,1.131c-8.064,0-12.695-4.7-12.695-12.115,0-6.049,3.773-12.737,12-12.737,7.715,0,10.629,6,10.629,11.888a17.685,17.685,0,0,1-.1,1.868,1.191,1.191,0,0,1-1.189,1.062Zm5.783-5.07a1.2,1.2,0,0,0,1.179-1.435,3.916,3.916,0,0,0-4-3.39,4.432,4.432,0,0,0-4.224,3.218,1.2,1.2,0,0,0,1.133,1.607Z" fill="#00326e"/>
      <path id="Path_469" data-name="Path 469" d="M203.332,23.865a1.224,1.224,0,0,1,1.2-1.261h3.979a1.2,1.2,0,0,1,1.2,1.126l.2,3.81h.183a7,7,0,0,1,6.453-4.942c.154,0,.292,0,.418,0a1.2,1.2,0,0,1,1.17,1.2v4.517a1.2,1.2,0,0,1-1.279,1.194c-.225-.015-.466-.024-.731-.024-2.73,0-4.6,1.442-5.1,3.758a8.793,8.793,0,0,0-.137,1.647V45.74a1.2,1.2,0,0,1-1.2,1.2h-5.034a1.375,1.375,0,0,1-1.334-1.263S203.327,25.606,203.332,23.865Z" fill="#00326e"/>
      <path id="Path_470" data-name="Path 470" d="M243.21,22.639A1.215,1.215,0,0,1,244.4,23.9c0,1.4.032,19.565.032,19.565,0,4.573-1.067,8.325-3.766,10.712-2.607,2.261-6.147,2.923-9.648,2.923a20.4,20.4,0,0,1-7.626-1.371,1.2,1.2,0,0,1-.68-1.415l.844-3.223a1.211,1.211,0,0,1,1.651-.8,14.924,14.924,0,0,0,5.617,1.158c3.5,0,6.175-1.87,6.175-6.267v-1.53h-.1a7.66,7.66,0,0,1-6.425,3.073c-5.871,0-10.065-4.775-10.065-11.589,0-7.679,4.945-12.542,10.786-12.542a7.142,7.142,0,0,1,6.52,3.382h.1l.153-2.239a1.206,1.206,0,0,1,1.2-1.1Zm-6.322,10.2a5.677,5.677,0,0,0-.148-1.366,4.018,4.018,0,0,0-3.98-3.277c-2.692,0-4.841,2.46-4.841,6.777,0,3.546,1.753,6.319,4.829,6.319a4.049,4.049,0,0,0,3.928-2.988,7.809,7.809,0,0,0,.212-1.96Z" fill="#00326e"/>
      <path id="Path_471" data-name="Path 471" d="M257.318,37.416a1.218,1.218,0,0,0-1.1,1.773c1.041,1.863,3.489,2.757,6.193,2.757a20.479,20.479,0,0,0,5.3-.633,1.223,1.223,0,0,1,1.517.94l.532,2.664a1.235,1.235,0,0,1-.826,1.409,24.152,24.152,0,0,1-7.594,1.124c-8.064,0-12.7-4.7-12.7-12.115,0-6.049,3.772-12.737,12-12.737,7.715,0,10.629,6,10.629,11.888a17.607,17.607,0,0,1-.094,1.845,1.217,1.217,0,0,1-1.217,1.085Zm5.713-5.07a1.217,1.217,0,0,0,1.2-1.465,3.909,3.909,0,0,0-3.991-3.36,4.423,4.423,0,0,0-4.212,3.185,1.225,1.225,0,0,0,1.156,1.64Z" fill="#00326e"/>
      <path id="Path_472" data-name="Path 472" d="M274.366,42.989a4.366,4.366,0,1,1,8.727,0,4.365,4.365,0,1,1-8.727,0Z" fill="#00326e"/>
    </g>
    <path id="Path_473" data-name="Path 473" d="M172.136,20.43V44.643a2.282,2.282,0,0,1-2.282,2.283H166.8a2.283,2.283,0,0,1-2.283-2.283V28.021h-.194L156.153,45.6a2.284,2.284,0,0,1-2.07,1.321h-.833a2.284,2.284,0,0,1-2.07-1.321l-8.169-17.584h-.129V44.643a2.283,2.283,0,0,1-2.282,2.283h-2.992a2.283,2.283,0,0,1-2.282-2.283V20.43a2.282,2.282,0,0,1,2.282-2.282h6.127a2.282,2.282,0,0,1,2.079,1.341l7.852,17.339h.13l7.79-17.333a2.281,2.281,0,0,1,2.082-1.347h6.186A2.282,2.282,0,0,1,172.136,20.43Z" fill="#00326e"/>
  </g>
</svg>

  
  );
}
