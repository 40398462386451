import styled from 'styled-components';

export const Sec3MergeSolveProblemsStyled = styled.div`
  position: relative;
  min-height: calc(100vh - 74px);
  padding: 0 50px;
  display: flex;
  align-items: center;
  .merge_solve_row {
    flex: 1;
    margin: auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    column-gap: 40px;
    .ms_left_col {
      width: 40%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .ms_right_col {
      width: 60%;
      .ms_title {
        margin-top: 0;
        margin-bottom: 60px;
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S50};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      }
      ul {
        li {
          margin-bottom: 30px;
          &::marker {
            font-size: ${({ theme }) => theme.FONT.SIZE.S28};
            color: ${({ theme }) => theme.COLORS.BLUE_11};
          }
          .ms_head {
            margin: 0;
            font-size: ${({ theme }) => theme.FONT.SIZE.S28};
            color: ${({ theme }) => theme.COLORS.BLUE_11};
            font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
          }
          .ms_sub {
            margin: 0;
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    padding: 40px 26px 10px 26px;
    min-height: unset;
    .merge_solve_row {
      .ms_left_col {
        display: none;
      }
      .ms_right_col {
        width: 100%;
        .ms_title {
          font-size: ${({ theme }) => theme.FONT.SIZE.S34};
        }
        ul {
          li {
            .ms_head {
              font-size: ${({ theme }) => theme.FONT.SIZE.S24};
            }
            .ms_sub {
              font-size: ${({ theme }) => theme.FONT.SIZE.S18};
            }
          }
        }
      }
    }
  }
`;
