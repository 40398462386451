import React from 'react';
import { withTranslation } from 'react-i18next';
import { DashboardContainerStyled } from './styled';
import {
  BannerAdvertiseWidget,
  AgentFeedWidget,
  CoAgentWidget,
  CoAgentRecommentWidget,
  AssetOverviewWidget,
} from 'widgets';

class DashboardContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    return (
      <DashboardContainerStyled useSuspense={false}>
        <div className="section_layout">
          <BannerAdvertiseWidget />
        </div>
        <div className="section_layout">
          <AssetOverviewWidget />
        </div>
        <div className="section_layout">
          <CoAgentWidget />
        </div>
        <div className="section_layout">
          <CoAgentRecommentWidget />
        </div>
        <div className="section_layout">
          <AgentFeedWidget />
        </div>
      </DashboardContainerStyled>
    );
  }
}

export default withTranslation('common')(DashboardContainer);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(DashboardContainer);
