import React from 'react';
import { withTranslation } from 'react-i18next';
import { PrivacyPolicyContainerStyled } from './styled';
import queryString from 'query-string';
import { withRouter } from 'navigations/withRouter';

class PrivacyPolicyContainer extends React.Component {
  render() {
    const { router } = this.props;
    const { lang } = queryString.parse(router.location.search);
    return (
      <PrivacyPolicyContainerStyled useSuspense={false}>
        {lang === 'en' ? (
          <>
            <div className="privacy_title">Privacy Policy</div>
            <div className="p_content">
              FREEDOM INNOVATION TECHNOLOGY CO.,LTD (the “Company”) would like
              to inform you who contacts the Company that it is necessary to
              collect, compile and use your personal data for the Company’s
              service in accordance with this Privacy Policy. By contacting and
              disclosing your personal data to the Company for the purposes of
              communication, coordination, and/or provision of the Company's
              services to you, it deems that you agree and accept to be bound by
              and comply with this Privacy Policy.
            </div>
            <div className="p_sub_title">
              Personal data being processed, the Company may obtain your
              personal data from various channels as follows:
            </div>
            <ul className="sub_content">
              <li>
                (1) Directly from you through the Company's communications with
                you;{' '}
              </li>
              <li>
                (2) Indirectly, via a referral from a third party to whom you
                may grant consent to disclose your personal data to the Company;
                or
              </li>
              <li>
                (3) Automatically collected via system when you access the
                Company's website and/or use the services.
              </li>
            </ul>
            <div className="p_sub_title">
              The personal data that the Company is necessary to collect,
              compile, use and process under this Privacy Policy are as follows:
            </div>
            <ul className="sub_content">
              <li>
                (1) Your full name, including your related information or your
                representative information (in the case that you are contacting
                us in the name of a juristic person) which may include your
                identity information document.
              </li>
              <li>
                (2) Contact information such as telephone number, email or
                social media account information.
              </li>
              <li>
                (3) Other personal data that you may provide to the Company
                during the communications such as information on matters of
                inquiry, interest information or any other personally
                identifiable information you provide to the Company directly
                through various communication channels.
              </li>
              <li>
                (4) In case of communication via the website, may include your
                technical information such as IP Address, Cookies, including
                information about your browsing behavior.
              </li>
            </ul>
            <div className="p_sub_title">
              Purpose of the Personal Data Processing and Using, the Company is
              necessary to collect, compile and use the Job Applicant’s personal
              data for the following purposes:
            </div>
            <ul className="sub_content">
              <li>
                (1) For the management of communications with which you have
                communicated with the Company, such as answering questions,
                providing relevant information as requested and required,
                managing complaints, or responding to comments you make directly
                to the Company via various communication channels, including
                continuous coordination, service agreement execution, and/or
                performance of rights and obligations that the Company and you
                may agree on;
              </li>
              <li>
                (2) For the performance of legal obligations to which the
                Company may be subject to certain conditions. This may include,
                but is not limited to, performing duties associated with the
                preparation of accounting documents and taxes in connection with
                any services provided by the Company to you;
              </li>
              <li>
                (3) For the purpose of building and improving business
                relationships, including enhancing the service that the Company
                provides for your benefit, which the Company may collect and/or
                use your personal data for the purpose of control, assurance of
                service, management analysis and resolution of business-related
                issues, including, but not limited to, employee training and
                future service improvement planning;
              </li>
              <li>
                (4) For the purpose of protecting and defending the Company's
                legal rights in the event that you and the Company have a
                dispute; and
              </li>
              <li>
                (5) Subject to an explicit consent granted to the Company such
                as marketing and newsletter communications, the Company may
                process the Personal Data for the purposes as defined in the
                consent.
              </li>
            </ul>
            <div className="p_sub_title">
              Retention Period of Personal Data, in order to perform in
              accordance with the purposes described above, the Company is
              necessary to collect, compile and process your personal data
              according to the following period:
            </div>
            <ul className="sub_content">
              <li>
                (1) For personal data processing for the purpose of providing
                services, an agreement execution and/or the performance of
                rights and duties under the agreement, the Company is necessary
                to process your personal data.as long as the company has a duty
                to provide services to you;
              </li>
              <li>
                (2) For personal data processing for the purpose of performing
                the duties according to applicable laws, the Company is
                necessary to process your personal data for a period of time
                specified by applicable laws;
              </li>
              <li>
                (3) For personal data processing for the purpose of building and
                improving business relationships and/or improving the service,
                the Company reserves the right to retain that personal data to
                the extent that the Company may have business necessity. The
                Company warrants that the retention of the personal data will
                not unreasonably affect the rights of the data subject;
              </li>
              <li>
                (4) For personal data processing for the sake of protection and
                fighting for legitimate rights of the Company, the Company has a
                necessity to keep such personal data according to the
                prescription defined by applicable laws; and
              </li>
              <li>
                (5) In case you give consent to the Company to process your
                personal data for specific purposes, the Company will process
                your personal data until you withdraw such consent.
              </li>
            </ul>
            <div className="p_sub_title">
              Disclosure of the personal data, generally, your personal data
              will not be disclosed, except for the circumstance that the
              Company would need to disclose to the following person:
            </div>
            <ul className="sub_content">
              <li>
                (1) External service providers of the Company engaged for
                providing supporting services to the Company in providing
                services to you, including consultants of the Company. Such
                disclosure of the personal data to these third parties shall be
                done in accordance with the purposes and only on a need-to-know
                basis; or
              </li>
              <li>
                (2) Government, regulatory authorities or courts that the
                Company may be subject to an order, law or judgment to disclose
                the personal data.
              </li>
            </ul>
            <div className="p_sub_title">
              The Company undertakes to implement appropriate personal data
              security measures to prevent any unauthorized and unlawful access,
              modification, amendment, or disclosure. The Company commits to
              reviewing these measures on a regular basis to ensure its
              compatibility to the standards and to applicable laws.
            </div>
            <div className="p_sub_title">
              Data subject rights, the Company respects your rights as the data
              subject under applicable laws. You can contact to the Company to
              exercise the rights which are (1) the rights to withdraw consent,
              (2) the right to access, (3) the right request a copy of the
              personal data, (4) the right to correct the personal data, (5) the
              right to object to any processing of the personal data, (6) the
              right to request for data portability in the event that the
              Company stores the personal data in the format which is readable
              or commonly used by automated tools or equipment including the
              right to transfer transfer such personal data to other data
              controllers, (7) the right to request for the deletion or
              de-identification of the personal data upon there is no necessity
              to process, (8) the right to request for suspension of the use of
              the personal data, and (9) the right to file a complaint.
            </div>
            <div className="address_title">Company contact information</div>
            <div className="address">Data Protection Officer</div>
            <div className="address">
              Address: 5 Pa Daet Road, Tambon Pa Daet, Amphoe Mueang Chiang Mai,
              Chiang Mai 50100
            </div>
            <div className="address">
              Email or phone number: freedominnotech@gmail.com / 0876608530
            </div>
          </>
        ) : (
          <>
            <>
              <div className="privacy_title">นโยบายข้อมูลส่วนบุคคล</div>
              <div className="p_content">
                บริษัท ฟรีดอม อินโนเวชั่น เทคโนโลยี จํากัด (“บริษัท”)
                ขอแจ้งให้ท่านที่ติดต่อมายังบริษัททราบว่า
                บริษัทมีความจําเป็นต้องเก็บ รวบรวม และใช้ข้อมูลส่วนบุคคล
                ของท่านทีี่ติดต่อมาใช้บริการของบริษัท ภายใต้น
                โยบายข้อมูลส่วนบุคคลฉบับนี้ และเมื่อท่านติดต่อมายังบริษัท
                ส่งต่อเปิดเผยข้อมูลส่วนบุคคลของท่าน ให้แก่บริษัท เพื่อประโยชนใน
                นการติดต่อ ประสานงาน และ/หรือการให้บริการจากบริษัทให้แก่ท่าน
                บริษัทจะถือว่าท่านตกลงและยอมรับที่จะปฏิบัติตามนโยบายข้อมูลส่วนบุคคล
                ฉบับนี้แล้ว
              </div>
              <div className="p_sub_title">
                ข้อมูลส่วนบุคคลที่บริษัทประมวลผล
                บริษัทอาจได้รับข้อมูลส่วนบุคคลของท่าน ได้จากหลายช่องทาง ดังนี้
              </div>
              <ul className="sub_content">
                <li>
                  (1) ได้รับโดยตรง จากการติดต่อสื่อสารระหว่างบริษัทและท่าน
                </li>
                <li>
                  (2) ได้รับทางอ้อมจากการอ้างอิงจากบุคคลอื่น
                  ซึ่งท่านอาจให้ความยินยอมให้บุคคลดังกล่าวเปิดเผยส่งต่อข้อ
                  มูลส่วนบุคคลของท่านให้แก่ บริษัท หรือ
                </li>
                <li>
                  (3) เก็บรวบรวมโดยอัตโนมัติด้วยระบบ
                  เมื่อท่านเข้ามาเยี่ยมชมเว็บไซต์ และ/หรือใช้บริการต่าง ๆ
                  ของบริษัท
                </li>
              </ul>
              <div className="p_sub_title">
                ข้อมูลส่วนบุคคลที่บริษัทมีความจําเป็นต้องเก็บ รวบรวม ใช้
                และประมวลผลภายใต้นโยบายข้อมูลส่วนบุคคล ฉบับนี้ ได้แก่
              </div>
              <ul className="sub_content">
                <li>
                  (1) ชื่อนามสกุล รวมถึงข้อมูลที่เกี่ยวเนื่องของท่าน
                  หรือของตัวแทนของท่าน (ในกรณทีีท่านติดต่อมาในนามของนิติบุคคล)
                  ซึ่งอาจรวมถึงข้อมูลเอกสารการแสดงตน ของท่าน
                </li>
                <li>
                  (2) ข้อมูลการติดต่อ อาทิ เบอร์โทรศัพท์ อีเมล หรือข้อมูล Social
                  Media Account
                </li>
                <li>
                  (3) ข้อมูลส่วนบุคคลอื่น ๆ ที่ท่านอาจให้แก่บ
                  ริษัทระหว่างการติดต่อสื่อสาร เช่น ข้อมูลเรื่องที่ต้
                  องการติดต่อสอบถาม ข้อมูลความสนใจ หรือข้อมูลประกอบอื่น ๆ ที่
                  อาจระบุตัวตนของท่านได้ซึ่งท่านให้แก่ บริษัท
                  โดยตรงผ่านช่องทางการติดต่อสื่อสารต่าง ๆ
                </li>
                <li>
                  (4) กรณีการติดต่อผ่าน Website อาจรวมถึงข้อมูลทางเทคนิคของท่าน
                  ได้แก่ IP Address, Cookies
                  รวมถึงข้อมูลพฤติกรรมการสืบค้นของท่าน
                </li>
              </ul>
              <div className="p_sub_title">
                วัตถุประสงคในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล
                บริษัทจําเป็นต้องเก็บ รวบรวม
                และใช้ข้อมูลส่วนบุคคลของท่านตามที่ระบไว้
                ภายใต้นโยบายข้อมูลส่วนบุคคล ฉบับนี้ เพื่อวัตถุประสงค์ต่าง ๆ
                ดังต่อไปนี้
              </div>
              <ul className="sub_content">
                <li>
                  (1) เพื่อการจัดการตอบรับการสื่อสารที่ท่านติดต่อมายังบริษัท
                  เช่น เพื่อการตอบคําถาม การส่งข้อมูลที่เกี่ยวข้องตามที่
                  ท่านร้องขอและต้องการ การจัดการข้อร้องเรียน
                  หรือการตอบรับความคิดเห็นต่าง ๆ ที่ท่านให้แก่บริษัทโดยตรง
                  ผ่านช่องทางการติดต่อสื่อสารต่าง ๆ
                  รวมถึงการประสานงานต่อเนื่องไปจนถึงการให้ บริการ
                  การจัดทําสัญญาการให้บริการ
                  และ/หรือการปฏิบัติสิทธิและหน้าที่ที่บริษัทและท่านอาจตกลงและจัดทําระหว่างกันต่อเนื่อง
                </li>
                <li>
                  (2) เพื่อการปฏิบัติหน้าทตามกฎหมายที่บริษัทอาจอยู่ภายใต้เ
                  งื่อนไขทีต้องปฏิบัตติตาม ซึ่งอาจรวมถึงแต่ไม่จํากัดเพียง
                  การปฏิบัติหน้าที่ในการจัดทําเอกสารบัญชี
                  และภาษีสําหรับการให้บริการใด ๆ ที่บริษัทอาจให้ แก่ท่าน
                </li>
                <li>
                  (3) เพื่อการสร้างและปรับปรุงความสัมพันธ์ทางธุรกิจ
                  รวมถึงการปรับปรุงการให้บริการทบี่
                  ริษัทจะดําเนินการเพื่อประโยชน์ของท่านให้ดีขึ้น
                  ซึ่งบริิษัทอาจรวบรวม ใช้ข้อมูลส่วนบุคคลของท่าน เพื่อประโยชน์ใ
                  นการควบคุม การรับประกันคุณภาพ การวิเคราะห์ก
                  ารบริหารจัดการและแก้ไขปัญหาต่าง ๆ ที่เกี่ยวข้องของบริษัท
                  รวมถึงแต่ไม่จํากัดเพียงการฝึกอบรมพนักงาน
                  หรือการวางแผนการให้ปรับปรุงการให้บริการในอนาคต
                </li>
                <li>
                  (4)
                  เพื่อประโยชน์ในการปกป้องและต่อสู้สิทธิอันชอบด้วยกฎหมายของบริษัท
                  ในกรณีที่อาจเกิดข้อพิพาท ระหว่างท่านและบริษัท ไม่ว่าในลักษณะใด
                </li>
                <li>
                  (5) กรณที่บริษัทอาจได้รับความยินยอม
                  ในการประมวลผลข้อมูลส่วนบุคคลด้วยจุดประสงค์เฉพาะเจาะจงจากท่าน
                  เช่น เพื่อการติดต่อประชาสัมพันธ์ทางการตลาด
                  และการประชาสัมพันธ์ข่าวสารต่าง ๆ
                  บริษัทจะประมวลผลข้อมูลตามวัตถุประสงค์ดังกล่าว
                </li>
              </ul>
              <div className="p_sub_title">
                ระยะเวลาในการประมวลผลข้อมูลส่วนบุคคล เพื่อดําเนินการตามวัตถปุ
                ระสงค์ที่ระบุไว้ข้างต้นทั้งหมด บริษัทจําเป็นต้องเก็บรวบรวม
                และประมวลผลข้อมูลส่วนบุคคล ของท่านเป็นระยะเวลา ดังนี้
              </div>
              <ul className="sub_content">
                <li>
                  (1) สําหรับการประมวลผลข้อมูลด้วยวัตถุประสงค์การให้บริการ
                  การจัดทําสัญญา และ/หรือการปฏิบัติสิทธิและหน้าที่ภายใต้สัญญา
                  บริษัทจําเป็นต้องประมวลผล ข้อมูลส่วนบุคคลของท่าน
                  ตราบเท่าที่บริษัทยังมีหน้าที่ให้บริการแก่ท่าน
                </li>
                <li>
                  (2) สําหรับการประมวลผลข้อมูล
                  ด้วยวัตถุประสงค์การปฏิบัติหน้าที่ต ามกฎหมายของบริษัท
                  บริษัทจําเป็นต้องประมวลผลข้อมูลส่วนบุคคลของท่าน ไว้ตามระยะ
                  เวลาที่กฎหมายที่เกี่ยวข้องกําหนดไว้
                </li>
                <li>
                  (3)
                  สําหรับการประมวลผลข้อมูลส่วนบุคคลเพื่อการสร้างและปรับปรุงความสัมพันธ์ทางธุรกิจ
                  และ/หรือการปรับปรุงการให้บริการ บริษัทสงวนสิทธ์ในการเก็บ
                  รักษาข้อมูลส่วนบุคคลนั้นเท่าที่บริษัทอาจ
                  มีความจําเป็นทางธุรกิจ
                  โดยรับประกันไม่ให้กระทบสิทธิของท่านในฐานะเจ้าของข้อมูลเกินสมควร
                </li>
                <li>
                  (4) สําหรับการประมวลผลข้อมูลส่วนบุคคล เพื่อการปกป้องและต่อสู้
                  สิทธิอันชอบด้วยกฎหมายของบริษัท
                  บริษัทมีความจําเป็นเก็บรักษาข้อมูลส่วนบุคคลดังกล่าว
                  ตลอดอายุความที่เกี่ยวข้อง
                </li>
                <li>
                  (5) กรณีที่ท่านให้ความยินยอมแก่บริษัท
                  ในการประมวลผลข้อมูลด้วยวัตถุประสงค์เฉพาะ บริษัทจะประมวล
                  ผลข้อมูลส่วนบุคคลของท่านจนกว่าท่านจะถอนความ ยินยอม
                </li>
              </ul>
              <div className="p_sub_title">
                การเปิดเผยข้อมูลส่วนบุคคล โดยหลักการแล้ว
                ข้อมูลส่วนบุคคลของท่านจะไม่ถูกเปิดเผย
                ยกเว้นเป็นการเปิดเผยข้อมูลส่วนบุคคลที่บริษัทจําเป็นต้องดําเนินการ
                ให้แก่ กลุ่มบุคคลดังนี้
              </div>
              <ul className="sub_content">
                <li>
                  (1) การเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่
                  ผู้ให้บริการภายนอกของบริษัท
                  ที่ให้การช่วยเหลือสนับสนุนบริษัทในการให้บริการแก่ท่าน
                  รวมถึงที่ปรึกษาด้านการ ดําเนินธุรกิจของบริษัท
                  โดยบริษัทจะส่งต่อและเปิดเผยข้อมูลส่วนบุคคลของท่านตามขอบเขตของวัตถุประสงค์ที่ระบุไว้
                  และบนพื้นฐานเท่าที่จําเป็นเท่านั้น
                </li>
                <li>
                  (2) กรณีที่บริษัทอาจอยู่ภายใต้บังคับคําสั่งหรือคําพิพากษา
                  ของหน่วยงานราชการ
                  บริษัทอาจมีความจําเป็นในการส่งต่อเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่
                  หน่วยงานดังกล่าว
                </li>
              </ul>
              <div className="p_sub_title">
                บริษัทรับประกันการจัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม
                เพื่อป้องกันการเข้าถึง การใช้การเปลี่ยนแปลง การแก้ไข
                หรือการเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอํานาจหรือโดยมิชอบ
                ทั้งนี้
                บริษัทจะจัดให้มีการทบทวนมาตรการดังกล่าวเป็นระยะตามความเหมาะสม
                และโดยสอดคล้องกับกฎหมายที่เกี่ยวข้อง
              </div>
              <div className="p_sub_title">
                สิทธิของเจ้าของข้อมูล บริษัทเคารพสิทธิของท่าน
                ในฐานะเจ้าของข้อมูลภายใต้กฎหมายที่เกี่ยวข้อง
                โดยท่านสามารถติดต่อบริษัท เพื่อขอใช้สิทธิของท่าน ซึ่งได้แก่ (1)
                สิทธิ์ในการถอนความยินยอม (2) สิทธิ์ในการขอเข้าถึง (3)
                สิทธิในการขอรับสําเนาข้อมูลส่วนบุคคลของท่าน (4)
                สิทธิ์ในการขอปรับปรุงข้อมูลให้ถูกต้อง (5) สิทธิ์ในการ
                ขอคัดค้านการประมวลผลข้อมูลส่วนบุคคล (6)
                สิทธิในการขอโอนถ่ายข้อมูลที่เก็บบันทึกในรูปแบบอิเล็กทรอนิกส์ที่สามารถถ่ายโอนไดงาย
                รวมถึงการโอนถ่ายไปให้แก่ผู่ควบคุมข่อมูลส่วนบุคคลอื่น (7)
                สิทธิ์ขอให้ลบหรือทําลายข้อมูลของท่านเมื่อหมดความจําเป็น (8)
                สิทธิ์ในการขอให้ระงับการประมวลผลข้อมูลส่วนบุคคลชั่วคราว และ (8)
                สิทธิในการร้องเรียนได้
              </div>
              <div className="address_title">ข้อมูลติดต่อบริษัท</div>
              <div className="address">เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</div>
              <div className="address">
                ที่อยู่: เลขที่ 5 ถนน ป่าแดด ต.ป่าแดด อ.เมืองเชียงใหม่
                จ.เชียงใหม่ 50100
              </div>
              <div className="address">
                อีเมล หรือเบอร์โทรศัพท์: freedominnotech@gmail.com / 0876608530
              </div>
            </>
          </>
        )}
      </PrivacyPolicyContainerStyled>
    );
  }
}

export default withTranslation('common')(withRouter(PrivacyPolicyContainer));
