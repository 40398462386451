import styled from 'styled-components';

export const LineBetweenStyled = styled.div`
  .line {
    background: ${({ theme }) => theme.COLORS.BLUE_3};
    height: 2px;
    width: 100%;
  }

  .theme_standard {
  }
`;
