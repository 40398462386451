import { useState, useEffect } from 'react';
import { BtnLanguageWidgetStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { Icons } from 'components';

const BtnLanguageWidget = () => {
  const { i18n } = useTranslation();

  const [_langCurrent, _setLangCurrent] = useState(langs[0]);
  const [_active, _setActive] = useState(false);

  useEffect(() => {
    switch (i18n.language) {
      case 'th':
        _setLangCurrent(langs[0]);
        break;
      case 'en':
        _setLangCurrent(langs[1]);
        break;
      case 'ch':
        _setLangCurrent(langs[2]);
        break;
      default:
        break;
    }
  }, [i18n]);

  const _handleClickDropdown = () => {
    _setActive(!_active);
  };

  const _handleClickLang = (e) => {
    _setActive(!_active);
    _setLangCurrent(e);
    i18n.changeLanguage(e.value);
  };

  return (
    <BtnLanguageWidgetStyled>
      <div className="btn_lang_container" onClick={_handleClickDropdown}>
        <Icons.Lang />
        <div className="bl_lb">{_langCurrent.label}</div>
        <Icons.ArrowBottomDropdown />
      </div>
      {_active && (
        <div className="btn_content_list">
          {langs &&
            langs.map((e, i) => (
              <div
                key={i}
                className="content_item"
                onClick={() => _handleClickLang(e)}
              >
                {e.label}
              </div>
            ))}
        </div>
      )}
    </BtnLanguageWidgetStyled>
  );
};

const langs = [
  {
    label: 'ภาษาไทย',
    value: 'th',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: '中文',
    value: 'ch',
  },
];

export default BtnLanguageWidget;
