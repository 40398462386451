import theme from 'styles/theme.json';

export const AssetTrackingStatus = (key) => {
  switch (key) {
    case '1':
      return { no: 1, color: theme.COLORS.RED_1, label: 'Rent', value: 'rent' };
    case '2':
      return {
        no: 2,
        color: theme.COLORS.YELLOW_2,
        label: 'Rent',
        value: 'rent',
      };
    case '3':
      return {
        no: 3,
        color: theme.COLORS.ORANGE_1,
        label: 'Rent',
        value: 'rent',
      };
    case '4':
      return {
        no: 4,
        color: theme.COLORS.GREEN_4,
        label: 'Rent',
        value: 'rent',
      };
    default:
      break;
  }
};
