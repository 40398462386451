import { RankingWidgetStyled } from './styled';
import { Icons, Displays } from 'components';

const RankingWidget = () => {
  return (
    <RankingWidgetStyled>
      <div className="title">Performance</div>
      <div className="ranking">
        <Displays.RankBlock label="Class" />
      </div>
      <div className="row_2_col mb_8">
        <div className="left_col">
          <Icons.Ranking />
          Ranking
        </div>
        <div className="right_col">60</div>
      </div>
      <div className="row_2_col">
        <div className="left_col">
          <Icons.Credit />
          Credit
        </div>
        <div className="right_col">200,000</div>
      </div>
    </RankingWidgetStyled>
  );
};

export default RankingWidget;
