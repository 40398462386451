export default function renderIcon({ color = '#c1c1c1' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.664"
      height="9.664"
      viewBox="0 0 9.664 9.664"
    >
      <g id="bx_bx-time-five" transform="translate(-2 -2)">
        <path
          id="Path_569"
          data-name="Path 569"
          d="M6.832,2a4.832,4.832,0,1,0,4.832,4.832A4.838,4.838,0,0,0,6.832,2Zm0,8.7A3.866,3.866,0,1,1,10.7,6.832,3.87,3.87,0,0,1,6.832,10.7Z"
          fill={color}
        />
        <path
          id="Path_570"
          data-name="Path 570"
          d="M11.966,7H11V9.616l1.591,1.591.683-.683L11.966,9.216Z"
          transform="translate(-4.651 -2.584)"
          fill={color}
        />
      </g>
    </svg>
  );
}
