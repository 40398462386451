import styled from 'styled-components';

export const AssetLabelCountStyled = styled.div`
  .asset_count_container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    .ac_left_col {
      flex: 1;
      text-align: right;
      font-size: ${({ theme }) => theme.FONT.SIZE.S30};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
    .ac_right_col {
      display: flex;
      align-items: center;
      column-gap: 6px;
      .ac_txt_type {
        background: ${({ theme }) => theme.COLORS.WHITE_1}40;
        border-radius: 5px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        text-align: center;
        padding: 5px 8px;
        min-width: 88px;
      }
    }
  }

  .theme_standard {
  }
`;
