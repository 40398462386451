import styled from 'styled-components';

export const BtnInformationStyled = styled.div`
  padding: 2px;
  border-radius: 5px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .btn_i_row_2_col {
    display: flex;
    .left_col {
      flex-shrink: 0;
      width: 80px;
      .icon_block {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 11px 0;
        background: ${({ theme }) => theme.COLORS.BLUE_1};
        border-radius: 5px;
      }
    }
    .right_col {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.BLUE_1};
    }
  }
`;
