import { ROUTE_PATH } from 'utils/constants/routePath';
import { Icons } from 'components';
import theme from 'styles/theme.json';
import DashboardContainer from 'containers/DashboardContainer';
import MyAssetContainer from 'containers/MyAssetSystem/MyAssetContainer';
import AddAssetContainer from 'containers/MyAssetSystem/AddAssetContainer';
import ManageAssetContainer from 'containers/MyAssetSystem/ManageAssetContainer';
import CoAgentDashboardContainer from 'containers/CoAgentSystems/CoAgentDashboardContainer';
import ShareAssetsContainer from 'containers/CoAgentSystems/ShareAssetsContainer';
import CoAssetsContainer from 'containers/CoAgentSystems/CoAssetsContainer';

const cmsRoutes = [
  {
    key: 'coagent',
    path: ROUTE_PATH.COAGENT,
    name: 'coagent',
    notShowSidebar: true,
    element: <DashboardContainer />,
  },
  {
    key: 'dashboard',
    path: ROUTE_PATH.DASHBOARD,
    name: 'Feed',
    icon: (active) => (
      <Icons.PoolAsset
        color={active ? theme.COLORS.BLUE_1 : theme.COLORS.GRAY_4}
      />
    ),
    element: <DashboardContainer />,
  },
  // {
  //   key: 'myasset',
  //   path: ROUTE_PATH.MY_ASSET,
  //   name: 'My Asset',
  //   icon: (active) => (
  //     <Icons.Home color={active ? theme.COLORS.BLUE_1 : theme.COLORS.GRAY_4} />
  //   ),
  //   element: <MyAssetContainer />,
  // },
  // {
  //   key: 'myasset',
  //   path: ROUTE_PATH.ADD_MY_ASSET,
  //   name: 'My Asset',
  //   notShowSidebar: true,
  //   element: <AddAssetContainer />,
  // },
  {
    key: 'asset',
    name: 'Asset',
    icon: () => <Icons.Home color={theme.COLORS.GRAY_4} />,
    children: [
      {
        key: 'myassetdashboard',
        path: ROUTE_PATH.MY_ASSET,
        name: 'Dashboard',
        element: <MyAssetContainer />,
      },
      {
        key: 'manageassets',
        path: ROUTE_PATH.MANAGE_ASSET,
        name: 'Manage Assets',
        element: <ManageAssetContainer />,
      },
      {
        key: 'myassetdetail',
        path: ROUTE_PATH.ADD_MY_ASSET,
        name: 'Manage Assets',
        notShowSidebar: true,
        element: <AddAssetContainer />,
      },
    ],
  },
  {
    key: 'coagent',
    name: 'Co Agent',
    icon: () => <Icons.CoAgent color={theme.COLORS.GRAY_4} />,
    children: [
      {
        key: 'coagentdashboard',
        path: ROUTE_PATH.COAGENT_DASHBOARD,
        name: 'Dashboard',
        element: <CoAgentDashboardContainer />,
      },
      {
        key: 'coagentshareassets',
        path: ROUTE_PATH.COAGENT_SHARE_ASSETS,
        name: 'Share Assets',
        element: <ShareAssetsContainer />,
      },
      {
        key: 'coagentcoassets',
        path: ROUTE_PATH.COAGENT_CO_ASSETS,
        name: 'Co Assets',
        element: <CoAssetsContainer />,
      },
    ],
  },
];

export default cmsRoutes;
