export const ROUTE_PATH = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  COAGENT: '/coagent',
  DASHBOARD: '/coagent/dashboard',
  MY_ASSET: '/coagent/myasset',
  MANAGE_ASSET: '/coagent/manageasset',
  ADD_MY_ASSET: '/coagent/myasset/:id',
  COAGENT_DASHBOARD: '/coagent/coagentdashboard',
  COAGENT_SHARE_ASSETS: '/coagent/coagentshareassets',
  COAGENT_CO_ASSETS: '/coagent/coagentcoassets',
  PRIVACY_POLICY: '/privacy_policy',
};
