export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17031"
            data-name="Rectangle 17031"
            width="18"
            height="18"
            fill={color}
          />
        </clipPath>
      </defs>
      <g id="uiw_more" clip-path="url(#clip-path)">
        <path
          id="Path_530"
          data-name="Path 530"
          d="M2.25,7.5a2.25,2.25,0,1,1-1.591.659A2.25,2.25,0,0,1,2.25,7.5Zm13.5,0a2.25,2.25,0,1,1-1.591.659A2.25,2.25,0,0,1,15.75,7.5ZM9.2,7.5a2.25,2.25,0,1,1-1.591.659A2.25,2.25,0,0,1,9.2,7.5Z"
          transform="translate(0 -0.75)"
          fill={color}
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
}
