import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { PostMsgActionsStyled } from './styled';
import { Icons } from 'components';
import theme from 'styles/theme.json';

const PostMsgActions = ({ theme_standard, msg }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <PostMsgActionsStyled>
      <div className={customClass}>
        <div className="pmsd_container">
          <div className="content_block">{msg}</div>
          <div className="action_row_3_col_block">
            <div className="ac_r_col">
              <Icons.Favourite
                width="13.42"
                height="12"
                color={theme.COLORS.BLUE_1}
              />
              ถูกใจ
            </div>
            <div className="ac_r_col bd_lr">
              <Icons.Contact />
              ติดต่อ
            </div>
            <div className="ac_r_col">
              <Icons.Share />
              แชร์
            </div>
          </div>
        </div>
      </div>
    </PostMsgActionsStyled>
  );
};

PostMsgActions.propTypes = {};

export default PostMsgActions;
