import styled from 'styled-components';

export const AssetListFilterLayoutStyled = styled.div`
  .form_wrap {
    margin-bottom 15px;
  }
  .atll_wrap{
    overflow: auto;
  }
`;
