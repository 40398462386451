import React, { useState, useEffect } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { TrackingCountStyled } from './styled';

const TrackingCount = ({ theme_standard, tabs, initialValues }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_tabSelected, _setTabSelected] = useState();

  useEffect(() => {
    if (initialValues) {
      _setTabSelected(initialValues);
    }
  }, [initialValues]);

  const _handleClickTab = (e) => {
    _setTabSelected(e);
  };

  return (
    <TrackingCountStyled>
      <div className={customClass}>
        <div className="tab_header_layout">
          {tabs &&
            Object.keys(tabs).map((key, i) => (
              <div
                key={i}
                className={`tab_item ${key === _tabSelected ? 'active' : ''}`}
                onClick={() => _handleClickTab(key)}
              >
                {tabs[key].label}
              </div>
            ))}
        </div>
        <div className="tab_body_layout">
          {_tabSelected && tabs[_tabSelected].component}
        </div>
      </div>
    </TrackingCountStyled>
  );
};

TrackingCount.propTypes = {};

export default TrackingCount;
