export default function renderIcon({ color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.504"
      height="17.414"
      viewBox="0 0 24.504 17.414"
    >
      <path
        id="si-glyph_share-2"
        d="M24.653,154.6a2.954,2.954,0,0,0-2.984,2.926,2.834,2.834,0,0,0,.838,1.977l-1.591,3.941a3.037,3.037,0,0,0-.864-.136,3.2,3.2,0,0,0-.64.068l-2.893-3.825-2.149-2.842a2.765,2.765,0,0,0,.145-4.165,3.111,3.111,0,0,0-4.27,0,2.754,2.754,0,0,0-.26,3.749l-2.558,4.356a3.144,3.144,0,0,0-1.213-.247,2.9,2.9,0,1,0,0,5.8,2.969,2.969,0,0,0,3.027-2.9,2.8,2.8,0,0,0-.621-1.741l2.565-4.362a3.15,3.15,0,0,0,1.819.174l5.048,6.67a2.924,2.924,0,0,0-.757.978,2.77,2.77,0,0,0-.27,1.184,3.03,3.03,0,0,0,6.055,0,2.836,2.836,0,0,0-.85-2.01l1.585-3.931a3.074,3.074,0,0,0,.889.136,2.9,2.9,0,1,0-.049-5.8Z"
        transform="translate(-3.186 -151.701)"
        fill={color}
        fill-rule="evenodd"
      />
    </svg>
  );
}
