import { combineReducers } from 'redux';
import authenReducer from './authenReducer';
import sidebarReducer from './sidebarReducer';

const rootReducer = combineReducers({
  authenRedux: authenReducer,
  sidebarRedux: sidebarReducer,
});

export default rootReducer;
