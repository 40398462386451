export default function renderIcon({ color = '#808080' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="ic_sharp-grid-view"
        d="M3,3h8.889v8.889H3ZM3,14.111h8.889V23H3ZM14.111,3H23v8.889H14.111Zm0,11.111H23V23H14.111Z"
        transform="translate(-3 -3)"
        fill={color}
      />
    </svg>
  );
}
