import React from 'react';
import { useTranslation } from 'react-i18next';
import { Sec2UsageStaticStyled } from './styled';

const Sec2UsageStatic = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec2UsageStaticStyled>
      <div className="usage_static_container">
        <h2 className="us_title">
          {t('Statistics')}
          <div className="us_title_border" />
        </h2>
        <div className="us_row">
          <div className="us_item">
            <img
              className="us_img"
              src="/assets/images/icons/static-1.png"
              alt="usage static"
            />
            <div className="us_score">92 ล้านบาท++</div>
            <div className="us_detail">{t('Value')}</div>
          </div>
          <div className="us_item">
            <img
              className="us_img"
              src="/assets/images/icons/static-2.png"
              alt="usage static"
            />
            <div className="us_score">4.3 แสน ++</div>
            <div className="us_detail">{t('List')}</div>
          </div>
          <div className="us_item">
            <img
              className="us_img"
              src="/assets/images/icons/static-3.png"
              alt="usage static"
            />
            <div className="us_score">2.4 หมื่น ++</div>
            <div className="us_detail">{t('Number of users')}</div>
          </div>
          <div className="us_item">
            <img
              className="us_img"
              src="/assets/images/icons/static-4.png"
              alt="usage static"
            />
            <div className="us_score">99.5%</div>
            <div className="us_detail">{t('User satisfaction')}</div>
          </div>
        </div>
      </div>
    </Sec2UsageStaticStyled>
  );
};

Sec2UsageStatic.propTypes = {};

export default Sec2UsageStatic;
