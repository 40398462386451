import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AgentFeedHashtagStyled } from './styled';

const AgentFeedHashtag = ({ theme_standard, hashtag }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AgentFeedHashtagStyled>
      <div className={customClass}>
        <div className="hasgtag_container">
          <div className="sharp">#</div>
          {hashtag}
        </div>
      </div>
    </AgentFeedHashtagStyled>
  );
};

AgentFeedHashtag.propTypes = {};

export default AgentFeedHashtag;
