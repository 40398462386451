import AvartarVertical from './AvartarVertical';
import Asset from './Asset';
import AssetType1 from './AssetType1';
import AssetType2 from './AssetType2';
import AgentFeedItem from './AgentFeedItem';
import AssetTypeDetail from './AssetTypeDetail';
import AssetValueView from './AssetValueView';
import ActivityTask from './ActivityTask';

const Export = {
  AvartarVertical,
  Asset,
  AssetType1,
  AssetType2,
  AgentFeedItem,
  AssetTypeDetail,
  AssetValueView,
  ActivityTask,
};

export default Export;
