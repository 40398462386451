import styled from 'styled-components';

export const NavbarWidgetStyled = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 100;
  height: 44px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  box-shadow: 0 0 5px #00000026;
  .nav_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: auto;
    .nav_left_col {
      display: flex;
      align-items: center;
      column-gap: 15px;
      .hamburger_wrap {
        display: flex;
        border-radius: 5px;
        padding: 0 8px;
        cursor: pointer;
        background: ${({ theme }) => theme.COLORS.BLUE_1};
      }
    }
    .nav_right_col {
    }
  }
`;
