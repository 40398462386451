import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { Sec6BenefitCoAgentStyled } from './styled';

const Sec6BenefitCoAgent = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec6BenefitCoAgentStyled>
      <div className="benefit_container">
        <h2 className="bnf_title">
          {t('Do you know how good co-agent is?')}
          <div className="bnf_title_border" />
        </h2>
        <p className="bnf_desciption">
          {t(
            'One of Merge features can let brokers cooperate each other independently. It can help reduce the overlap time and reduce the cost. also travel expenses or documents as well. More importantly, there will be a lot of people to help you close the sale faster as well.'
          )}
        </p>
        <div className="intro_btn_regis">{t('Register For Free')}</div>
      </div>
      <img
        className="benefit_backdrop"
        src="/assets/images/bg/benefit-coagent-bg.png"
        alt="merge benefit background"
      />
    </Sec6BenefitCoAgentStyled>
  );
};

Sec6BenefitCoAgent.propTypes = {};

export default Sec6BenefitCoAgent;
