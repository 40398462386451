import styled from 'styled-components';

export const RegisterContainerStyled = styled.div`
  height: calc(100% - 260px - 74px);
  display: flex;
  .form_wrap_layout {
    padding: 22px;
    width: 100%;
    max-width: 300px;
    margin: auto;
    .logo_wrap {
      text-align: center;
      margin-bottom: 18px;
    }
  }
`;
