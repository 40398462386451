import React from 'react';
import { withTranslation } from 'react-i18next';
import { OverviewTabStyled } from './styled';
import {
  CoAgentWidget,
  CoAgentRecommentWidget,
  AssetOverviewWidget,
} from 'widgets';

class OverviewTab extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    return (
      <OverviewTabStyled>
        <div className="section_layout">
          <AssetOverviewWidget />
        </div>
        <div className="section_layout">
          <CoAgentWidget isShowCommission />
        </div>
        <div className="section_layout">
          <CoAgentRecommentWidget />
        </div>
      </OverviewTabStyled>
    );
  }
}

export default withTranslation('common')(OverviewTab);
