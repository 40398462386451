export default function renderIcon({ color = '#d1d1d1' }) {
  return (
    <svg
      id="Group_386"
      data-name="Group 386"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <g id="Group_385" data-name="Group 385">
        <path
          id="Path_496"
          data-name="Path 496"
          d="M20.9,2H4.1A2.177,2.177,0,0,0,2,4.1V7.26A2.121,2.121,0,0,0,3.05,9.035V20.9A2.256,2.256,0,0,0,5.15,23h14.7a2.256,2.256,0,0,0,2.1-2.1V9.035A2.121,2.121,0,0,0,23,7.26V4.1A2.177,2.177,0,0,0,20.9,2ZM19.85,20.9H5.15V9.35h14.7ZM20.9,7.25H4.1V4.1H20.9Z"
          transform="translate(-2 -2)"
          fill={color}
        />
        <rect
          id="Rectangle_16989"
          data-name="Rectangle 16989"
          width="6"
          height="2"
          transform="translate(7.5 10.556)"
          fill={color}
        />
      </g>
    </svg>
  );
}
