import React from 'react';
import { withTranslation } from 'react-i18next';
import { CommissionTabStyled } from './styled';

class CommissionTab extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    const { t } = this.props;
    return (
      <CommissionTabStyled useSuspense={false}>
        {t('ok')}
        <button onClick={this.handleClickChangLang}>CommissionTab</button>
      </CommissionTabStyled>
    );
  }
}

export default withTranslation('common')(CommissionTab);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CommissionTab);
