import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { Label2LabelStyled } from './styled';

const Label2Label = ({ theme_standard, leftLabel, rightLabel }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <Label2LabelStyled>
      <div className={customClass}>
        <div className="lb_2_lb_container">
          <div className="lb_left_col">{leftLabel}</div>
          <div className="lb_right_col">{rightLabel}</div>
        </div>
      </div>
    </Label2LabelStyled>
  );
};

Label2Label.propTypes = {};

export default Label2Label;
