import styled from 'styled-components';

export const LabelRectangularStatusStyled = styled.div`
  .label_rec_container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    color: ${({ theme }) => theme.COLORS.GRAY_5};
    .rec_box {
      width: 18px;
      height: 18px;
      background: ${({ bgStatus }) => bgStatus};
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      border-radius: 2px;
    }
  }

  .theme_standard {
  }
`;
