import React from 'react';
import { withTranslation } from 'react-i18next';
import { ManageAssetContainerStyled } from './styled';

class ManageAssetContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    const { t } = this.props;
    return (
      <ManageAssetContainerStyled useSuspense={false}>
        ...ManageAssetContainer Comming Soon
      </ManageAssetContainerStyled>
    );
  }
}

export default withTranslation('common')(ManageAssetContainer);
