import { ShareAssetAllWidgetStyled } from './styled';
import { Blocks, Tables, Icons, Displays, Buttons } from 'components';
import moment from 'moment';
import theme from 'styles/theme.json';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { AssetTrackingStatus } from 'utils/functions/asset';

const ShareAssetAllWidget = () => {

  return (
    <ShareAssetAllWidgetStyled>
      <Blocks.BlockShadow>
        <div className="sa_row_2_col">
          <div className="sa_left_col">
            <Tables.TitleBodyFooter
              themeColor={theme.COLORS.BLUE_1}
              renderHeaderType="iconCountLabel"
              headIcon={<Icons.AssetShare />}
              headSumCount={'130'}
              headTitle="ทรัพย์ที่ท่านแบ่งปัน"
              columns={columnShare()}
              data={dataShare}
              renderFooterType="updateDated"
              updatedDate={moment().format('DD-MM-YYYY')}
            />
          </div>
          <div className="sa_right_col">
            <Tables.TitleBodyFooter
              theme_no_column
              themeColor={theme.COLORS.BLUE_1}
              renderHeaderType="title"
              headTitle="Activity Task"
              columns={columnActivity()}
              data={dataActivity}
            />
          </div>
        </div>
      </Blocks.BlockShadow>
    </ShareAssetAllWidgetStyled>
  );
};

const columnShare = () => [
  {
    Header: 'ชื่อทรัพย์',
    accessor: 'asset_name',
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'ราคา',
    accessor: 'price',
    Cell: ({
      value,
      // columnProps: {
      //   rest: { someFunc },
      // },
    }) => <div style={{ textAlign: 'right' }}>{value}</div>,
    // getProps: () => ({ someFunc: () => alert("clicked")})
  },
  {
    Header: '%แชร์',
    accessor: 'comission_percent',
    headerStyle: { textAlign: 'right' },
    Cell: ({ value }) => <div style={{ textAlign: 'right' }}>{value}</div>,
  },
  {
    Header: 'ค่าคอมมิชชั่น',
    accessor: 'commission',
    headerStyle: { textAlign: 'right' },
    Cell: ({ value }) => <div style={{ textAlign: 'right' }}>{value}</div>,
  },
  {
    Header: 'สถานะ',
    accessor: 'status',
    headerStyle: { textAlign: 'right' },
    Cell: ({ value }) => (
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Displays.StatusProcess statusProcess={AssetTrackingStatus('3').no} />
      </div>
    ),
  },
];

const dataShare = [
  {
    asset_name: 'MARS HOUSE',
    price: RenderCommaMoney('15000000', true),
    comission_percent: '3 %',
    commission: '1080000',
    status: 'จอง',
  },
  {
    asset_name: 'เรือนไม้ เรือนนอน',
    price: RenderCommaMoney('2000000', true),
    comission_percent: '5 %',
    commission: '80000',
    status: 'ทำสัญญา',
  },
  {
    asset_name: 'Astra Condo',
    price: RenderCommaMoney('2200000', true),
    comission_percent: '4 %',
    commission: '1080000',
    status: 'ธุรกรรม',
  },
];

const columnActivity = () => [
  {
    Header: '',
    accessor: 'date',
    Cell: ({ value }) => (
      <div style={{ color: theme.COLORS.GRAY_2 }}>
        {moment().format('DD-MM-YYYY')}
      </div>
    ),
  },
  {
    Header: '',
    accessor: 'owner',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center', color: theme.COLORS.BLUE_1 }}>
        {value}
      </div>
    ),
  },
  {
    Header: '',
    accessor: 'process',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>
        {
          <Buttons.Normal
            theme_process
            label="Negotiate Process"
            bg={theme.COLORS.GREEN_5}
          />
        }
      </div>
    ),
  },
];

const dataActivity = [
  {
    // date:
    owner: 'Kikproperty',
    // process
  },
];

export default ShareAssetAllWidget;
