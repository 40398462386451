import styled from 'styled-components';

export const CoinWidgetStyled = styled.div`
  padding: 16px 12px 5px 12px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 5px;
  .row_2_col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .left_col {
    }
    .right_col {
    }
  }
  .txt_detail_col {
    text-align: right;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
`;
