import RankBlock from './RankBlock';
import MergeCoin from './MergeCoin';
import LineBetween from './LineBetween';
import Discount from './Discount';
import AvatarRankCircle from './AvatarRankCircle';
import AvatarCompany from './AvatarCompany';
import AvatarHorizontal from './AvatarHorizontal';
import PostMsgActions from './PostMsgActions';
import AgentFeedHashtag from './AgentFeedHashtag';
import LabelRectangularStatus from './LabelRectangularStatus';
import StatusProcess from './StatusProcess';
import StatusListCount from './StatusListCount';
import AssetLabelCount from './AssetLabelCount';

const Export = {
  RankBlock,
  MergeCoin,
  LineBetween,
  Discount,
  AvatarRankCircle,
  AvatarCompany,
  AvatarHorizontal,
  PostMsgActions,
  AgentFeedHashtag,
  LabelRectangularStatus,
  StatusProcess,
  StatusListCount,
  AssetLabelCount,
};

export default Export;
