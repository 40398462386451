import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { Sec4ProcessStyled } from './styled';

const Sec4Process = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec4ProcessStyled>
      <div className="procee_container">
        <h2 className="p_title">{t('When you use you will love Merge')}</h2>
        <div className="process_row">
          {contentList &&
            contentList.map((e, i) => (
              <div key={i} className="process_item">
                <img src={e.imgPath} alt="merge process" />
                <h3>{t(e.label)}</h3>
              </div>
            ))}
        </div>
      </div>
    </Sec4ProcessStyled>
  );
};

const contentList = [
  {
    label: 'Choose broker by youself',
    imgPath: '/assets/images/icons/benefit-1.png',
  },
  {
    label: 'Manage assets',
    imgPath: '/assets/images/icons/benefit-2.png',
  },
  {
    label: 'Share benefits',
    imgPath: '/assets/images/icons/benefit-3.png',
  },
];

Sec4Process.propTypes = {};

export default Sec4Process;
