import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { StatusListCountStyled } from './styled';

const StatusListCount = ({ theme_standard, bg, statusList }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <StatusListCountStyled bg={bg}>
      <div className={customClass}>
        <div className="slc_row_container">
          {statusList &&
            statusList.map((e, i) => (
              <div key={i} className="status_item">
                {e.label} ({e.count})
              </div>
            ))}
        </div>
      </div>
    </StatusListCountStyled>
  );
};

StatusListCount.propTypes = {};

export default StatusListCount;
