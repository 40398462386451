export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.333"
      height="12"
      viewBox="0 0 11.333 12"
    >
      <path
        id="bx_bxs-share-alt"
        d="M3,9a1.994,1.994,0,0,0,3.366,1.453l3.994,2.283a1.9,1.9,0,0,0-.027.264,2.013,2.013,0,1,0,.634-1.453L6.973,9.265A1.924,1.924,0,0,0,7,9a1.924,1.924,0,0,0-.027-.265l3.994-2.282A1.995,1.995,0,1,0,10.333,5a1.924,1.924,0,0,0,.027.265L6.366,7.547A1.994,1.994,0,0,0,3,9Z"
        transform="translate(-3 -3)"
        fill={color}
      />
    </svg>
  );
}
