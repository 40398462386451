import { useState } from 'react';
import { NavbarLandingWidgetStyled } from './styled';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { BtnLanguageWidget } from 'widgets';
import { Icons } from 'components';

const NavbarLandingWidget = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['navigation']);

  const [_isShow, _setIsShow] = useState(false);

  const _handleClickNavMenu = (route) => {
    console.log('ja');
    if (_isShow) {
      _handleClickDisSidebar();
    }
    navigate(route);
  };

  const _handleClickEnbSidebar = () => {
    console.log('ma');
    _setIsShow(true);
  };

  const _handleClickDisSidebar = () => {
    _setIsShow(false);
  };

  return (
    <NavbarLandingWidgetStyled isShow={_isShow}>
      <div className="nav_left_col">
        <Icons.Logo />
      </div>
      <div className="nav_right_col">
        {menuList(t).map((e, i) => (
          <div
            key={i}
            className={e.className}
            onClick={() => _handleClickNavMenu(e.routePath)}
          >
            {e.label}
          </div>
        ))}
        <BtnLanguageWidget />
      </div>
      <div className="nav_hamburger_right_col">
        <div onClick={_handleClickEnbSidebar}>
          <Icons.Hamburger />
        </div>
        <div className="sidebar_nav_show_container">
          <div className="side_nav">
            {menuList(t).map((e, i) => (
              <div
                key={i}
                className="sidebar_item"
                onClick={() => _handleClickNavMenu(e.routePath)}
              >
                {e.label}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="navbar_backdrop" onClick={_handleClickDisSidebar} />
    </NavbarLandingWidgetStyled>
  );
};

const menuList = (t) => [
  {
    className: 'item_lb_right',
    label: t('Home'),
    routePath: ROUTE_PATH.ROOT,
  },
  // {
  //   className: 'item_lb_right',
  //   label: t('Contact'),
  //   routePath: ROUTE_PATH.ROOT,
  // },
  {
    className: 'item_lb_right',
    label: t('Log In'),
    routePath: ROUTE_PATH.LOGIN,
  },
  {
    className: 'item_lb_right',
    label: t('Register'),
    routePath: ROUTE_PATH.REGISTER,
  },
];

export default NavbarLandingWidget;
