export default function renderIcon({
  color = '#fff',
  width = '15',
  height = '15',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15 15"
    >
      <path
        id="akar-icons_circle-plus-fill"
        d="M8.5,1A7.5,7.5,0,1,0,16,8.5,7.5,7.5,0,0,0,8.5,1Zm.682,10.227a.682.682,0,0,1-1.364,0V9.182H5.773a.682.682,0,0,1,0-1.364H7.818V5.773a.682.682,0,0,1,1.364,0V7.818h2.045a.682.682,0,0,1,0,1.364H9.182Z"
        transform="translate(-1 -1)"
        fill={color}
        fill-rule="evenodd"
      />
    </svg>
  );
}
