import { PromotionSidebarWidgetStyled } from './styled';

const PromotionSidebarWidget = () => {
  return (
    <PromotionSidebarWidgetStyled>
      <div className="promotion_figture">
        <img src="/assets/images/mockup/promotion-default.jpg" alt="merge promotion"/>
      </div>
    </PromotionSidebarWidgetStyled>
  );
};

export default PromotionSidebarWidget;
