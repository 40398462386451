import React from 'react';
import { withTranslation } from 'react-i18next';
import { LandingPageContainerStyled } from './styled';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import { LandingPages } from 'components';

class LandingPageContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  onSubmit = () => {
    this.props.router.navigate(ROUTE_PATH.DASHBOARD);
  };

  render() {
    return (
      <LandingPageContainerStyled>
        <LandingPages.Sec1Introduction />
        <LandingPages.Sec2UsageStatic />
        <LandingPages.Sec3MergeSolveProblems />
        <LandingPages.Sec4Process />
        <LandingPages.Sec5ProgramForYou />
        <LandingPages.Sec6BenefitCoAgent />
        <LandingPages.Sec7CoAgentProcess />
        <LandingPages.Sec8Partner />
        <LandingPages.Sec9RegisWithMe />
      </LandingPageContainerStyled>
    );
  }
}

export default withTranslation('common')(withRouter(LandingPageContainer));

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(LandingPageContainer);
