import styled from 'styled-components';

export const StatusListCountStyled = styled.div`
  .slc_row_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    background: ${({ bg }) => bg};
    border-left-bottom-radius: 5px;
    border-right-bottom-radius: 5px;
    .status_item {
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }

  .theme_standard {
  }
`;
