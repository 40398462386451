import { AssetItemListLayoutStyled } from './styled';
import { Cards } from 'components';
import theme from 'styles/theme.json';

const AssetItemListLayout = () => {
  return (
    <AssetItemListLayoutStyled>
      <div className="asset_item_row">
        {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((e, i) => (
          <div key={i} className="asset_item_wrap">
            <Cards.AssetType1
              assetName="Astra Condominium"
              assetImg={'/assets/images/mockup/asset_recomment_1.jpg'}
              address="ช้างคลาน + เมืองเชียงใหม่"
              discountNo="33%"
              discountBG={theme.COLORS.GREEN_1}
              commission="115000"
              price="11500"
              userName="Kikproperty"
              userImg={'/assets/images/mockup/avatar.jpg'}
              teameLogo={'/assets/images/mockup/team-logo.png'}
              updatedDate="7 Days ago"
              userRank=""
            />
          </div>
        ))}
      </div>
    </AssetItemListLayoutStyled>
  );
};

export default AssetItemListLayout;
