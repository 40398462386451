export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="20"
      viewBox="0 0 1 20"
    >
      <line
        id="Line_93"
        data-name="Line 93"
        y2="20"
        transform="translate(0.5)"
        fill="none"
        stroke={color}
        stroke-width="1"
      />
    </svg>
  );
}
