export default function renderIcon({ color = '#d1d1d1' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20.07"
      viewBox="0 0 19 20.07"
    >
      <path
        id="Path_506"
        data-name="Path 506"
        d="M12.489,19.407,4.71,13.377,3,14.7l9.5,7.367L22,14.7l-1.721-1.337-7.79,6.041Zm.011-2.673,7.769-6.03L22,9.367,12.5,2,3,9.367,4.721,10.7Z"
        transform="translate(-3 -2)"
        fill={color}
      />
    </svg>
  );
}
