import React from 'react';
import { withTranslation } from 'react-i18next';
import { CoAssetsContainerStyled } from './styled';

class CoAssetsContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    return (
      <CoAssetsContainerStyled useSuspense={false}>
        ...Co Assets Comming Soon
      </CoAssetsContainerStyled>
    );
  }
}

export default withTranslation('common')(CoAssetsContainer);
