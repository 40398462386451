import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AssetStyled } from './styled';
import { Icons, Displays } from 'components';

const Asset = ({
  theme_standard,
  bg,
  no,
  icon,
  label,
  routeAdd,
  updatedDate,
  handleClickSelected,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AssetStyled bg={bg}>
      <div className={customClass}>
        <div className="asset_container">
          <div className="asset_price">มูลค่า +</div>
          <div className="a_icon">{icon}</div>
          <Displays.AssetLabelCount
            no={no}
            label={label}
            icon={routeAdd && <Icons.AddAsset />}
          />
          <div className="update_date">Update : {updatedDate}</div>
        </div>
      </div>
    </AssetStyled>
  );
};

Asset.propTypes = {};

export default Asset;
