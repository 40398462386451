export default function renderIcon({ color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        id="Group_bt_Menu"
        data-name="Group bt Menu"
        transform="translate(-14965 -134)"
      >
        <g id="sync_alt_black_24dp" transform="translate(14965 134)">
          <g id="Group_393" data-name="Group 393">
            <rect
              id="Rectangle_17007"
              data-name="Rectangle 17007"
              width="25"
              height="25"
              fill="none"
            />
          </g>
          <g id="Group_395" data-name="Group 395" transform="translate(2 4.5)">
            <g id="Group_394" data-name="Group 394">
              <path
                id="Path_512"
                data-name="Path 512"
                d="M18.789,12,23,8,18.789,4V7H3V9H18.789Z"
                transform="translate(-2 -4)"
                fill={color}
              />
              <path
                id="Path_513"
                data-name="Path 513"
                d="M6.211,12,2,16l4.211,4V17H22V15H6.211Z"
                transform="translate(-2 -4)"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
