import styled from 'styled-components';

export const PrivacyPolicyContainerStyled = styled.div`
  padding: 2rem 1.5rem;
  .privacy_title {
    margin-bottom: 1rem;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  }
  .p_content {
    margin-bottom: 0.75rem;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
  }
  .p_sub_title {
    margin-bottom: 0.5rem;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
  }
  .sub_content {
    margin-bottom: 0.5rem;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
  }
  .address_title {
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  }
  .address {
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
  }
`;
