export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      id="military_tech_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g id="Group_391" data-name="Group 391">
        <rect
          id="Rectangle_17005"
          data-name="Rectangle 17005"
          width="25"
          height="25"
          fill="none"
        />
      </g>
      <g id="Group_392" data-name="Group 392" transform="translate(7.5 2)">
        <path
          id="Path_511"
          data-name="Path 511"
          d="M17,10.851V2H7v8.851a1.051,1.051,0,0,0,.49.9l4.18,2.635-.99,2.457-3.41.3L9.86,19.5,9.07,23,12,21.141,14.93,23l-.78-3.5,2.59-2.352-3.41-.3-.99-2.457,4.18-2.635A1.052,1.052,0,0,0,17,10.851Zm-4,1.89-1,.63-1-.63V3.05h2Z"
          transform="translate(-7 -2)"
          fill={color}
        />
      </g>
    </svg>
  );
}
