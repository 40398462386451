import styled from 'styled-components';

export const Sec2UsageStaticStyled = styled.div`
  position: relative;
  min-height: calc(100vh - 154px);
  padding: 30px 26px 50px 26px;
  display: flex;
  align-items: center;
  background-image: url('/assets/images/bg/static-bg.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  .usage_static_container {
    flex: 1;
    margin: auto;
    width: 100%;
    max-width: 1440px;
    .us_title {
      margin: auto;
      margin-bottom: 106px;
      position: relative;
      width: fit-content;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S50};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_3};
      .us_title_border {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        width: 90%;
        height: 4px;
        background: ${({ theme }) => theme.COLORS.BLUE_11};
      }
    }
    .us_row {
      display: flex;
      column-gap: 30px;
      row-gap: 30px;
      .us_item {
        flex: 1;
        border-radius: 15px;
        -webkit-box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.16);
        min-width: 215px;
        min-height: 410px;
        padding: 12px 22px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        text-align: center;
        .us_img {
          margin: auto;
          margin-bottom: 18px;
          width: 180px;
          height: 180px;
        }
        .us_score {
          margin-bottom: 32px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S36};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLUE_11};
        }
        .us_detail {
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .usage_static_container {
      .us_row {
        flex-wrap: wrap;
        .us_item {
          flex-basis: calc(50% - 60px);
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    .usage_static_container {
      .us_title {
        margin-bottom: 60px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S34};
      }
      .us_row {
        .us_item {
          .us_score {
            margin-bottom: 32px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S30};
          }
          .us_detail {
            font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          }
        }
      }
    }
  }
`;
