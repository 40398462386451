import { NavbarWidgetStyled } from './styled';
import { useSelector, useDispatch } from 'react-redux';
import { setReduxIsShowSidebar } from 'store/actions';
import { Icons } from 'components';

const NavbarWidget = () => {
  const dispatch = useDispatch();
  const { sidebarRedux } = useSelector((state) => state);

  const handleClickMenu = () => {
    dispatch(setReduxIsShowSidebar({ isShow: !sidebarRedux.isShow }));
  };

  return (
    <NavbarWidgetStyled>
      <div className="nav_container">
        <div className="nav_left_col">
          <img src={'/assets/images/logos/logo-merge.svg'} alt="merge logo"/>
          <div className="hamburger_wrap" onClick={handleClickMenu}>
            <Icons.ShowHideSidebar />
          </div>
        </div>
        <div className="nav_right_col"></div>
      </div>
    </NavbarWidgetStyled>
  );
};

export default NavbarWidget;
