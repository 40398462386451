import React from 'react';
import { LoginFormStyled } from './styled';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';

const LoginForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let navigate = useNavigate();

  const _handleCLickCreateAccount = () => {
    console.log('dsfdsf');
    navigate(ROUTE_PATH.REGISTER);
  };

  return (
    <LoginFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="title">ลงชื่อเข้าใช้</div>
        <div className="input_wrap_layout">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'อีเมลล์'}
                errors={errors.email?.message}
              />
            )}
            name="email"
            defaultValue=""
          />
        </div>
        <div className="input_wrap_layout">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'รหัสผ่าน'}
                errors={errors.password?.message}
                type="password"
              />
            )}
            name="password"
            defaultValue=""
          />
        </div>
        <div className="input_wrap_layout mb_28">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckbox
                {...field}
                label={'จำรหัสผ่าน'}
                // errors={errors.phone?.message}
              />
            )}
            name="dd"
            defaultValue=""
          />
        </div>
        <div className="btn_submit_layout">
          <Buttons.ButtonFullWidth type="submit" label="เข้าสู่ระบบ" />
        </div>
        <div className="txt_account" onClick={_handleCLickCreateAccount}>
          ยังไม่มีบัญชีหรือ <span>สร้างบัญชีกับเรา</span>
        </div>
        <div className="txt_forget">ลืมรหัสผ่าน</div>
      </form>
    </LoginFormStyled>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
