import React from 'react';
import { withTranslation } from 'react-i18next';
import { RegisterContainerStyled } from './styled';
import { Forms, Icons } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';

class RegisterContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  onSubmit = () => {
    this.props.router.navigate(ROUTE_PATH.DASHBOARD);
  };

  render() {
    return (
      <RegisterContainerStyled>
        <div className="form_wrap_layout">
          <div className="logo_wrap">
            <Icons.Logo />
          </div>
          <Forms.RegisterForm onSubmit={this.onSubmit} />
        </div>
      </RegisterContainerStyled>
    );
  }
}

export default withTranslation('common')(withRouter(RegisterContainer));
