import styled from 'styled-components';

export const InputCheckboxStyled = styled.div`
  .checkbox_container {
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-weight: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_4};
    display: flex;
    align-items: center;
    input {
      margin-right: 13px;
    }
  }
  .errors {
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S10};
  }

  .theme_standard {
  }
`;
