import styled from 'styled-components';

export const Sec6BenefitCoAgentStyled = styled.div`
  position: relative;
  min-height: calc(100vh - 74px);
  padding: 0 50px;
  background: ${({ theme }) => theme.COLORS.BLUE_12};
  display: flex;
  align-items: center;
  .benefit_container {
    margin: auto;
    width: 100%;
    max-width: 1440px;
    flex: 1;
    .bnf_title {
      margin-bottom: 55px;
      padding-bottom: 26px;
      position: relative;
      width: fit-content;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S36};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      .bnf_title_border {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        width: 90%;
        height: 4px;
        background: ${({ theme }) => theme.COLORS.BLUE_10};
      }
    }
    .bnf_desciption {
      margin-bottom: 50px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S24};
      font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    }
    .intro_btn_regis {
      -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: 1px solid ${({ theme }) => theme.COLORS.BLUE_8};
      width: fit-content;
      padding: 14px 18px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S36};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLUE_9};
      cursor: pointer;
    }
  }

  .benefit_backdrop {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    padding: 30px 22px;
  }
`;
