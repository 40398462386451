export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      id="credit_score_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g id="Group_396" data-name="Group 396">
        <path
          id="Path_514"
          data-name="Path 514"
          d="M0,0H25V25H0Z"
          fill="none"
        />
      </g>
      <g id="Group_397" data-name="Group 397" transform="translate(2 5)">
        <path
          id="Path_515"
          data-name="Path 515"
          d="M20.9,4H4.1A2.035,2.035,0,0,0,2.01,6L2,18a2.045,2.045,0,0,0,2.1,2H9.35V18H4.1V12H23V6A2.045,2.045,0,0,0,20.9,4Zm0,4H4.1V6H20.9ZM15.576,19.17,12.6,16.34l-1.481,1.41L15.576,22,23,14.93l-1.48-1.41Z"
          transform="translate(-2 -4)"
          fill={color}
        />
      </g>
    </svg>
  );
}
