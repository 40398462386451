import styled from 'styled-components';

export const AssetOverviewWidgetStyled = styled.div`
  .aov_container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    .aov_left_col {
      flex: 1;
      display: flex;
      column-gap: 10px;
      row-gap: 10px;
      .card_asset_wrap {
        flex: 1;
      }
    }
    .aov_right_col {
      flex: 1;
    }
  }

  @media (max-width: 1250px) {
    .aov_container {
      flex-direction: column;
      .aov_left_col {
        flex-wrap: wrap;
        .card_asset_wrap {
          flex: none;
          width: calc(50% - 5px);
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .aov_container {
      .aov_left_col {
        .card_asset_wrap {
          width: 100%;
        }
      }
    }
  }
`;
