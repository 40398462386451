import styled from 'styled-components';

export const PromotionSidebarWidgetStyled = styled.div`
  .promotion_figture {
    height: 100px;
    img {
      width: 100%;
      height: 100px;
      border-radius: 5px;
      object-fit: cover;
    }
  }
`;
