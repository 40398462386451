import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AgentFeedItemStyled } from './styled';
import { Displays, Icons } from 'components';

const AgentFeedItem = ({
  theme_standard,
  userName,
  userRank,
  userImg,
  postDated,
  msg,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AgentFeedItemStyled>
      <div className={customClass}>
        <div className="agent_feed_container">
          <div className="ag_header_row_2_col">
            <div className="ag_left_col">
              <Displays.AvatarHorizontal
                userName={userName}
                userImg={userImg}
                userRank={userRank}
                postDated={postDated}
                renderContentType="postDated"
              />
            </div>
            <div className="ag_right_col">
              <Icons.DotMore />
            </div>
          </div>
          <Displays.PostMsgActions msg={msg} />
        </div>
      </div>
    </AgentFeedItemStyled>
  );
};

AgentFeedItem.propTypes = {};

export default AgentFeedItem;
