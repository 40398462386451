import styled from 'styled-components';

export const AvatarRankCircleStyled = styled.div`
  width: 30px;
  height: 30px;
  border: 3px solid ${({ rankColor }) => rankColor};
  border-radius: 30px;
  overflow: hidden;
  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
`;
