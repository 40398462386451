import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ActivityTaskStyled } from './styled';
import { Displays, Buttons, Icons, Typhographys } from 'components';
import theme from 'styles/theme.json';

const ActivityTask = ({
  theme_standard,
  userImg,
  userRank,
  ownerName,
  transactionNo,
  assetImg,
  assetCommissionPercent,
  assetBonus,
  shareCommissionPercent,
  shareAssetBonus,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ActivityTaskStyled>
      <div className={customClass}>
        <div className="avt_container">
          <div className="avt_top_layout">
            <div className="t_row_coagent">
              <div className="rc_left_col">
                <Displays.AvatarRankCircle rankColor={userRank} src={userImg} />
                <div className="owner_name">{ownerName}</div>
                <Buttons.BtnIconPrefix
                  theme_standard
                  btnStyCus={{
                    background: theme.COLORS.RED_2,
                    padding: '4px 12px',
                  }}
                  txtStyCus={{
                    color: theme.COLORS.WHITE_1,
                  }}
                  icon={<Icons.Chat />}
                  label="พูดคุย"
                />
              </div>
              <div className="rc_right_col">
                Transaction number : {transactionNo}
              </div>
            </div>
            <div className="t_line_between" />
            <div className="t_row_asset">
              <div className="ra_left_col">
                <img className="asset_img" src={assetImg} alt="merge asset"/>
              </div>
              <div className="ra_right_col">
                <div className="rc_left_col">
                  <div className="rc_content">
                    <div className="rc_title">ผลประโยชน์ทรัพย์</div>
                    <div className="typho_wrap mb_10">
                      <Typhographys.Label2Label
                        leftLabel="ค่านายหน้า"
                        rightLabel={`${assetCommissionPercent}%`}
                      />
                    </div>
                    <div className="typho_wrap">
                      <Typhographys.Label2Label
                        leftLabel="โบนัส"
                        rightLabel={assetBonus}
                      />
                    </div>
                  </div>
                  <div className="rc_content">
                    <div className="rc_title">ผลประโยชน์ที่แบ่งปัน</div>
                    <div className="typho_wrap mb_10">
                      <Typhographys.Label2Label
                        leftLabel="ค่านายหน้า"
                        rightLabel={`${shareCommissionPercent}%`}
                      />
                    </div>
                    <div className="typho_wrap">
                      <Typhographys.Label2Label
                        leftLabel="โบนัส"
                        rightLabel={shareAssetBonus}
                      />
                    </div>
                  </div>

                  {/* <div className="rc_title">ผลประโยชน์ที่แบ่งปัน</div>
                  <div className="typho_wrap mb_10">
                    <Typhographys.Label2Label
                      leftLabel="ค่านายหน้า"
                      rightLabel={`${shareCommissionPercent}%`}
                    />
                  </div>
                  <div className="typho_wrap">
                    <Typhographys.Label2Label
                      leftLabel="โบนัส"
                      rightLabel={shareAssetBonus}
                    />
                  </div> */}
                </div>
                <div className="rc_right_col">
                  <Icons.DocumentType1 />
                  <Buttons.Normal label="ดูรายละเอียด" />
                </div>
              </div>
            </div>
          </div>
          <div className="avt_bottom_layout">
            <div className="avt_label">Activity Task</div>
            <div className="line_vertical_between" />
            <Buttons.Normal
              theme_process
              label="Negotiate Process"
              bg={theme.COLORS.GREEN_5}
            />
            <Icons.DocumentType2 />
          </div>
        </div>
      </div>
    </ActivityTaskStyled>
  );
};

ActivityTask.propTypes = {};

export default ActivityTask;
