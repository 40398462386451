import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AssetValueViewStyled } from './styled';
import Chart from 'react-apexcharts';
import theme from 'styles/theme.json';

const AssetValueView = ({
  theme_standard,
  total,
  houseTotal,
  condoTotal,
  landTotal,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AssetValueViewStyled>
      <div className={customClass}>
        <div className="asset_value_container">
          <div className="av_title">
            มูลค่าทรัพย์ทั้งหมด <span>{total}</span> ล้าน
          </div>
          <div className="av_row">
            <div className="av_left_col">
              <Chart
                options={{
                  chart: {
                    id: 'apexchart-example',
                    toolbar: {
                      show: false,
                    },
                  },
                  dataLabels: {
                    formatter: function (val, opts) {
                      return `${Number(val).toFixed(0)}%`;
                    },
                  },
                  tooltip: {
                    y: {
                      formatter: function (
                        value,
                        { series, seriesIndex, dataPointIndex, w }
                      ) {
                        return `${value}%`;
                      },
                    },
                  },
                  legend: {
                    show: false,
                  },
                  plotOptions: {
                    pie: {},
                  },
                  colors: [
                    theme.COLORS.GREEN_1,
                    theme.COLORS.BLUE_2,
                    theme.COLORS.YELLOW_1,
                  ],
                  labels: ['บ้าน', 'คอนโด', 'ที่ดิน'],
                }}
                series={[44, 55, 41]}
                type="donut"
                width="225px"
              />
            </div>
            <div className="av_right_col">
              <div className="content_block">
                <div className="row">
                  <div className="left_col">
                    <div className="dot red" />
                    <div className="txt_prefix">บ้าน</div>
                  </div>
                  <div className="right_col">
                    <div className="txt_value">{houseTotal}</div>
                    <div className="txt_suffix">ล้าน</div>
                  </div>
                </div>
                <div className="row">
                  <div className="left_col">
                    <div className="dot blue" />
                    <div className="txt_prefix">คอนโด</div>
                  </div>
                  <div className="right_col">
                    <div className="txt_value">{condoTotal}</div>
                    <div className="txt_suffix">ล้าน</div>
                  </div>
                </div>
                <div className="row">
                  <div className="left_col">
                    <div className="dot yellow" />
                    <div className="txt_prefix">ที่ดิน</div>
                  </div>
                  <div className="right_col">
                    <div className="txt_value">{landTotal}</div>
                    <div className="txt_suffix">ล้าน</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AssetValueViewStyled>
  );
};

AssetValueView.propTypes = {};

export default AssetValueView;
