import { CoAgentRecommentWidgetStyled } from './styled';
import { Blocks, Typhographys, Cards } from 'components';
import theme from 'styles/theme.json';

const CoAgentRecommentWidget = () => {
  return (
    <CoAgentRecommentWidgetStyled>
      <Blocks.BlockShadow>
        <div className="title_wrap">
          <Typhographys.TitleButton title="ทรัพย์เสนอ Co-Agent สำหรับท่าน" />
        </div>
        <Cards.AssetType1
          assetName="Astra Condominium"
          assetImg={'/assets/images/mockup/asset_recomment_1.jpg'}
          address="ช้างคลาน + เมืองเชียงใหม่"
          discountNo="33%"
          discountBG={theme.COLORS.GREEN_1}
          commission="115000"
          price="11500"
          userName="Kikproperty"
          userImg={'/assets/images/mockup/avatar.jpg'}
          teameLogo={'/assets/images/mockup/team-logo.png'}
          updatedDate="7 Days ago"
          userRank=""
        />
      </Blocks.BlockShadow>
    </CoAgentRecommentWidgetStyled>
  );
};

export default CoAgentRecommentWidget;
