import { PageLandingLayoutStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { NavbarLandingWidget, LandingPageFooterWidget } from 'widgets';

const PageLandingLayout = () => {
  return (
    <PageLandingLayoutStyled>
      <NavbarLandingWidget />
      <div className="landing_body_layout">
        <Outlet />
      </div>
      <LandingPageFooterWidget />
    </PageLandingLayoutStyled>
  );
};

export default PageLandingLayout;
