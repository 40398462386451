import { ShareAssetTransactionWidgetStyled } from './styled';
import { Blocks, Cards } from 'components';
import theme from 'styles/theme.json';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const ShareAssetTransactionWidget = () => {
  return (
    <ShareAssetTransactionWidgetStyled>
      <Blocks.BlockShadow>
        <div className="activity_task_wrap">
          <Cards.ActivityTask
            userImg="/assets/images/mockup/avatar-mini.jpg"
            userRank={theme.COLORS.GOLD_1}
            ownerName={'Kikproperty'}
            transactionNo={'1234567890'}
            assetImg="/assets/images/mockup/asset_recomment_1.jpg"
            assetCommissionPercent={'7'}
            assetBonus={RenderCommaMoney('15000000', true)}
            shareCommissionPercent={'4'}
            shareAssetBonus={RenderCommaMoney('7200000', true)}
          />
        </div>
      </Blocks.BlockShadow>
    </ShareAssetTransactionWidgetStyled>
  );
};

export default ShareAssetTransactionWidget;
