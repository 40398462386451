export default function renderIcon({ color = '#030783' }) {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 5.00005V0.800049H0V5.00005H28ZM28 13.4001V9.20005H0V13.4001H28ZM28 21.8001V17.6001H0V21.8001H28Z"
        fill={color}
      />
    </svg>
  );
}
