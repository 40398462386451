import styled from 'styled-components';

export const AssetTypeOverviewWidgetStyled = styled.div`
  .asset_type_container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    .at_left_col {
      flex: 1;
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      row-gap: 10px;
      .atd_wrap {
      }
    }
    .at_right_col {
      flex: 1;
      padding-top: 12px;
    }
  }

  @media (max-width: 1140px) {
    .asset_type_container {
      flex-direction: column;
      .at_left_col {
        flex-wrap: wrap;
        justify-content: center;
        .atd_wrap {
          width: calc(50% - 5px);
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .asset_type_container {
      .at_left_col {
        .atd_wrap {
          width: 100%;
        }
      }
    }
  }
`;
