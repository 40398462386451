import React from 'react';
import { withTranslation } from 'react-i18next';
import { AssetAllTabStyled } from './styled';
import { ShareAssetAllWidget, ShareAssetTransactionWidget } from 'widgets';

class AssetAllTab extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    return (
      <AssetAllTabStyled useSuspense={false}>
        <div className="section_layout">
          <ShareAssetAllWidget />
        </div>
        <div className="section_layout">
          <ShareAssetTransactionWidget />
        </div>
      </AssetAllTabStyled>
    );
  }
}

export default withTranslation('common')(AssetAllTab);
