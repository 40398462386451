import { CoAgentWidgetStyled, CommissionStyled } from './styled';
import theme from 'styles/theme.json';
import {
  Blocks,
  Typhographys,
  Buttons,
  Icons,
  Displays,
  Tables,
} from 'components';
import moment from 'moment';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { AssetTrackingStatus } from 'utils/functions/asset';

const CoAgentWidget = ({ isShowCommission }) => {
  return (
    <CoAgentWidgetStyled>
      <Blocks.BlockShadow>
        <div className="title_wrap">
          <Typhographys.TitleButton title="Co-Agent" />
        </div>
        <div className="row_action">
          <div className="ra_left_col">
            <div className="lrs_wrap">
              <Displays.LabelRectangularStatus
                bgStatus={theme.COLORS.RED_1}
                label="จอง"
              />
            </div>
            <div className="lrs_wrap">
              <Displays.LabelRectangularStatus
                bgStatus={theme.COLORS.YELLOW_2}
                label="ทำสัญญา"
              />
            </div>
            <div className="lrs_wrap">
              <Displays.LabelRectangularStatus
                bgStatus={theme.COLORS.ORANGE_1}
                label="ธุรกรรม"
              />
            </div>
            <div className="lrs_wrap">
              <Displays.LabelRectangularStatus
                bgStatus={theme.COLORS.GREEN_4}
                label="โอนกรรมสิทธิ์"
              />
            </div>
          </div>
          <div className="ra_right_col">
            <Buttons.BtnIconPrefix
              theme_standard
              icon={<Icons.ShareType2 />}
              label="แชร์ทรัพย์เพิ่ม"
            />
          </div>
        </div>
        <div className="ca_row_2_col">
          <div className="left_col">
            <Tables.TitleBodyFooter
              themeColor={theme.COLORS.BLUE_1}
              renderHeaderType="iconCountLabel"
              headIcon={<Icons.AssetShare />}
              headSumCount={'130'}
              headTitle="ทรัพย์ที่ท่านแบ่งปัน"
              columns={columnShare()}
              data={dataShare}
              updatedDate={moment().format('DD-MM-YYYY')}
              renderFooterType="status"
              statusList={[
                { label: 'จอง', count: 0 },
                { label: 'ทำสัญญา', count: 0 },
                { label: 'ธุรกรรม', count: 0 },
                { label: 'โอนกรรมสิทธิ', count: 0 },
              ]}
            />
            {isShowCommission && (
              <div className="commission_wrap">
                <CommissionStyled themeColor={theme.COLORS.BLUE_1}>
                  <div className="c_container">
                    <div className="c_label">ค่าคอมมิชชั่นที่ท่านแบ่งปัน</div>
                    <div className="c_line_vertical" />
                    <div className="c_price">
                      {RenderCommaMoney(800000)} บาท
                    </div>
                  </div>
                </CommissionStyled>
              </div>
            )}
          </div>
          <div className="right_col">
            <Tables.TitleBodyFooter
              theme_right_update
              themeColor={theme.COLORS.YELLOW_3}
              renderHeaderType="iconCountLabel"
              headIcon={<Icons.AssetJoin />}
              headSumCount={'130'}
              headTitle="ทรัพย์ที่ท่ายเข้าร่วม"
              columns={columnShare()}
              data={dataShare}
              updatedDate={moment().format('DD-MM-YYYY')}
              renderFooterType="status"
              statusList={[
                { label: 'จอง', count: 0 },
                { label: 'ทำสัญญา', count: 0 },
                { label: 'ธุรกรรม', count: 0 },
                { label: 'โอนกรรมสิทธิ', count: 0 },
              ]}
            />
            {isShowCommission && (
              <div className="commission_wrap">
                <CommissionStyled themeColor={theme.COLORS.YELLOW_3}>
                  <div className="c_container">
                    <div className="c_label">ค่าคอมมิชชั่นที่ท่านแบ่งปัน</div>
                    <div className="c_line_vertical" />
                    <div className="c_price">
                      {RenderCommaMoney(800000)} บาท
                    </div>
                  </div>
                </CommissionStyled>
              </div>
            )}
          </div>
        </div>
      </Blocks.BlockShadow>
    </CoAgentWidgetStyled>
  );
};

const columnShare = () => [
  {
    Header: 'ชื่อทรัพย์',
    accessor: 'asset_name',
    headerStyle: { textAlign: 'left' },
  },
  {
    Header: 'ราคา',
    accessor: 'price',
    Cell: ({
      value,
      // columnProps: {
      //   rest: { someFunc },
      // },
    }) => <div style={{ textAlign: 'right' }}>{value}</div>,
    // getProps: () => ({ someFunc: () => alert("clicked")})
  },
  {
    Header: '%แชร์',
    accessor: 'comission_percent',
    headerStyle: { textAlign: 'right' },
    Cell: ({ value }) => <div style={{ textAlign: 'right' }}>{value}</div>,
  },
  {
    Header: 'ค่าคอมมิชชั่น',
    accessor: 'commission',
    headerStyle: { textAlign: 'right' },
    Cell: ({ value }) => <div style={{ textAlign: 'right' }}>{value}</div>,
  },
  {
    Header: 'สถานะ',
    accessor: 'status',
    headerStyle: { textAlign: 'right' },
    Cell: ({ value }) => (
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Displays.StatusProcess statusProcess={AssetTrackingStatus('3').no} />
      </div>
    ),
  },
];

const dataShare = [
  {
    asset_name: 'MARS HOUSE',
    price: RenderCommaMoney('15000000', true),
    comission_percent: '3 %',
    commission: '1080000',
    status: 'จอง',
  },
  {
    asset_name: 'เรือนไม้ เรือนนอน',
    price: RenderCommaMoney('2000000', true),
    comission_percent: '5 %',
    commission: '80000',
    status: 'ทำสัญญา',
  },
  {
    asset_name: 'Astra Condo',
    price: RenderCommaMoney('2200000', true),
    comission_percent: '4 %',
    commission: '1080000',
    status: 'ธุรกรรม',
  },
];

export default CoAgentWidget;
