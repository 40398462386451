import React from 'react';
import { withTranslation } from 'react-i18next';
import { WatchlistTabStyled } from './styled';
import { AssetFavJoin, AssetListFilterLayout } from 'widgets';

class WatchlistTab extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    return (
      <WatchlistTabStyled>
        <div className="section_layout">
          <AssetFavJoin />
        </div>
        <div className="section_layout">
          <AssetListFilterLayout />
        </div>
      </WatchlistTabStyled>
    );
  }
}

export default withTranslation('common')(WatchlistTab);
