import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AssetTypeListStyled } from './styled';

const AssetTypeList = ({ theme_standard, data }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AssetTypeListStyled>
      <div className={customClass}>
        <div className="asset_type_list_container">
          {data &&
            data.map((e, i) => (
              <div key={i} className="atl_row">
                <div className="atl_left_col">
                  <div className="atl_label_prefix">{e.labelPrefix}</div>
                </div>
                <div className="atl_right_col">
                  <div className="atl_label_value">{e.labelValue}</div>
                  <div className="atl_label_suffix">{e.labelSuffix}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </AssetTypeListStyled>
  );
};

AssetTypeList.propTypes = {};

export default AssetTypeList;
