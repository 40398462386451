import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { StatusProcessStyled } from './styled';
import theme from 'styles/theme.json';

const StatusProcess = ({ theme_standard, statusProcess }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <StatusProcessStyled>
      <div className={customClass}>
        <div className="sp_row">
          {[
            theme.COLORS.RED_1,
            theme.COLORS.YELLOW_2,
            theme.COLORS.ORANGE_1,
            theme.COLORS.GREEN_4,
          ].map((e, i) => (
            <div
              className="sp_item"
              style={{ background: i + 1 <= statusProcess ? e : '' }}
            />
          ))}
        </div>
      </div>
    </StatusProcessStyled>
  );
};

StatusProcess.propTypes = {};

export default StatusProcess;
