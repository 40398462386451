export default function renderIcon({ color = '#fff' }) {
  return (
    <svg
      id="help_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path id="Path_516" data-name="Path 516" d="M0,0H25V25H0Z" fill="none" />
      <path
        id="Path_517"
        data-name="Path 517"
        d="M12.5,2A10.5,10.5,0,1,0,23,12.5,10.5,10.5,0,0,0,12.5,2Zm1.05,17.85h-2.1v-2.1h2.1Zm2.173-8.137-.945.966A3.574,3.574,0,0,0,13.55,15.65h-2.1v-.525a4.226,4.226,0,0,1,1.229-2.971l1.3-1.323A2.053,2.053,0,0,0,14.6,9.35a2.1,2.1,0,1,0-4.2,0H8.3a4.2,4.2,0,0,1,8.4,0A3.341,3.341,0,0,1,15.723,11.712Z"
        transform="translate(0 0)"
        fill={color}
      />
    </svg>
  );
}
