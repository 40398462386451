export default function renderIcon({ color = '#d1d1d1' }) {
  return (
    <svg
      id="Group_388"
      data-name="Group 388"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
    >
      <path
        id="Path_503"
        data-name="Path 503"
        d="M19.85,9.3V4H16.7V6.6L12.5,3,2,12H5.15v8H10.4V14h4.2v6h5.25V12H23ZM10.4,10a2.1,2.1,0,0,1,4.2,0Z"
        transform="translate(-2 -3)"
        fill={color}
      />
    </svg>
  );
}
