import styled from 'styled-components';

export const AvatarHorizontalStyled = styled.div`
  .avartar_container {
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-radius: 29px;
    box-shadow: 0 0 5px #00000026;
    .left_col {
    }
    .right_col {
      .ah_name {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 3px;
      }
      .ah_row_2_col {
        display: flex;
        column-gap: 5px;
      }
      .post_dated {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        color: ${({ theme }) => theme.COLORS.GRAY_7};
      }
    }
  }

  .theme_standard {
  }
`;
