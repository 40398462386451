import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BtnCountAssetStyled } from './styled';

const BtnCountAsset = ({ theme_standard, no, label, bg }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BtnCountAssetStyled bg={bg}>
      <div className={customClass}>
        <div className="btn_count_asset_container">
          <div className="btn_no">{no}</div>
          <div className="btn_label">{label}</div>
        </div>
      </div>
    </BtnCountAssetStyled>
  );
};

BtnCountAsset.propTypes = {};

export default BtnCountAsset;
