import styled from 'styled-components';

export const AssetCarouselWidgetStyled = styled.div`
  .title_row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    coliumn-gap: 5px;
    margin-bottom: 20px;
    .t_left_col {
    }
    .t_right_col {
    }
  }
`;
