import React from 'react';
// import PropTypes from 'prop-types'
import { BlockShadowStyled } from './styled';

const BlockShadow = ({ children, cusSty }) => {
  return (
    <BlockShadowStyled>
      <div className="block_shadow_container" style={cusSty && { ...cusSty }}>
        {children}
      </div>
    </BlockShadowStyled>
  );
};

BlockShadow.propTypes = {};

export default BlockShadow;
