import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AssetType1Styled } from './styled';
import { Displays, Icons, Buttons } from 'components';
import theme from 'styles/theme.json';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const AssetType1 = ({
  theme_standard,
  assetName,
  assetImg,
  address,
  discountNo,
  discountBG,
  price,
  commission,
  updatedDate,
  userName,
  userImg,
  userRank,
  teameLogo,
  isFavorite,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AssetType1Styled>
      <div className={customClass}>
        <div className="asset_container">
          <div className="header_block">
            <div className="a_title">{assetName}</div>
            <div className="a_sub_title">{address}</div>
          </div>
          <div className="preview_block">
            <div className="favorite_icon">
              <Icons.Favourite isFavorite={isFavorite} />
            </div>
            <img className="asset_img" src={assetImg} alt="merge asset"/>
            <div className="dicount">
              <Displays.Discount bg={discountBG} discountNo={discountNo} />
            </div>
          </div>
          <div className="content_block">
            <div className="price">THB {RenderCommaMoney(price, true)}</div>
            <div className="user_more">
              <div className="commission_row">
                <div className="txt_prefix_com">% COMMISSION</div>
                <Icons.LineHorizontal />
                <div className="txt_suffix_com">
                  {RenderCommaMoney(commission, true)} <span>฿</span>
                </div>
              </div>
              <div className="um_row_2col">
                <div className="um_left_col">
                  <Displays.AvatarHorizontal
                    userName={userName}
                    userImg={userImg}
                    userRank={userRank}
                    teameLogo={teameLogo}
                    renderContentType="userDetail"
                  />
                </div>
                <div className="um_right_col">
                  <div className="btn_wrap">
                    <Buttons.BtnIconPrefix
                      theme_more
                      icon={
                        <Icons.AddAsset
                          color={theme.COLORS.BLUE_1}
                          width="10"
                          height="10"
                        />
                      }
                      label="More"
                    />
                  </div>
                  <div className="time_ago">{updatedDate}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AssetType1Styled>
  );
};

AssetType1.propTypes = {};

export default AssetType1;
