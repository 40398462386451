import styled from 'styled-components';

export const BannerAdvertiseWidgetStyled = styled.div`
  .advertise_img {
    width: 100%;
    height: auto;
    box-shadow: 0 3px 5px #00000026;
    border-radius: 5px;
  }
`;
