import React from 'react';
import { withTranslation } from 'react-i18next';
import { UsageHistoryTabStyled } from './styled';

class UsageHistoryTab extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    const { t } = this.props;
    return (
      <UsageHistoryTabStyled useSuspense={false}>
        {t('ok')}
        <button onClick={this.handleClickChangLang}>UsageHistoryTab</button>
      </UsageHistoryTabStyled>
    );
  }
}

export default withTranslation('common')(UsageHistoryTab);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(UsageHistoryTab);
