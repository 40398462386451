import { AssetTypeOverviewWidgetStyled } from './styled';
import { Blocks, Cards } from 'components';
import theme from 'styles/theme.json';
import moment from 'moment';

const AssetTypeOverviewWidget = () => {
  return (
    <AssetTypeOverviewWidgetStyled>
      <Blocks.BlockShadow>
        <div className="asset_type_container">
          <div className="at_left_col">
            <div className="atd_wrap">
              <Cards.AssetTypeDetail
                no={'80'}
                label="ทรัพย์ทั้งหมด"
                bg={theme.COLORS.BLUE_1}
                updatedDate={moment().format('DD-DD-YYYY')}
                dataList={dataAssetMockup}
              />
            </div>
            <div className="atd_wrap">
              <Cards.AssetTypeDetail
                no={'3'}
                label="บ้าน"
                routeAdd={true}
                bg={theme.COLORS.GREEN_1}
                updatedDate={moment().format('DD-DD-YYYY')}
                dataList={dataHomeMockup}
              />
            </div>
            <div className="atd_wrap">
              <Cards.AssetTypeDetail
                no={'3'}
                label="คอนโด"
                routeAdd={true}
                bg={theme.COLORS.BLUE_2}
                updatedDate={moment().format('DD-DD-YYYY')}
                dataList={dataCondoMockup}
              />
            </div>
            <div className="atd_wrap">
              <Cards.AssetTypeDetail
                no={'3'}
                label="ที่ดิน"
                routeAdd={true}
                bg={theme.COLORS.YELLOW_1}
                updatedDate={moment().format('DD-DD-YYYY')}
                dataList={dataLandMockup}
              />
            </div>
          </div>
          <div className="at_right_col">
            <Cards.AssetValueView
              total="38"
              houseTotal="8"
              condoTotal="20"
              landTotal="1"
            />
          </div>
        </div>
      </Blocks.BlockShadow>
    </AssetTypeOverviewWidgetStyled>
  );
};

const dataAssetMockup = [
  {
    labelPrefix: 'บ้าน',
    labelValue: '30',
    labelSuffix: 'หลัง',
  },
  {
    labelPrefix: 'คอนโด',
    labelValue: '30',
    labelSuffix: 'หลัง',
  },
  {
    labelPrefix: 'ที่ดิน',
    labelValue: '20',
    labelSuffix: 'ผืน',
  },
];

const dataHomeMockup = [
  {
    labelPrefix: 'บ้านเดี่ยว',
    labelValue: '5',
    labelSuffix: 'หลัง',
  },
  {
    labelPrefix: 'บ้านแฝด',
    labelValue: '5',
    labelSuffix: 'หลัง',
  },
  {
    labelPrefix: 'ทาวน์เฮ้าส์',
    labelValue: '2',
    labelSuffix: 'หลัง',
  },
  {
    labelPrefix: 'ทาวน์โฮม',
    labelValue: '2',
    labelSuffix: 'หลัง',
  },
  {
    labelPrefix: 'อาคารพาณิชย์',
    labelValue: '2',
    labelSuffix: 'หลัง',
  },
];

const dataCondoMockup = [
  {
    labelPrefix: 'Studio',
    labelValue: '1',
    labelSuffix: 'ห้อง',
  },
  {
    labelPrefix: 'One Bedroom',
    labelValue: '2',
    labelSuffix: 'ห้อง',
  },
];

const dataLandMockup = [
  {
    labelPrefix: 'น.ส.3ก',
    labelValue: '1',
    labelSuffix: 'ผืน',
  },
  {
    labelPrefix: 'น.ส.4',
    labelValue: '1',
    labelSuffix: 'ผืน',
  },
];

export default AssetTypeOverviewWidget;
