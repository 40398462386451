import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { Sec7CoAgentProcessStyled } from './styled';

const Sec7CoAgentProcess = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec7CoAgentProcessStyled>
      <h2 className="co_agent_title">{t('How to co-agent with us')}</h2>
      <div className="ca_row">
        {processList &&
          processList.map((e, i) => (
            <div key={i} className="ca_item">
              <img
                className="ca_img"
                src={e.imgPath}
                alt="merge coagent process"
              />
              <p className="ca_label">{`${e.no}. ${t(e.label)}`}</p>
            </div>
          ))}
      </div>
    </Sec7CoAgentProcessStyled>
  );
};

const processList = [
  {
    no: 1,
    imgPath: '/assets/images/icons/co-agent-process-1.png',
    label: 'Register',
  },
  {
    no: 2,
    imgPath: '/assets/images/icons/co-agent-process-2.png',
    label: 'Enter property details',
  },
  {
    no: 3,
    imgPath: '/assets/images/icons/co-agent-process-3.png',
    label: 'Join co-agent',
  },
  {
    no: 4,
    imgPath: '/assets/images/icons/co-agent-process-4.png',
    label: 'Share asset',
  },
  {
    no: 5,
    imgPath: '/assets/images/icons/co-agent-process-5.png',
    label: 'Matching',
  },
];

Sec7CoAgentProcess.propTypes = {};

export default Sec7CoAgentProcess;
