import React, { useEffect, useState } from 'react';
import { AssetCarouselWidgetStyled } from './styled';
import { useNavigate } from 'react-router-dom';
import { Blocks, Buttons, Cards, Icons } from 'components';
import theme from 'styles/theme.json';
import moment from 'moment';
import { ROUTE_PATH } from 'utils/constants/routePath';

const AssetCarouselWidget = ({ assetType }) => {
  let navigate = useNavigate();

  const [themeAsset, setThemeAsset] = useState();

  useEffect(() => {
    renderAssetComponent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderAssetComponent = () => {
    switch (assetType) {
      case 'house':
        setThemeAsset({
          color: theme.COLORS.GREEN_1,
          title: 'บ้าน',
          icon: <Icons.AssetHome width="20" height="20" />,
        });
        break;
      case 'condo':
        setThemeAsset({
          color: theme.COLORS.BLUE_2,
          title: 'คอนโด',
          icon: <Icons.AssetCondo width="20" height="20" />,
        });
        break;
      case 'land':
        setThemeAsset({
          color: theme.COLORS.YELLOW_1,
          title: 'ที่ดิน',
          icon: <Icons.AssetLand width="20" height="20" />,
        });
        break;
      default:
        break;
    }
  };

  const _handleClickAddAsset = () => {
    navigate(`${ROUTE_PATH.MY_ASSET}/create`);
  };

  return (
    <AssetCarouselWidgetStyled>
      <Blocks.BlockShadow>
        {themeAsset && (
          <>
            <div className="title_row">
              <div className="t_left_col">
                <Buttons.BtnIconPrefix
                  theme_standard
                  btnStyCus={{
                    padding: '10px 0px',
                    minWidth: '120px',
                    justifyContent: 'center',
                    background: themeAsset.color,
                  }}
                  txtStyCus={{ color: theme.COLORS.BLUE_1 }}
                  icon={themeAsset.icon}
                  label={themeAsset.title}
                />
              </div>
              <div className="t_right_col">
                <Buttons.BtnIconPrefix
                  theme_standard
                  icon={<Icons.AddAssetType2 />}
                  label="เพิ่มทรัพย์"
                  onClick={_handleClickAddAsset}
                />
              </div>
            </div>
          </>
        )}
        <Cards.AssetType2
          themeColor={theme.COLORS.GREEN_1}
          src={'/assets/images/mockup/asset_recomment_1.jpg'}
          assetNo={'BSTOX123'}
          program={'Co'}
          name={'SATIDA Condominium'}
          address={'ช้างคลาน + เมืองเชียงใหม่'}
          owner={'Amnagh kunka'}
          price={'12000000'}
          postDated={moment().from()}
        />
      </Blocks.BlockShadow>
    </AssetCarouselWidgetStyled>
  );
};

export default AssetCarouselWidget;
