import React from 'react';
import { FilterAssetFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs } from 'components';

const FilterAssetForm = ({ isShowControlLayout, onChangeLayout, onSubmit }) => {
  const schema = yup.object().shape({
    test: yup.string().required('New password is required'),
  });

  const {
    control,
    handleSubmit,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <FilterAssetFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field }) => (
            <Inputs.TextField
              {...field}
              // placeholder={t('test')}
              // errors={errors.phone?.message}
            />
          )}
          name="test"
          defaultValue=""
        />
        {isShowControlLayout && (
          <Inputs.SwitchLayout onChange={onChangeLayout} />
        )}
        {/* <button type="submit">submit</button> */}
      </form>
    </FilterAssetFormStyled>
  );
};

FilterAssetForm.propTypes = {};

export default FilterAssetForm;
