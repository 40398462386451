import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AssetType2Styled } from './styled';
import { Buttons, Icons } from 'components';

const AssetType2 = ({
  theme_standard,
  themeColor,
  src,
  assetNo,
  program,
  name,
  address,
  owner,
  price,
  postDated,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AssetType2Styled themeColor={themeColor}>
      <div className={customClass}>
        <div className="asset_type2_container">
          <div className="at2_preview_block">
            <img src={src} alt="merge asset"/>
            <div className="ribbin">
              <div className="rib_left_col">Asset Number : {assetNo}</div>
              <div className="rib_right_col">{program}</div>
            </div>
          </div>
          <div className="at2_content_block">
            <div className="cb_title">{name}</div>
            <div className="cb_sub_title">{address}</div>
            <div className="cb_line" />
            <div className="cb_owner">
              Owner <span>{owner}</span>
            </div>
            <div className="cb_price">THB {price}</div>
            <div className="cb_row_action">
              <div className="action_col">
                <Buttons.BtnIconPrefix
                  theme_action
                  icon={<Icons.Setting />}
                  label="แก้ไข"
                />
              </div>
              <div className="action_col">
                <Buttons.BtnIconPrefix
                  theme_action
                  icon={<Icons.Share />}
                  label="แชร์"
                />
              </div>
              <div className="action_col">
                <div className="post_dated">
                  <Icons.Clock />
                  {postDated}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AssetType2Styled>
  );
};

AssetType2.propTypes = {};

export default AssetType2;
