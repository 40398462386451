import styled from 'styled-components';

export const SwitchLayoutStyled = styled.div`
  .switch_layout_container {
    display: flex;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
    border-radius: 5px;
    width: fit-content;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .sl_item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 10px;
      cursor: pointer;
      border-radius: 5px;
      &.active {
        margin: -1px;
        background: ${({ theme }) => theme.COLORS.BLUE_1};
      }
    }
  }

  .theme_standard {
  }
`;
