import React, { useEffect, useState } from 'react';
import { SidebarWidgetStyled } from './styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setReduxIsShowSidebar } from 'store/actions';
import { Cards, Displays } from 'components';
import {
  CoinWidget,
  RankingWidget,
  PromotionSidebarWidget,
  BtnImgLink,
  BtnInformation,
} from 'widgets';
import cmsRoutes from 'navigations/cmsRoutes';

const SidebarWidget = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { sidebarRedux } = useSelector((state) => state);

  useEffect(() => {}, []);

  const [active, setActive] = useState('dashboard');
  const [activechildren, setActivechildren] = useState();

  useEffect(() => {
    if (location?.pathname) {
      const splitPathName = location.pathname.split('/');
      if (splitPathName[splitPathName.length - 1] === 'coagent') {
        setActive('dashboard');
      } else {
        setActive(splitPathName[splitPathName.length - 1]);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickMenu = (e) => {
    if (!e.children) {
      setActive(e.key);
      navigate(e.path);
    } else {
      if (activechildren === e.key) {
        setActivechildren(false);
      } else {
        setActivechildren(e.key);
      }
    }
  };

  const handleClickchildren = (e) => {
    setActive(e.key);
    navigate(e.path);
  };

  const handleClickSidebar = () => {
    dispatch(setReduxIsShowSidebar({ isShow: false }));
  };

  return (
    <SidebarWidgetStyled isShow={sidebarRedux?.isShow}>
      <div className="sidebar_show_container">
        <div className="sidebar_container">
          <div className="section_1">
            <div className="sec_col mb_10">
              <Cards.AvartarVertical
                label="คุณ พิธรา ธาราเขต"
                score="540"
                reviewPoint="72"
                src={'/assets/images/mockup/avatar.jpg'}
              />
            </div>
            <div className="sec_col mb_20">
              <RankingWidget />
            </div>
            <div className="sec_col mb_20">
              <CoinWidget />
            </div>
            <div className="sec_col">
              <PromotionSidebarWidget />
            </div>
          </div>
          <div className="line_wrap">
            <Displays.LineBetween />
          </div>
          <div className="section_2">
            {cmsRoutes &&
              cmsRoutes
                .filter((e) => !e.notShowSidebar)
                .map((g, j) =>
                  !g.children ? (
                    <div
                      className={`sidebar_item_container ${
                        active === g.key ? 'active' : ''
                      }`}
                    >
                      <div
                        key={j}
                        className="s_main_menu_item"
                        onClick={() => handleClickMenu(g)}
                      >
                        {g.icon(active === g.key ? true : false)}
                        {g.name}
                        <div className="block_active" />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`sidebar_item_container ${
                        activechildren === g.key ? 'active_main_sub' : ''
                      }`}
                    >
                      <div
                        key={j}
                        className="s_main_menu_item"
                        onClick={() => handleClickMenu(g)}
                      >
                        {g.icon()}
                        {g.name}
                      </div>
                      <div className="sub_menu_container">
                        {g.children
                          .filter((i) => !i.notShowSidebar)
                          .map((h, k) => (
                            <div
                              key={k}
                              className={`s_sub_menu_item ${
                                active === h.key ? 'active' : ''
                              }`}
                              onClick={() => handleClickchildren(h)}
                            >
                              <div className="s_dot" />
                              {h.name}
                              <div className="block_active" />
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                )}
          </div>
          <div className="line_wrap">
            <Displays.LineBetween />
          </div>
          <div className="section_3">
            <div className="s3_btn_wrap mb_5">
              <BtnImgLink />
            </div>
            <div className="s3_btn_wrap">
              <BtnInformation />
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar_backdrop" onClick={handleClickSidebar} />
    </SidebarWidgetStyled>
  );
};

export default SidebarWidget;
