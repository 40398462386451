import React, { useEffect } from 'react';
import { DashboardCMSLayoutStyled } from './styled';
import { Outlet } from 'react-router-dom';
import 'moment/locale/th.js';
// import coAgentRoutes from 'routes/coAgentRoutes';
import { NavbarWidget, SidebarWidget } from 'widgets';
// import { MemberAction, NavBar } from 'widgets';
// import { useSelector } from 'react-redux';

const DashboardCMSLayout = () => {
  // const { authenRedux } = useSelector((state) => state);
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardCMSLayoutStyled>
      <div className="m_top_layout">
        <NavbarWidget />
      </div>
      <div className="m_body_layout">
        <div className="m_left_col_layout">
          <SidebarWidget />
        </div>
        <div className="m_right_col_layout">
          <Outlet />
        </div>
      </div>
    </DashboardCMSLayoutStyled>
  );
};

export default DashboardCMSLayout;
