import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { SwitchLayoutStyled } from './styled';
import { Icons } from 'components';
import theme from 'styles/theme.json';

const SwitchLayout = ({ theme_standard, onChange }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_selectedLayout, _setSelectedLayout] = useState('item');

  const handleClickLayout = (e) => {
    _setSelectedLayout(e);
    onChange && onChange(e);
  };

  return (
    <SwitchLayoutStyled>
      <div className={customClass}>
        <div className="switch_layout_container">
          <div
            className={`sl_item ${_selectedLayout === 'item' ? 'active' : ''}`}
            onClick={() => handleClickLayout('item')}
          >
            <Icons.LayoutType1
              color={
                _selectedLayout === 'item'
                  ? theme.COLORS.WHITE_1
                  : theme.COLORS.GRAY_2
              }
            />
          </div>
          <div
            className={`sl_item ${_selectedLayout === 'table' ? 'active' : ''}`}
            onClick={() => handleClickLayout('table')}
          >
            <Icons.LayoutType2
              color={
                _selectedLayout === 'table'
                  ? theme.COLORS.WHITE_1
                  : theme.COLORS.GRAY_2
              }
            />
          </div>
        </div>
      </div>
    </SwitchLayoutStyled>
  );
};

SwitchLayout.propTypes = {};

export default SwitchLayout;
