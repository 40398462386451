import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { Sec8PartnerStyled } from './styled';

const Sec8Partner = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec8PartnerStyled>
      <div className="partner_title">{t('Sponsored by')}</div>
      <div className="partner_row">
        {logoList &&
          logoList.map((e, i) => <img key={i} src={e.imgPath} alt={e.alt} />)}
      </div>
    </Sec8PartnerStyled>
  );
};

const logoList = [
  {
    imgPath: '/assets/images/logos/map-logo.png',
    alt: 'map',
  },
  {
    imgPath: '/assets/images/logos/tedfund-logo.png',
    alt: 'ted fund',
  },
  {
    imgPath: '/assets/images/logos/nia-logo.png',
    alt: 'Nia',
  },
  {
    imgPath: '/assets/images/logos/tpq-logo.png',
    alt: 'TPQl',
  },
  {
    imgPath: '/assets/images/logos/broker-logo.png',
    alt: 'Broker',
  },
  {
    imgPath: '/assets/images/logos/startup-logo.png',
    alt: 'StartUp Thailand',
  },
  {
    imgPath: '/assets/images/logos/maejo-logo.png',
    alt: 'Maejo university',
  },
];

Sec8Partner.propTypes = {};

export default Sec8Partner;
