import { Routes, Route } from 'react-router-dom';
import cmsRoutes from './cmsRoutes';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { DashboardCMSLayout, PageLandingLayout } from 'Mainlayouts';
import LandingPageContainer from 'containers/LandingPageContainer';
import LoginContainer from 'containers/MemberSystem/LoginContainer';
import RegisterContainer from 'containers/MemberSystem/RegisterContainer';
import PrivacyPolicyContainer from 'containers/PrivacyPolicyContainer';

const Navigations = () => {
  return (
    <Routes>
      <Route
        path={ROUTE_PATH.PRIVACY_POLICY}
        element={<PrivacyPolicyContainer />}
      />
      <Route element={<PageLandingLayout />}>
        <Route index element={<LandingPageContainer />} />
        <Route path={ROUTE_PATH.LOGIN} element={<LoginContainer />} />
        <Route path={ROUTE_PATH.REGISTER} element={<RegisterContainer />} />
        <Route path="*" element={<LoginContainer />} />
      </Route>
      <Route element={<DashboardCMSLayout />}>
        {cmsRoutes &&
          cmsRoutes.map((e, i) => {
            return !e.children ? (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            ) : (
              <Route key={i}>
                {e.children &&
                  e.children.map((f, j) => (
                    <Route
                      key={j}
                      index={f.index}
                      path={f.path}
                      element={f.element}
                    />
                  ))}
              </Route>
            );
          })}
        {/* <Route path={ROUTE_PATH.HOME_PAGE} element={<HomePageContainer />} />
        <Route path={ROUTE_PATH.FARM} element={<FarmContainer />} />
        <Route path="*" element={<TradeContainer />} /> */}
      </Route>
    </Routes>
  );
};

export default Navigations;
