import styled from 'styled-components';

export const NavbarLandingWidgetStyled = styled.div`
  position: sticky;
  top: 0px;
  z-index: 99;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 74px;
  padding: 0 26px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  .nav_left_col {
    .nav_logo {
      width: 138px;
      height: 35px;
      cursor: pointer;
    }
  }
  .nav_right_col {
    display: flex;
    align-items: baseline;
    column-gap: 12px;
    .item_lb_right {
      border-bottom: 2px solid ${({ theme }) => theme.COLORS.WHITE_1};
      padding: 0 6px;
      cursor: pointer;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      &.active {
        border-bottom: 2px solid ${({ theme }) => theme.COLORS.BLUE_8};
        color: ${({ theme }) => theme.COLORS.BLUE_8};
      }
    }
  }
  .nav_hamburger_right_col {
    display: none;
    .sidebar_nav_show_container {
      -webkit-box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.16);
      width: ${({ isShow }) => (isShow ? '270px' : '0')};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      transition: width 1s;
      height: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 99;
      &::-webkit-scrollbar {
        width: 0px;
      }
      .side_nav {
        width: 270px;
        .sidebar_item {
          padding: 12px 12px;
        }
      }
    }
  }

  .navbar_backdrop {
    display: ${({ isShow }) => (isShow ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.COLORS.BLACK_1}80;
    z-index: 98;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    .nav_right_col {
      display: none;
    }
    .nav_hamburger_right_col {
      display: block;
      cursor: pointer;
    }
  }
`;
