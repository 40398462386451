import styled from 'styled-components';

export const AssetStyled = styled.div`
  .asset_container {
    height: 180px;
    // width: 170px;
    min-width: 170px;
    width: calc(100% - 10px); 
    background: ${({ bg }) => bg};
    border-radius: 5px;
    padding: 30px 5px 5px 5px;
    position: relative;
    .asset_price {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      cursor: pointer;
    }
    .a_icon {
      height: 90px;
      text-align: center;
      margin-bottom: 20px;
    }
    .update_date {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S10};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }

  .theme_standard {
  }
`;
