import styled from 'styled-components';

export const AssetFavJoinStyled = styled.div`
  position: relative;
  .title_wrap {
    margin-bottom: 15px;
  }
  .asset_fj_row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 9px;
    row-gap: 10px;
  }
  .afj_update_dated {
    position: absolute;
    right: 8px;
    bottom: 4px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S10};
    color: ${({ theme }) => theme.COLORS.GRAY_2};
  }

  @media (max-width: 710px) {
    .asset_fj_row {
      justify-content: center;
    }
  }
`;
