import styled from 'styled-components';

export const Sec1IntroductionStyled = styled.div`
  .introduction_container {
    margin: auto;
    position: relative;
    max-width: 1440px;
    min-height: calc(100vh - 74px);
    padding: 0 50px;
    display: flex;
    align-items: center;
    .intro_header_block {
      h1 {
        width: 60%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S60};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLUE_9};
      }
      h2 {
        width: 70%;
        font-size: ${({ theme }) => theme.FONT.SIZE.S38};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLUE_10};
      }
      p {
        font-size: ${({ theme }) => theme.FONT.SIZE.S28};
        color: ${({ theme }) => theme.COLORS.BLACK_3};
        span {
          color: ${({ theme }) => theme.COLORS.BLUE_10};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        }
      }
      .intro_btn_regis {
        margin-top: 100px;
        margin-left: 50px;
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: 1px solid ${({ theme }) => theme.COLORS.BLUE_8};
        width: fit-content;
        padding: 14px 18px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S36};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLUE_9};
        cursor: pointer;
      }
    }
    .intro_icon_top {
      position: absolute;
      top: 60px;
      right: 40px;
      width: 314px;
      height: auto;
      z-index: -1;
    }
    .intro_icon_bottom {
      position: absolute;
      right: 80px;
      bottom: 0px;
      width: 200px;
      height: auto;
      z-index: -1;
    }
  }
  .intro_bg_backdrop {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: auto;
    z-index: -2;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    .introduction_container {
      .intro_header_block {
        h1 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S54};
        }
        h2 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S34};
        }
        p {
          font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        }
        .intro_btn_regis {
          margin-top: 60px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S30};
        }
      }
      .intro_icon_top {
        top: 80px;
        width: 264px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .introduction_container {
      align-items: flex-start;
      padding: 30px 26px;
      min-height: calc(100vh - 74px - 60px);
      .intro_header_block {
        h1 {
          width: 100%;
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S44};
        }
        h2 {
          width: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        }
        p {
          width: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        }
        .intro_btn_regis {
          margin: auto;
          margin-top: 40px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S28};
        }
      }
      .intro_icon_top {
        display: none;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .introduction_container {
      padding: 10px 26px 0 26px;
      .intro_header_block {
        h1 {
          width: 100%;
          text-align: center;
          font-size: ${({ theme }) => theme.FONT.SIZE.S34};
        }
        h2 {
          width: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        }
        p {
          width: 100%;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        }
        .intro_btn_regis {
          margin: auto;
          margin-top: 80px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        }
      }
      .intro_icon_top {
        display: none;
      }
      .intro_icon_bottom {
        width: 120px;
        right: 20px;
      }
    }
  }
`;
