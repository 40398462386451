import styled from 'styled-components';

export const Sec8PartnerStyled = styled.div`
  margin: auto;
  max-width: 1440px;
  padding: 30px 50px 50px 50px;
  .partner_title {
    margin-bottom: 60px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S36};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    color: ${({ theme }) => theme.COLORS.BLUE_9};
  }
  .partner_row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    column-gap: 30px;
    row-gap: 60px;
    img {
      width: 120px;
      height: auto;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    padding: 30px 22px;
  }
`;
