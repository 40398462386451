import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AvartarVerticalStyled, ButtonVerifyStyled } from './styled';
import { Icons } from 'components';
import theme from 'styles/theme.json';

const AvartarVertical = ({
  theme_standard,
  label,
  score,
  reviewPoint,
  src,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderIsVerify = (isVerify) => {
    if (isVerify) {
      return <BtnRender bg={theme.COLORS.GREEN_3} txt="Verified" />;
    } else {
      return <BtnRender bg={theme.COLORS.RED_2} txt="Not Verified" />;
    }
  };

  return (
    <AvartarVerticalStyled>
      <div className={customClass}>
        <div className="figture_circle">
          <img src={src} alt="merge avatar" />
        </div>
        <div className="a_txt_name">{label}</div>
        {renderIsVerify()}
        <div className="a_scroe">
          {score} คะแนน <span>|</span> รีวิว {reviewPoint}
        </div>
      </div>
    </AvartarVerticalStyled>
  );
};

const BtnRender = ({ bg, txt }) => (
  <ButtonVerifyStyled bg={bg}>
    <Icons.Verify />
    <div className="txt_status">{txt}</div>
  </ButtonVerifyStyled>
);

AvartarVertical.propTypes = {};

export default AvartarVertical;
