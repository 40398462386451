import styled from 'styled-components';

export const StatusProcessStyled = styled.div`
  .sp_row {
    display: flex;
    column-gap: 2px;
    .sp_item {
      width: 9px;
      height: 18px;
      border-radius: 2px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
    }
  }

  .theme_standard {
  }
`;
