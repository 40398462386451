import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BtnIconPrefixStyled } from './styled';

const BtnIconPrefix = ({
  theme_standard,
  theme_more,
  theme_verify,
  theme_action,
  btnStyCus,
  txtStyCus,
  icon,
  label,
  onClick,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_more: theme_more,
    theme_verify: theme_verify,
    theme_action: theme_action,
  });
  return (
    <BtnIconPrefixStyled>
      <div className={customClass}>
        <div
          className="btn_c_container"
          style={btnStyCus && { ...btnStyCus }}
          onClick={onClick}
        >
          {icon}
          <span class="label" style={txtStyCus && { ...txtStyCus }}>
             {label}
          </span>
        </div>
      </div>
    </BtnIconPrefixStyled>
  );
};

BtnIconPrefix.propTypes = {};

export default BtnIconPrefix;
