export default function renderIcon({
  color = '#fff',
  width = '24.588',
  height = '22',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.588 22"
    >
      <path
        id="topcoat_like"
        d="M14.077,8.881C9.918,2.456,1.5,5.956,1.5,12.955,1.5,18.21,13.041,26.431,14.077,27.5c1.044-1.069,12.011-9.29,12.011-14.545,0-6.945-7.844-10.5-12.011-4.074Z"
        transform="translate(-1.5 -5.5)"
        fill={color}
      />
    </svg>
  );
}
