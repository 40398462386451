import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { Sec3MergeSolveProblemsStyled } from './styled';

const Sec3MergeSolveProblems = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec3MergeSolveProblemsStyled>
      <div className="merge_solve_row">
        <div className="ms_left_col">
          <img
            src="/assets/images/icons/merge-resolve.png"
            alt="merge resolve"
          />
        </div>
        <div className="ms_right_col">
          <h2 className="ms_title">{t('Merge help solve the problem')}</h2>
          <ul>
            {contentList &&
              contentList.map((e, i) => (
                <li key={i}>
                  <h3 className="ms_head">{t(e.lbHead)}</h3>
                  <p className="ms_sub">{t(e.lbsub)}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </Sec3MergeSolveProblemsStyled>
  );
};

const contentList = [
  {
    lbHead: 'Manage assets effectively',
    lbsub: 'Programs will help planing and manage assets for your team.',
  },
  {
    lbHead: 'Document',
    lbsub: 'Having various documents to choose from.',
  },
  {
    lbHead: 'Choose a professional broker',
    lbsub:
      'You can select a broker who has been allocated from us and you can see review before making a decision.',
  },
  {
    lbHead: 'Reduce cheating',
    lbsub:
      'There is a system to track the work process until the process is complete and there is a punishment for those who do wrong.',
  },
];

Sec3MergeSolveProblems.propTypes = {};

export default Sec3MergeSolveProblems;
