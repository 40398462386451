import Sec1Introduction from './Sec1Introduction';
import Sec2UsageStatic from './Sec2UsageStatic';
import Sec3MergeSolveProblems from './Sec3MergeSolveProblems';
import Sec4Process from './Sec4Process';
import Sec5ProgramForYou from './Sec5ProgramForYou';
import Sec6BenefitCoAgent from './Sec6BenefitCoAgent';
import Sec7CoAgentProcess from './Sec7CoAgentProcess';
import Sec8Partner from './Sec8Partner';
import Sec9RegisWithMe from './Sec9RegisWithMe';

const Export = {
  Sec1Introduction,
  Sec2UsageStatic,
  Sec3MergeSolveProblems,
  Sec4Process,
  Sec5ProgramForYou,
  Sec6BenefitCoAgent,
  Sec7CoAgentProcess,
  Sec8Partner,
  Sec9RegisWithMe,
};

export default Export;
