import styled from 'styled-components';

export const BtnImgLinkStyled = styled.div`
  height: 48px;
  border-radius: 5px;
  padding: 2px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .img_broker {
    width: 100%;
    height: 48px;
    object-fit: cover;
    border-radius: 5px;
  }
`;
