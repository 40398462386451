import styled from 'styled-components';

export const SidebarWidgetStyled = styled.div`
  width: ${({ isShow }) => (isShow ? '270px' : '0')};
  transition: width 1s;
  .sidebar_show_container {
    width: ${({ isShow }) => (isShow ? '270px' : '0')};
    background: ${({ theme }) => theme.COLORS.BLUE_1};
    transition: width 1s;
    height: calc(100% - 44px);
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 99;
    &::-webkit-scrollbar {
      width: 0px;
    }
    .sidebar_container {
      width: 270px;
      .section_1 {
        padding: 50px 10px 0px 10px;
        .sec_col {
          &.mb_10 {
            margin-bottom: 10px;
          }
          &.mb_20 {
            margin-bottom: 20px;
          }
        }
      }
      .line_wrap {
        padding: 0 10px;
        margin: 20px 0;
      }
      .section_2 {
        .sidebar_item_container {
          &.active {
            .s_main_menu_item {
              background: ${({ theme }) => theme.COLORS.BLUE_4};
              color: ${({ theme }) => theme.COLORS.BLUE_1};
              border-radius: 5px;
              .block_active {
                display: block;
              }
            }
          }
          &.active_main_sub {
            .sub_menu_container {
              max-height: 200px;
              height: auto;
              transition: max-height 0.25s ease-in;
            }
          }
          .s_main_menu_item {
            display: flex;
            align-items: center;
            column-gap: 26px;
            padding: 10px 10px 10px 28px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            color: ${({ theme }) => theme.COLORS.GRAY_4};
            cursor: pointer;
            position: relative;
            .block_active {
              display: none;
              position: absolute;
              border-radius: 5px;
              width: 7px;
              height: 100%;
              right: 0;
              background: ${({ theme }) => theme.COLORS.BLUE_5};
            }
          }
          .sub_menu_container {
            max-height: 0;
            transition: max-height 0.15s ease-out;
            overflow: hidden;
            .s_sub_menu_item {
              display: flex;
              align-items: center;
              column-gap: 12px;
              padding: 10px 10px 10px 75px;
              font-size: ${({ theme }) => theme.FONT.SIZE.S16};
              color: ${({ theme }) => theme.COLORS.GRAY_4};
              cursor: pointer;
              position: relative;
              &.active {
                background: ${({ theme }) => theme.COLORS.BLUE_4};
                color: ${({ theme }) => theme.COLORS.BLUE_1};
                border-radius: 5px;
                .block_active {
                  display: block;
                }
              }
              .s_dot {
                width: 8px;
                height: 8px;
                border-radius: 8px;
                background: ${({ theme }) => theme.COLORS.BLUE_5};
              }
              .block_active {
                display: none;
                position: absolute;
                border-radius: 5px;
                width: 7px;
                height: 100%;
                right: 0;
                background: ${({ theme }) => theme.COLORS.BLUE_5};
              }
            }
          }
        }
      }
      .section_3 {
        padding: 0 10px 20px 10px;
        .s3_btn_wrap {
          &.mb_5 {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .sidebar_backdrop {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.COLORS.BLACK_1}80;
    z-index: 98;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.DESKTOP}) {
    width: ${({ isShow }) => (isShow ? '240px' : '0')};
    .sidebar_show_container {
      width: ${({ isShow }) => (isShow ? '240px' : '0')};
      .sidebar_container {
        width: 240px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    width: 0px;
    transition: width 0.8s;
    .sidebar_show_container {
      transition: width 0.8s;
    }
    .sidebar_backdrop {
      display: ${({ isShow }) => (isShow ? 'block' : 'none')};
    }
  }
`;
