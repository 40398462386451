import styled from 'styled-components';

export const TitleButtonStyled = styled.div`
  .title_btn {
    width: fit-content;
    box-shadow: 0 3px 5px #00000026;
    border-radius: 5px;
    padding: 5px 30px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
    color: ${({ theme }) => theme.COLORS.BLUE_1};
  }

  .theme_standard {
  }
`;
