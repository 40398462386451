import styled from 'styled-components';

export const NormalStyled = styled.div`
  .btn_normal_container {
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    background: ${({ bg, theme }) => (bg ? bg : theme.COLORS.BLUE_1)};
    border-radius: 5px;
    padding: 2px 8px;
  }

  .theme_standard {
  }

  .theme_process {
    .btn_normal_container {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
      width: 115px;
      text-align: center;
      padding: 2px 22px;
      line-height: 18px;
    }
  }
`;
