import styled from 'styled-components';

export const AgentFeedItemStyled = styled.div`
  padding: 15px;
  border-radius: 5px;
  background: ${({ theme }) => theme.COLORS.BLUE_6};
  box-shadow: 0 3px 5px #00000026;
  .ag_header_row_2_col {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 10px;
    .ag_left_col {
      flex: 1;
    }
    .ag_right_col {
      height: 18px;
      cursor: pointer;
    }
  }

  .theme_standard {
  }
`;
