import styled from 'styled-components';

export const ButtonFullWidthStyled = styled.div`
  .btn_container {
    width: 100%;
    padding: 5px;
    border-radius: 6px;
    cursour: pointer;
    background: ${({ theme }) => theme.COLORS.BLUE_14};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-weight: bold;
    font-family: Prompt;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    cursor: pointer;
  }

  .theme_standard {
  }
`;
