import React from 'react';
import { withTranslation } from 'react-i18next';
import { AddAssetContainerStyled } from './styled';

class AddAssetContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    // const { t } = this.props;
    return (
      <AddAssetContainerStyled useSuspense={false}>
        <button onClick={this.handleClickChangLang}>AddAssetContainer</button>
      </AddAssetContainerStyled>
    );
  }
}

export default withTranslation('common')(AddAssetContainer);
