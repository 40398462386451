import { BtnInformationStyled } from './styled';
import { Icons } from 'components';

const BtnInformation = () => {
  return (
    <BtnInformationStyled>
      <div className="btn_i_row_2_col">
        <div className="left_col">
          <div className="icon_block">
            <Icons.Information />
          </div>
        </div>
        <div className="right_col">คู่มือการใช้งาน</div>
      </div>
    </BtnInformationStyled>
  );
};

export default BtnInformation;
