export default function renderIcon({ color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.368"
      height="15.778"
      viewBox="0 0 17.368 15.778"
    >
      <g id="clarity_talk-bubbles-solid" transform="translate(-2 -4)">
        <path
          id="Path_578"
          data-name="Path 578"
          d="M5.267,15.356V11H3.633A1.633,1.633,0,0,0,2,12.633v9.8a.534.534,0,0,0,.849.43l2.8-2.063h7.317a1.508,1.508,0,0,0,1.552-1.563v-.615H8.533a3.267,3.267,0,0,1-3.267-3.267Z"
          transform="translate(0 -3.189)"
          fill={color}
        />
        <path
          id="Path_579"
          data-name="Path 579"
          d="M21.889,4H12.633A1.633,1.633,0,0,0,11,5.633v6.533A1.633,1.633,0,0,0,12.633,13.8h7.377l2.6,2.02a.535.535,0,0,0,.855-.43V5.633A1.633,1.633,0,0,0,21.889,4Z"
          transform="translate(-4.1)"
          fill={color}
        />
      </g>
    </svg>
  );
}
