import React from 'react';
// import PropTypes from 'prop-types'
import { DiscountStyled } from './styled';

const Discount = ({ bg, discountNo }) => {
  return <DiscountStyled bg={bg}>{discountNo}</DiscountStyled>;
};

Discount.propTypes = {};

export default Discount;
