import styled from 'styled-components';

export const AgentFeedWidgetStyled = styled.div`
  .coagent_feed_header_sec {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    column-gap: 14px;
    row-gap: 14px;
    margin-bottom: 20px;
    .hastage_row_wrap {
      display: flex;
      column-gap: 5px;
    }
  }
  .parent_row {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    .post_item {
      flex: 1;
    }
  }

  @media (max-width: 920px) {
    .parent_row {
      flex-wrap: wrap;
      .post_item {
        flex: none;
        width: calc(50% - 10px);
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MOBILE}) {
    .parent_row {
      .post_item {
        width: 100%;
      }
    }
  }
`;
