import styled from 'styled-components';

export const RankBlockStyled = styled.div`
  .rank_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 35px;
    height: 40px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: ${({ theme }) => theme.COLORS.GOLD_1};
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }

  .theme_standard {
  }
`;
