import styled from 'styled-components';

export const AssetItemListLayoutStyled = styled.div`
  .asset_item_row {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 20px;

    .asset_item_wrap {
    }
  }

  @media (max-width: 1100px) {
    .asset_item_row {
      grid-template-columns: auto auto auto auto;
    }
  }
  @media (max-width: 900px) {
    .asset_item_row {
      grid-template-columns: auto auto auto;
    }
  }
  @media (max-width: 700px) {
    .asset_item_row {
      column-gap: 30px;
      justify-content: center;
      grid-template-columns: auto auto;
    }
  }
  @media (max-width: 500px) {
    .asset_item_row {
      grid-template-columns: auto;
    }
  }
`;
