import styled from 'styled-components';

export const Label2LabelStyled = styled.div`
  .lb_2_lb_container {
    display: flex;
    justify-content: space-between;
    column-gap: 6px;
    .lb_left_col {
      font-size: ${({ theme }) => theme.FONT.SIZE.S17};
    }
    .lb_right_col {
      font-size: ${({ theme }) => theme.FONT.SIZE.S17};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
  }

  .theme_standard {
  }
`;
