import { AUTHEN } from '../actions/actionTypes';

const initialState = {};

const authenReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHEN:
      state = action?.data;
      return state;
    default:
      return state;
  }
};

export default authenReducer;
