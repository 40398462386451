import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { TitleBodyFooterStyled } from './styled';
import { useTable } from 'react-table';
import { Displays } from 'components';

const TitleBodyFooter = ({
  theme_standard,
  theme_right_update,
  theme_no_column,
  columns,
  data = [],
  onClickRow,
  updatedDate,
  headIcon,
  headSumCount,
  headTitle,
  statusList,
  themeColor,
  renderHeaderType,
  renderFooterType,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_right_update: theme_right_update,
    theme_no_column: theme_no_column,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const renderHeader = () => {
    switch (renderHeaderType) {
      case 'iconCountLabel':
        return (
          <div className="head_block">
            <div className="t_left_col">
              {headIcon}
              {headSumCount}
            </div>
            <div className="t_right_col">{headTitle}</div>
          </div>
        );
      case 'title':
        return <div className="head_block_title">{headTitle}</div>;
      default:
        break;
    }
  };

  const renderFooter = () => {
    switch (renderFooterType) {
      case 'status':
        return (
          <div className="footer_block">
            <Displays.StatusListCount bg={themeColor} statusList={statusList} />
          </div>
        );
      case 'updateDated':
        return (
          <div className="footer_update_block">Update : {updatedDate}</div>
        );

      default:
        break;
    }
  };

  return (
    <TitleBodyFooterStyled themeColor={themeColor}>
      <div className={customClass}>
        <div className="table_container">
          {renderHeader()}
          <div className="content_block">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="head_row"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={column.headerStyle && { ...column.headerStyle }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      className="row_body"
                      {...row.getRowProps()}
                      onClick={() => {
                        onClickRow && onClickRow(i);
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {renderFooter()}
        </div>
        {!renderFooter && updatedDate && (
          <div className="update_dated">Update : {updatedDate}</div>
        )}
      </div>
    </TitleBodyFooterStyled>
  );
};

TitleBodyFooter.propTypes = {};

export default TitleBodyFooter;
