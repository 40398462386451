import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { RankBlockStyled } from './styled';

const RankBlock = ({ theme_standard, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <RankBlockStyled>
      <div className={customClass}>
        <div className="rank_container">
          <div className="label">{label}</div>
        </div>
      </div>
    </RankBlockStyled>
  );
};

RankBlock.propTypes = {};

export default RankBlock;
