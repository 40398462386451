import styled from 'styled-components';

export const AssetTypeListStyled = styled.div`
  .asset_type_list_container {
    // width: 170px;
    width: calc(100% - 10px);
    height: 140px;
    padding: 5px;
    border-radius: 5px;
    background: ${({ theme }) => theme.COLORS.WHITE_1}D9;
    .atl_row {
      display: flex;
      column-gap: 5px;
      margin-bottom: 5px;
      .atl_left_col {
        flex: 1;
        .atl_label_prefix {
          font-size: ${({ theme }) => theme.FONT.SIZE.S11};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLUE_1};
        }
      }
      .atl_right_col {
        flex: 1;
        display: flex;
        justify-content: space-between;
        .atl_label_value {
          font-size: ${({ theme }) => theme.FONT.SIZE.S11};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.GRAY_10};
        }
        .atl_label_suffix {
          font-size: ${({ theme }) => theme.FONT.SIZE.S11};
          font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLUE_1};
        }
      }
    }
  }

  .theme_standard {
  }
`;
