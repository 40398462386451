export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.657"
      height="12"
      viewBox="0 0 11.657 12"
    >
      <path
        id="mdi_cogs"
        d="M10.075,11.4a1.4,1.4,0,1,0-1.4-1.4,1.4,1.4,0,0,0,1.4,1.4Zm2.971-1.011.846.663a.2.2,0,0,1,.046.257l-.8,1.383a.2.2,0,0,1-.246.086l-.994-.4a3.065,3.065,0,0,1-.674.394l-.154,1.057a.2.2,0,0,1-.194.171h-1.6a.2.2,0,0,1-.2-.171l-.149-1.057a2.938,2.938,0,0,1-.674-.394l-1,.4a.2.2,0,0,1-.24-.086l-.8-1.383a.2.2,0,0,1,.046-.257l.846-.663L7.075,10,7.1,9.606l-.846-.657a.2.2,0,0,1-.046-.257l.8-1.383a.192.192,0,0,1,.24-.091l1,.406a2.746,2.746,0,0,1,.674-.394l.149-1.063A.2.2,0,0,1,9.275,6h1.6a.2.2,0,0,1,.194.166l.154,1.063a2.855,2.855,0,0,1,.674.394l.994-.406a.2.2,0,0,1,.246.091l.8,1.383a.2.2,0,0,1-.046.257l-.846.657.029.394ZM4.812,5.469a.9.9,0,1,0-.9-.9A.9.9,0,0,0,4.812,5.469Zm1.909-.646.554.423a.136.136,0,0,1,.017.166L6.778,6.3a.122.122,0,0,1-.154.057L5.984,6.1l-.423.251-.109.68a.128.128,0,0,1-.126.109H4.3a.13.13,0,0,1-.131-.109l-.091-.68L3.641,6.1l-.651.257A.119.119,0,0,1,2.841,6.3l-.514-.891a.131.131,0,0,1,.029-.166L2.9,4.823l-.017-.251L2.9,4.32,2.355,3.9a.131.131,0,0,1-.029-.166l.514-.891a.119.119,0,0,1,.149-.057l.646.257.44-.251.091-.68A.13.13,0,0,1,4.3,2H5.326a.129.129,0,0,1,.126.109l.109.68.423.251.64-.257a.122.122,0,0,1,.154.057l.514.891a.136.136,0,0,1-.017.166l-.554.423.017.251-.017.251Z"
        transform="translate(-2.308 -2)"
        fill={color}
      />
    </svg>
  );
}
