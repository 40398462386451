import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { NormalStyled } from './styled';

const Normal = ({ theme_standard, theme_process, label, styCus, bg }) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_process: theme_process,
  });
  return (
    <NormalStyled bg={bg}>
      <div className={customClass}>
        <button
          className="btn_normal_container"
          style={styCus && { ...styCus }}
        >
          {label}
        </button>
      </div>
    </NormalStyled>
  );
};

Normal.propTypes = {};

export default Normal;
