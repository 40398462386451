import styled from 'styled-components';

export const DashboardCMSLayoutStyled = styled.div`
  height: 100%;
  .m_top_layout {
    position: sticky;
    height: 44px;
  }
  .m_body_layout {
    display: grid;
    grid-template-columns: min-content auto;
    width: 100%;
    max-width: 1920px;
    margin: auto;
    .m_left_col_layout {
    }
    .m_right_col_layout {
      overflow: auto;
      padding: 20px 25px;
      background: ${({ theme }) => theme.COLORS.BLUE_6};
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.DESKTOP}) {
    .m_body_layout {
      .m_right_col_layout {
        padding: 20px 17px;
      }
    }
  }
`;
