import styled from 'styled-components';

export const AvatarCompanyStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  overflow: hidden;
  background: ${({ theme }) => theme.COLORS.BLUE_7}B3;
  img {
    width: 12px;
    height: 12px;
  }
`;
