import { AgentFeedWidgetStyled } from './styled';
import { Blocks, Typhographys, Cards, Displays } from 'components';
import moment from 'moment';

const AgentFeedWidget = () => {
  return (
    <AgentFeedWidgetStyled>
      <Blocks.BlockShadow>
        <div className="coagent_feed_header_sec">
          <Typhographys.TitleButton title="ทรัพย์เสนอ Co-Agent สำหรับท่าน" />
          <div className="hastage_row_wrap">
            <Displays.AgentFeedHashtag hashtag="ซื้อ" />
            <Displays.AgentFeedHashtag hashtag="เช่า" />
            <Displays.AgentFeedHashtag hashtag="เชียงใหม่" />
          </div>
        </div>
        <div className="parent_row">
          {[0, 0, 0].map((e, i) => (
            <div key={i} className="post_item">
              <Cards.AgentFeedItem
                userName="Thanapon aromdee"
                userRank="gold"
                userImg="/assets/images/mockup/avatar-mini.jpg"
                postDated={moment().fromNow()}
                msg={
                  'หาเช่าหรือซื้อคอนโด ย่านเมืองเชียงใหม่ ในตัวเมืองเชียงใหม่.งบเช่าราคา 20,000 บาท งบซื้อ 700,000 บาท'
                }
              />
            </div>
          ))}
        </div>
      </Blocks.BlockShadow>
    </AgentFeedWidgetStyled>
  );
};

export default AgentFeedWidget;
