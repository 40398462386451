import { AssetFavJoinStyled } from './styled';
import { Blocks, Typhographys, Buttons } from 'components';
import theme from 'styles/theme.json';

const AssetFavJoin = () => {
  return (
    <AssetFavJoinStyled>
      <Blocks.BlockShadow>
        <div className="title_wrap">
          <Typhographys.TitleButton title="ทรัพย์ที่ท่านสนใจเข้าร่วม" />
        </div>
        <div className="asset_fj_row">
          <div className="btn_wrap">
            <Buttons.BtnCountAsset
              bg={theme.COLORS.BLUE_1}
              no={'80'}
              label={'ทรัพย์ Watchlist'}
            />
          </div>
          <div className="btn_wrap">
            <Buttons.BtnCountAsset
              bg={theme.COLORS.GREEN_1}
              no={'3'}
              label={'บ้าน'}
            />
          </div>
          <div className="btn_wrap">
            <Buttons.BtnCountAsset
              bg={theme.COLORS.BLUE_2}
              no={'4'}
              label={'คอนโด'}
            />
          </div>
          <div className="btn_wrap">
            <Buttons.BtnCountAsset
              bg={theme.COLORS.YELLOW_1}
              no={'1'}
              label={'ที่ดิน'}
            />
          </div>
        </div>
        <div className="afj_update_dated">Update : 14-08-2564</div>
      </Blocks.BlockShadow>
    </AssetFavJoinStyled>
  );
};

export default AssetFavJoin;
