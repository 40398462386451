import { BannerAdvertiseWidgetStyled } from './styled';

const BannerAdvertiseWidget = () => {
  return (
    <BannerAdvertiseWidgetStyled>
      <img
        className="advertise_img"
        src={'/assets/images/mockup/advertise.jpg'}
        alt="merge banner"
      />
    </BannerAdvertiseWidgetStyled>
  );
};

export default BannerAdvertiseWidget;
