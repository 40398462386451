import styled from 'styled-components';

export const Sec4ProcessStyled = styled.div`
  position: relative;
  min-height: calc(100vh - 134px);
  padding: 30px 50px;
  display: flex;
  align-items: center;
  background: rgb(102, 190, 255);
  background: linear-gradient(
    180deg,
    rgba(102, 190, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  .procee_container {
    flex: 1;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    .p_title {
      margin: 5px 0 80px 0;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S50};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
    .process_row {
      display: flex;
      justify-content: space-around;
      column-gap: 30px;
      row-gap: 30px;
      .process_item {
        flex: 1;
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 26px;
        min-width: 280px;
        min-height: 430px;
        padding: 20px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        text-align: center;
        img {
          width: 236px;
          height: 226px;
        }
        h3 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S24};
          font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        }
      }
    }
  }

  @media (max-width: 1124px) {
    .procee_container {
      .p_title {
        margin: 5px 0 40px 0;
      }
      .process_row {
        flex-direction: column;
        .process_item {
          min-height: unset;
          h3 {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    padding: 40px 22px 30px 22px;
    align-items: flex-start;
    .procee_container {
      flex: 1;
      .p_title {
        margin-bottom: 50px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S34};
      }
      .process_row {
        row-gap: 20px;
        .process_item {
          img {
            width: 136px;
            height: 126px;
          }
          h3 {
            font-size: ${({ theme }) => theme.FONT.SIZE.S20};
          }
        }
      }
    }
  }
`;
