import Verify from './Verify';
import CartCircle from './CartCircle';
import Credit from './Credit';
import MergeCoin from './MergeCoin';
import Ranking from './Ranking';
import CoAgent from './CoAgent';
import PoolAsset from './PoolAsset';
import MyCustomer from './MyCustomer';
import Home from './Home';
import Information from './Information';
import AssetAll from './AssetAll';
import AssetCondo from './AssetCondo';
import AssetHome from './AssetHome';
import AssetLand from './AssetLand';
import AddAsset from './AddAsset';
import Favourite from './Favourite';
import LineHorizontal from './LineHorizontal';
import DotMore from './DotMore';
import Contact from './Contact';
import Share from './Share';
import ShareType2 from './ShareType2';
import AssetJoin from './AssetJoin';
import AssetShare from './AssetShare';
import AddAssetType2 from './AddAssetType2';
import Setting from './Setting';
import Clock from './Clock';
import LayoutType1 from './LayoutType1';
import LayoutType2 from './LayoutType2';
import Chat from './Chat';
import DocumentType1 from './DocumentType1';
import DocumentType2 from './DocumentType2';
import ShowHideSidebar from './ShowHideSidebar';
import Lang from './Lang';
import ArrowBottomDropdown from './ArrowBottomDropdown';
import Hamburger from './Hamburger';
import Logo from './Logo';

const Export = {
  Verify,
  CartCircle,
  Credit,
  MergeCoin,
  Ranking,
  CoAgent,
  PoolAsset,
  MyCustomer,
  Home,
  Information,
  AssetAll,
  AssetCondo,
  AssetHome,
  AssetLand,
  AddAsset,
  Favourite,
  LineHorizontal,
  DotMore,
  Contact,
  Share,
  ShareType2,
  AssetJoin,
  AssetShare,
  AddAssetType2,
  Setting,
  Clock,
  LayoutType1,
  LayoutType2,
  Chat,
  DocumentType1,
  DocumentType2,
  ShowHideSidebar,
  Lang,
  ArrowBottomDropdown,
  Hamburger,
  Logo,
};

export default Export;
