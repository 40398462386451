import { FilterAssetWidgetStyled } from './styled';
import { Forms } from 'components';

const FilterAssetWidget = () => {

  const onSubmit = (values) => {};

  return (
    <FilterAssetWidgetStyled>
      <Forms.FilterAssetForm onSubmit={onSubmit} />
    </FilterAssetWidgetStyled>
  );
};

export default FilterAssetWidget;
