export default function renderIcon({ color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.886"
      height="17"
      viewBox="0 0 14.886 17"
    >
      <g id="bx_bx-message-alt-add" transform="translate(-3 -2)">
        <path
          id="Path_574"
          data-name="Path 574"
          d="M3,14.259a1.706,1.706,0,0,0,1.654,1.751H7.62L10.443,19l2.823-2.989h2.966a1.706,1.706,0,0,0,1.654-1.751V3.751A1.706,1.706,0,0,0,16.232,2H4.654A1.706,1.706,0,0,0,3,3.751ZM4.654,3.751H16.232V14.259h-3.65l-2.139,2.264L8.3,14.259H4.654Z"
          fill={color}
        />
        <path
          id="Path_575"
          data-name="Path 575"
          d="M11,14h2V11h3V9H13V6H11V9H8v2h3Z"
          transform="translate(-1.557 -0.846)"
          fill={color}
        />
      </g>
    </svg>
  );
}
