import styled from 'styled-components';

export const TextFieldStyled = styled.div`
  .label {
    font-size: ${({ theme }) => theme.FONT.SIZE.S13};
    font-weight: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_6};
    margin-bottom: 10px;
  }
  input {
    width: calc(100% - 24px);
    height: 40px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_14};
    font-family: Prompt;
    font-size: ${({ theme }) => theme.FONT.SIZE.S13};
    font-weight: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_6};
    border-radius: 6px;
    padding: 0 10px;
  }
  .errors {
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S10};
  }

  .theme_standard {
  }
`;
