import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ButtonFullWidthStyled } from './styled';

const ButtonFullWidth = ({
  theme_standard,
  theme_bg_white_border_gray,
  theme_border_blue,
  type = 'button',
  label,
  styCus,
  onClick,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_bg_white_border_gray: theme_bg_white_border_gray,
    theme_border_blue: theme_border_blue,
  });
  return (
    <ButtonFullWidthStyled>
      <div className={customClass}>
        <button
          style={styCus && { ...styCus }}
          type={type}
          className="btn_container"
          onClick={onClick}
        >
          {label}
        </button>
      </div>
    </ButtonFullWidthStyled>
  );
};

ButtonFullWidth.propTypes = {};

export default ButtonFullWidth;
