import styled from 'styled-components';

export const Sec9RegisWithMeStyled = styled.div`
  position: relative;
  min-height: calc(100vh - 74px);
  padding: 0 50px;
  background: rgb(88, 179, 247);
  background: linear-gradient(
    145deg,
    rgba(88, 179, 247, 1) 0%,
    rgba(45, 156, 219, 1) 100%
  );
  display: flex;
  align-items: center;
  .joint_with_me_container_wrap {
    margin: auto;
    width: 100%;
    max-width: 1440px;
    .joint_with_me_container {
      flex: 1;
      h2 {
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S60};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      p {
        margin-bottom: 60px;
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S50};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .btn_regis {
        margin: auto;
        border-radius: 50px;
        width: fit-content;
        padding: 6px 26px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S36};
        cursor: pointer;
      }
    }
  }

  .rwm_backdrop {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -1;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    padding: 30px 22px;
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .joint_with_me_container {
      h2 {
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S50};
      }
      p {
        font-size: ${({ theme }) => theme.FONT.SIZE.S40};
      }
      .btn_regis {
        font-size: ${({ theme }) => theme.FONT.SIZE.S26};
      }
    }
  }
`;
