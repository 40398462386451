import { Suspense } from 'react';
import 'i18n';
import { store, persistor } from 'store';
import Navigations from 'navigations';
import theme from 'styles/theme.json';
import { Provider } from 'react-redux';
import { GlobalStyle } from 'styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
// import { Notification } from 'components';
import { PersistGate } from 'redux-persist/integration/react';


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            {/* <Notification /> */}
            <Suspense fallback={''}>
              <Navigations />
            </Suspense>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
