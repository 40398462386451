export default function renderIcon({ color = '#808080' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.4"
      height="20"
      viewBox="0 0 22.4 20"
    >
      <path
        id="oi_list-rich"
        d="M0,0V8.571H8.4V0ZM11.2,0V2.857H22.4V0Zm0,5.714V8.571h8.4V5.714ZM0,11.429V20H8.4V11.429Zm11.2,0v2.857H22.4V11.429Zm0,5.714V20h8.4V17.143Z"
        fill={color}
      />
    </svg>
  );
}
