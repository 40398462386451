export default function renderIcon({
  color = '#fff',
  width = '15',
  height = '15',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15 15"
    >
      <path
        id="ci_circle-check-outline"
        d="M9.5,17A7.5,7.5,0,1,1,17,9.5,7.5,7.5,0,0,1,9.5,17Zm-.012-1.5H9.5a6,6,0,1,0-.012,0ZM8,13.25l-3-3L6.058,9.193,8,11.127l4.942-4.942L14,7.25Z"
        transform="translate(-2 -2)"
        fill={color}
      />
    </svg>
  );
}
