import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LineBetweenStyled } from './styled';

const LineBetween = ({ theme_standard, styCus }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <LineBetweenStyled>
      <div className={customClass}>
        <div className="line" style={styCus && { ...styCus }} />
      </div>
    </LineBetweenStyled>
  );
};

LineBetween.propTypes = {};

export default LineBetween;
