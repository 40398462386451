import styled from 'styled-components';

export const DiscountStyled = styled.div`
  text-align: center;
  width: fit-content;
  min-width: 42px;
  background: ${({ bg }) => bg};
  border-radius: 5px;
  font-size: ${({ theme }) => theme.FONT.SIZE.S20};
  font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
  color: ${({ theme }) => theme.COLORS.WHITE_1};
  padding: 0 8px;
`;
