import React from 'react';
import { withTranslation } from 'react-i18next';
import { ShareAssetsContainerStyled } from './styled';
import { Tabs } from 'components';
import { AssetAllTab } from './Tabs';

class ShareAssetsContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    return (
      <ShareAssetsContainerStyled useSuspense={false}>
        <Tabs.TrackingCount tabs={tabs} initialValues={'assetAll'} />
      </ShareAssetsContainerStyled>
    );
  }
}
const tabs = {
  assetAll: {
    label: 'ทรัพย์ทั้งหมด',
    component: <AssetAllTab />,
  },
};

export default withTranslation('common')(ShareAssetsContainer);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ShareAssetsContainer);
