import styled from 'styled-components';

export const BlockShadowStyled = styled.div`
  .block_shadow_container {
    box-shadow: 0 3px 5px #00000026;
    padding: 20px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 5px;
  }
`;
