import styled from 'styled-components';

export const BtnCountAssetStyled = styled.div`
  .btn_count_asset_container {
    min-width: 190px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 5px 10px 5px 5px;
    border-radius: 5px;
    background: ${({ bg }) => bg};
    .btn_no {
      min-width: 48px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 5px;
      background: ${({ theme }) => theme.COLORS.WHITE_1}40;
      font-size: ${({ theme }) => theme.FONT.SIZE.S30};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
    .btn_label {
      flex: 1;
      text-align: center;
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }
  .theme_standard {
  }
`;
