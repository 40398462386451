import styled from 'styled-components';

export const RegisterFormStyled = styled.div`
  .title {
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-weight: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_4};
    margin-bottom: 32px;
  }
  .input_wrap_layout {
    margin-bottom: 14px;
    &.mb_28 {
      margin-bottom: 28px;
    }
  }
  .btn_submit_layout {
  }
`;
