import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LabelRectangularStatusStyled } from './styled';

const LabelRectangularStatus = ({ theme_standard, bgStatus, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <LabelRectangularStatusStyled bgStatus={bgStatus}>
      <div className={customClass}>
        <div className="label_rec_container">
          <div className="rec_box" />
          {label}
        </div>
      </div>
    </LabelRectangularStatusStyled>
  );
};

LabelRectangularStatus.propTypes = {};

export default LabelRectangularStatus;
