import { AssetTableListLayoutStyled } from './styled';
import { Tables, Buttons } from 'components';
import theme from 'styles/theme.json';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

const AssetTableListLayout = () => {
  return (
    <AssetTableListLayoutStyled>
      <div className="atl_wrap">
        <Tables.TitleBodyFooter
          isShowNo
          themeColor={theme.COLORS.BLUE_1}
          columns={columnShare()}
          data={dataShare}
        />
      </div>
    </AssetTableListLayoutStyled>
  );
};

const columnShare = () => [
  {
    Header: '',
    accessor: 'no',
    Cell: ({ row }) => {
      console.log('value', row);
      return row.index + 1 + '.';
    },
  },
  {
    Header: 'รูป',
    accessor: 'asset_img',
    Cell: ({ value }) => (
      <img
        src={value}
        style={{ width: 110, height: 60, objectFit: 'cover', borderRadius: 5 }}
        alt="merge asset"
      />
    ),
  },
  {
    Header: 'ผู้แชร์',
    accessor: 'agent',
    Cell: ({ value }) => (
      <div style={{ color: theme.COLORS.BLUE_1 }}>{value}</div>
    ),
  },
  {
    Header: 'ราคา',
    accessor: 'price',
    headerStyle: { textAlign: 'center' },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'ค่าคอมมิชชั่น',
    accessor: 'commission',
    headerStyle: { textAlign: 'center' },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'ตำแหน่ง',
    accessor: 'address',
    headerStyle: { textAlign: 'center' },
  },
  {
    Header: '',
    accessor: 'action',
    headerStyle: { textAlign: 'right' },
    Cell: ({ value }) => (
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Buttons.Normal label="รายละเอียด" />
      </div>
    ),
  },
];

const dataShare = [
  {
    asset_img: '/assets/images/mockup/asset_recomment_1.jpg',
    agent: 'Kikproperty',
    price: RenderCommaMoney('15000000', true),
    comission_percent: '3 %',
    commission: RenderCommaMoney('1080000'),
    address: 'หางดง + เชียงใหม่',
  },
  {
    asset_img: '/assets/images/mockup/asset_recomment_1.jpg',
    agent: 'Bas23savage',
    price: RenderCommaMoney('2000000', true),
    comission_percent: '5 %',
    commission: RenderCommaMoney('80000'),
    address: 'หางดง + เชียงใหม่',
  },
  {
    asset_img: '/assets/images/mockup/asset_recomment_1.jpg',
    agent: 'Terapong52',
    price: RenderCommaMoney('2200000', true),
    comission_percent: '4 %',
    commission: RenderCommaMoney('1080000'),
    address: 'หางดง + เชียงใหม่',
  },
];
export default AssetTableListLayout;
