import styled from 'styled-components';

export const AgentFeedHashtagStyled = styled.div`
  .hasgtag_container {
    display: flex;
    align-items: center;
    column-gap: 7px;
    padding: 4px 5px;
    min-width: 35px;
    border-radius: 5px;
    background: ${({ theme }) => theme.COLORS.GRAY_9};
    font-size: ${({ theme }) => theme.FONT.SIZE.S10};
    font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
    color: ${({ theme }) => theme.COLORS.GRAY_3};
    .sharp {
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    }
  }

  .theme_standard {
  }
`;
