import styled from 'styled-components';

export const TitleBodyFooterStyled = styled.div`
  .table_container {
    border: 2px solid ${({ themeColor }) => themeColor};
    border-radius: 5px;
    .head_block {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      column-gap: 5px;
      padding: 20px 15px 0px 15px;
      margin-bottom: 5px;
      .t_left_col {
        display: flex;
        align-items: baseline;
        column-gap: 20px;
        padding-left: 30px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S40};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
      }
      .t_right_col {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
      }
    }
    .head_block_title {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.BLUE_1};
      margin-bottom: 5px;
      padding-top: 15px;
    }
    .content_block {
      padding: 5px 15px 15px 15px;
      height: 370px;
      table {
        width: 100%;
        border-spacing: 0;
        .head_row {
          th {
            border-bottom: 2px solid ${({ themeColor }) => themeColor};
            font-size: ${({ theme }) => theme.FONT.SIZE.S15};
            font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
          }
        }
        tbody {
          tr {
            td {
              padding-bottom: 10px;
            }
            &:first-child {
              td {
                padding-top: 12px;
              }
            }
            &:last-child {
              td {
                padding-bottom: 0px;
              }
            }
          }
        }
      }
    }

    .footer_update_block {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 18px;
      background: ${({ themeColor }) => themeColor};
      border-left-bottom-radius: 5px;
      border-right-bottom-radius: 5px;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }
  .update_dated {
    margin-top: 5px;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
  }

  .theme_standard {
  }

  .theme_right_update {
    .update_dated {
      text-align: right;
    }
  }

  .theme_no_column {
    .content_block {
      height: 465px;
    }
  }
`;
