import styled from 'styled-components';

export const AssetType2Styled = styled.div`
  .asset_type2_container {
    width: 194px;
    border-radius: 5px;
    box-shadow: 0 0 5px #00000026;
    cursor: pointer;
    .at2_preview_block {
      position: relative;
      height: 140px;
      overflow: hidden;
      border-radius: 5px;
      .ribbin {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-radius: 5px;
        display: flex;
        background: ${({ theme }) => theme.COLORS.BLACK_2}B3;
        .rib_left_col {
          flex: 1;
          padding: 5px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S10};
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
        .rib_right_col {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          border-radius: 5px;
          background: ${({ themeColor }) => themeColor};
          font-size: ${({ theme }) => theme.FONT.SIZE.S10};
          font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
          color: ${({ theme }) => theme.COLORS.BLUE_1};
        }
      }
      img {
        width: 100%;
        height: 140px;
        object-fit: cover;
        transition: 0.5s all ease-in-out;
        &:hover {
          transform: scale(1.4);
        }
      }
    }
    .at2_content_block {
      padding: 5px 5px 10px 5px;
      .cb_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 5px;
      }
      .cb_sub_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        font-family: ${({ theme }) => theme.FONT.STYLE.LIGHT};
        color: ${({ theme }) => theme.COLORS.GRAY_3};
      }
      .cb_line {
        margin: 7px 0;
        width: 100%;
        height: 1.5px;
        background: ${({ theme }) => theme.COLORS.GREEN_1};
      }
      .cb_owner {
        font-size: ${({ theme }) => theme.FONT.SIZE.S11};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
        margin-bottom: 10px;
        span {
          color: ${({ theme }) => theme.COLORS.GRAY_11};
        }
      }
      .cb_price {
        padding: 5px;
        border-radius: 5px;
        background: ${({ theme }) => theme.COLORS.BLUE_6};
        font-size: ${({ theme }) => theme.FONT.SIZE.S15};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        text-align: center;
        margin-bottom: 10px;
      }
      .cb_row_action {
        display: flex;
        align-items: center;
        column-gap: 5px;
        .action_col {
          flex: 1;
          .post_dated {
            display: flex;
            align-items: center;
            column-gap: 4px;
            font-size: ${({ theme }) => theme.FONT.SIZE.S7};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
            color: ${({ theme }) => theme.COLORS.GRAY_6};
          }
        }
      }
    }
  }

  .theme_standard {
  }
`;
