import styled from 'styled-components';

export const MergeCoinStyled = styled.div`
  .mc_row_2_col {
    display: flex;
    align-items: center;
    column-gap: 15px;
    .mc_label_block {
      .mc_txt_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S15};
        font-family: ${({ theme }) => theme.FONT.STYLE.SEMIBOLD};
      }
      .mc_txt_unit {
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: ${({ theme }) => theme.COLORS.BLUE_1};
      }
    }
  }

  .theme_standard {
  }
`;
