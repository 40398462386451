import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { Sec9RegisWithMeStyled } from './styled';

const Sec9RegisWithMe = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec9RegisWithMeStyled>
      <div className="joint_with_me_container_wrap">
        <div className="joint_with_me_container">
          <h2>{t('START APPLYING WITH US NOW')}</h2>
          <p>{t('Increase efficiency sales to you')}</p>
          <div className="btn_regis">{t('Register For Free')}</div>
        </div>
        <img
          className="rwm_backdrop"
          src="/assets/images/bg/join-with-me-bg.png"
          alt="regis backdrop"
        />
      </div>
    </Sec9RegisWithMeStyled>
  );
};

Sec9RegisWithMe.propTypes = {};

export default Sec9RegisWithMe;
