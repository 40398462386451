import { IS_SHOW_SIDEBAR } from '../actions/actionTypes';

const initialState = {
  isShow: true,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_SHOW_SIDEBAR:
      state = action?.data;
      return state;
    default:
      return state;
  }
};

export default sidebarReducer;
