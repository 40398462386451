import React from 'react';
import { RegisterFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const RegisterForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
    telephone: yup.string().required('Telephone is required'),
    password: yup.string().required('Password is required'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    isAccept: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const handleCLickCreateAccount = () => {
  //   router.push('/register');
  // };

  return (
    <RegisterFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="title">สร้างบัญชีของคุณ</div>
        <div className="input_wrap_layout">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'อีเมลล์'}
                errors={errors.email?.message}
              />
            )}
            name="email"
            defaultValue=""
          />
        </div>
        <div className="input_wrap_layout">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'เบอร์โทรศัพท์'}
                errors={errors.telephone?.message}
              />
            )}
            name="telephone"
            defaultValue=""
          />
        </div>
        <div className="input_wrap_layout">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'รหัสผ่าน'}
                errors={errors.password?.message}
              />
            )}
            name="password"
            defaultValue=""
          />
        </div>
        <div className="input_wrap_layout">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={'ยืนยันรหัสผ่าน'}
                errors={errors.confirm_password?.message}
              />
            )}
            name="confirm_password"
            defaultValue=""
          />
        </div>
        <div className="input_wrap_layout mb_28">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckbox
                {...field}
                label={'ฉันยอมรับนโยบาย'}
                errors={errors.isAccept?.message}
              />
            )}
            name="isAccept"
            defaultValue={false}
          />
        </div>
        <div className="btn_submit_layout">
          <Buttons.ButtonFullWidth type="submit" label="ลงทะเบียน" />
        </div>
      </form>
    </RegisterFormStyled>
  );
};

RegisterForm.propTypes = {};

export default RegisterForm;
