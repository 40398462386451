export default function renderIcon({ color = '#4E5B7E' }) {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 7L10.2631 0.25H0.73686L5.5 7Z" fill={color} />
    </svg>
  );
}
