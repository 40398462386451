import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { Sec5ProgramForYouStyled } from './styled';

const Sec5ProgramForYou = () => {
  const { t } = useTranslation('landingPage');
  return (
    <Sec5ProgramForYouStyled>
      <div className="procee_container">
        <h2 className="p_title">{t('Platform that support you Merge')}</h2>
        <div className="process_row">
          {contentList &&
            contentList.map((e, i) => (
              <div key={i} className="process_item">
                <img src={e.imgPath} alt="merge process" />
                <h3>{t(e.lbHead)}</h3>
                <p>{t(e.lbSub)}</p>
              </div>
            ))}
        </div>
      </div>
    </Sec5ProgramForYouStyled>
  );
};

const contentList = [
  {
    lbHead: 'Broker company',
    lbSub:
      'Can view all assets of the company and manage assets independently and also recruiting brokers with good grades and records',
    imgPath: '/assets/images/icons/benefit-1.png',
  },
  {
    lbHead: 'Broker',
    lbSub: 'No need to travel to other provinces, can use co-agent in platform',
    imgPath: '/assets/images/icons/benefit-2.png',
  },
  {
    lbHead: 'Sales team',
    lbSub:
      'Help to find asset , find agents that need to cooperate through platform.',
    imgPath: '/assets/images/icons/benefit-3.png',
  },
];

Sec5ProgramForYou.propTypes = {};

export default Sec5ProgramForYou;
