import React from 'react';
import { withTranslation } from 'react-i18next';
import { AssetApprovedTabStyled } from './styled';

class AssetApprovedTab extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  render() {
    const { t } = this.props;
    return (
      <AssetApprovedTabStyled useSuspense={false}>
        {t('ok')}
        <button onClick={this.handleClickChangLang}>AssetApprovedTab</button>
      </AssetApprovedTabStyled>
    );
  }
}

export default withTranslation('common')(AssetApprovedTab);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(AssetApprovedTab);
