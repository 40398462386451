import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AssetLabelCountStyled } from './styled';

const AssetLabelCount = ({ theme_standard, no, label, icon }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AssetLabelCountStyled>
      <div className={customClass}>
        <div className="asset_count_container">
          <div className="ac_left_col">{no}</div>
          <div className="ac_right_col">
            <div className="ac_txt_type">{label}</div>
            {icon && icon}
          </div>
        </div>
      </div>
    </AssetLabelCountStyled>
  );
};

AssetLabelCount.propTypes = {};

export default AssetLabelCount;
