import styled from 'styled-components';

export const AssetAllTabStyled = styled.div`
  .section_layout {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
