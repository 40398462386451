import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AssetTypeDetailStyled } from './styled';
import { Displays, Icons, Lists } from 'components';

const AssetTypeDetail = ({
  theme_standard,
  no,
  label,
  routeAdd,
  bg,
  updatedDate,
  dataList,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AssetTypeDetailStyled bg={bg}>
      <div className={customClass}>
        <div className="asset_type_detail_container">
          <div className="asset_head_wrap">
            <Displays.AssetLabelCount
              no={no}
              label={label}
              icon={routeAdd && <Icons.AddAsset />}
            />
          </div>
          <div className="asset_list_wrap">
            <Lists.AssetTypeList data={dataList} />
          </div>
          <div className="at_update_date">Update : {updatedDate}</div>
        </div>
      </div>
    </AssetTypeDetailStyled>
  );
};

AssetTypeDetail.propTypes = {};

export default AssetTypeDetail;
