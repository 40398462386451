export default function renderIcon({ color = '#1f4181' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.092"
      height="40"
      viewBox="0 0 29.092 40"
    >
      <path
        id="simple-icons_googlesheets"
        d="M16.681,20.908H11V17.727h5.683V20.91ZM22.365,0V10h10l-10-10Zm2.272,17.727H18.953V20.91h5.683Zm0,5.455H18.953v3.183h5.683Zm7.727-12.348v26.44A2.726,2.726,0,0,1,29.636,40H6a2.727,2.727,0,0,1-2.727-2.727V2.727A2.726,2.726,0,0,1,6,0H21.53V10.833Zm-5.455,4.622H8.726V28.637H26.91V15.453ZM16.681,23.182H11v3.183h5.683V23.182Z"
        transform="translate(-3.273)"
        fill={color}
        opacity="0.85"
      />
    </svg>
  );
}
