import styled from 'styled-components';

export const ShareAssetAllWidgetStyled = styled.div`
  .sa_row_2_col {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    overflow: auto;
    .sa_left_col {
      flex: 1;
      min-width: 500px;
    }
    .sa_right_col {
      flex: 1;
      min-width: 500px; 
    }
  }
`;
