import styled from 'styled-components';

export const RankingWidgetStyled = styled.div`
  padding: 18px 12px 14px 12px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  border-radius: 5px;
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 14px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S15};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    color: ${({ theme }) => theme.COLORS.BLUE_1};
  }
  .ranking {
    position: absolute;
    top: 0px;
    left: 12px;
  }
  .row_2_col {
    display: flex;
    column-gap: 50px;
    &.mb_8 {
      margin-bottom: 8px;
    }
    .left_col {
      flex-shrink: 0;
      width: 100px;
      display: flex;
      align-items: center;
      column-gap: 20px;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLUE_1};
    }
    .right_col {
      flex: 1;
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.DESKTOP}) {
    .row_2_col {
      column-gap: 40px;
    }
  }
`;
