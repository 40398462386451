import styled from 'styled-components';

export const Sec7CoAgentProcessStyled = styled.div`
  margin: auto;
  position: relative;
  max-width: 1440px;
  min-height: calc(100vh - 134px);
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  .co_agent_title {
    font-size: ${({ theme }) => theme.FONT.SIZE.S36};
    font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
  }
  .ca_row {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    .ca_item {
      width: fit-content;
      text-align: center;
      .ca_img {
        margin: auto;
        width: 180px;
        height: 169px;
      }
      .ca_label {
        white-space: nowrap;
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.LAPTOP}) {
    padding: 30px 22px;
  }
`;
